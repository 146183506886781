import React, { useEffect, useState } from 'react'
import MainTitle from '../../../components/mainTitle/mainTitle'
import DeleteButton from '../../../components/Buttons/deleteButton/deleteButton'
import CustomLoader from '../../../components/loader/loader'
import { useNavigate } from 'react-router-dom'
import { AllSpeaker_Data } from '../../../services/getServices'
import { SPEAKER_API } from '../../../constant/api'
import CustomButton from '../../../components/Buttons/custom/CustomButton'
import DataTableCom from '../../../components/datatable/datatablecom'
import { EditSpeaker_Data } from '../../../services/postServices'
import { SweetConfirm, SweetAlertBox } from '../../../components/alert/sweetAlertPop'
import { ErrorToast } from '../../../components/alert/tostPop'
import { useSelector } from 'react-redux';
import { selectorUserRole } from '../../../redux/selector';
import { roleSuperUser } from '../../../utils/globalVariable';
import { FcCancel, FcCheckmark, FcEditImage } from 'react-icons/fc'
import CustomBtn from '../../../components/Buttons/CustomButton/CustomBtn'


const Speaker = () => {

    const userRole = useSelector(selectorUserRole);
    const [SpeakerData, setSpeakerData] = useState([]);
    const [refersh, setRefersh] = useState();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    // fetch all Speaker data
    const fetchSpeakerData = async (getData = 'allSpeaker') => {
        setLoading(true);
        try {
            const res = await AllSpeaker_Data();
            if (getData === "approvedSpeaker") {
                setSpeakerData(res.data.data.approvedSpeakers);
            }
            else if (getData === "notApprovedSpeaker") {
                setSpeakerData(res.data.data.notApprovedSpeakers);
            }
            else {
                const combineSpeakerData = [...(res.data.data.approvedSpeakers || []), ...(res.data.data.notApprovedSpeakers || [])]
                setSpeakerData(combineSpeakerData);
            }
            setLoading(false);
        }
        catch (err) {
            console.error(err);
        }
    }

    const handleActionButton = (speakerId, action) => {
        if (action === "yes") {
            SweetConfirm('Speaker Approved', 'Are you sure this speaker is approved?', "Confirm", async () => {
                const res = await EditSpeaker_Data(speakerId, { approved: "YES" })
                if (res.status === 200) {
                    SweetAlertBox("success", "Speaker Approved", "This speaker is approved successfully", () => {
                        fetchSpeakerData()
                    })
                }
                else {
                    ErrorToast("Sorry, This record is updated.")
                    fetchSpeakerData()
                }
            })
        }
        else {
            SweetConfirm('Speaker Not Approved', 'Are you sure this speaker is not approved?', "Confirm", async () => {
                const res = await EditSpeaker_Data(speakerId, { approved: "NO" })
                if (res.status === 200) {
                    SweetAlertBox("success", "Speaker Not Approved", "This speaker is not approved successfully", () => {
                        fetchSpeakerData()
                    })
                }
                else {
                    ErrorToast("Sorry, This record is updated.")
                    fetchSpeakerData()
                }
            })
        }
    }


    const columns = [
        {
            name: 'No',
            selector: (row, index) => index + 1,
            sortable: true,
            width: '10%',
            minWidth: '10%',
        },
        {
            name: 'Name',
            selector: (row) => row.firstName + " " + row.lastName,
            sortable: true,
        },
        {
            name: 'Email',
            sortable: true,
            selector: (row) => row.email,
        },
        {
            name: 'Mobile',
            sortable: true,
            selector: (row) => row.mobile,
        },
        {
            name: 'Designation',
            sortable: true,
            selector: (row) => row.designation,
        },

        {
            name: 'city',
            sortable: true,
            selector: (row) => row.city,
        },

        {
            name: 'Actions',
            button: true,
            cell: (item) => {
                return (
                    <>
                        {
                            item?.approved === "NO" ?
                                <div className="d-flex gap-3">
                                    <CustomBtn
                                        btnIcon={<FcCheckmark size={20} />}
                                        btnText=""
                                        className="checkButton"
                                        onClick={() => handleActionButton(item._id, 'yes')}
                                    />
                                    <CustomBtn
                                        btnIcon={<FcCancel size={20} />}
                                        btnText=""
                                        className="cancelButton"
                                        onClick={() => handleActionButton(item._id, 'no')}
                                    />
                                </div> :
                                <div className="d-flex gap-3">
                                    <CustomBtn
                                        btnIcon={<FcEditImage size={20} />}
                                        btnText=""
                                        className="actionEdit"
                                        onClick={() => navigate(`/getInvolved/speaker/${item._id}`)}
                                    />
                                    {
                                        userRole && userRole === roleSuperUser ?
                                            <DeleteButton
                                                Id={item._id}
                                                apiRoute={SPEAKER_API}
                                                handleBack={() => { fetchSpeakerData() }}
                                            /> : ""
                                    }
                                </div>
                        }
                    </>
                )
            }
        }
    ];

    useEffect(() => {
        fetchSpeakerData()
    }, [])

    return (
        <div>
            <CustomLoader show={loading} />

            <div className="d-flex flex-column flex-sm-row gap-3 justify-content-center justify-content-sm-between align-items-center w-100 pb-4 px-3">
                <MainTitle heading={"Speaker"} textAlign={"center"} />
                <CustomButton buttonText={"Add"} onClick={() => navigate('/getInvolved/speaker')} />
            </div>

            <div className='pb-5'>
                <DataTableCom
                    columns={columns}
                    data={SpeakerData}
                    refreshData={() => fetchSpeakerData(refersh)}
                    dataList={[
                        {
                            label: "All Speaker",
                            value: "allSpeaker"
                        },
                        {
                            label: "Approved Speaker",
                            value: "approvedSpeaker"
                        },
                        {
                            label: "Not Approved Speaker",
                            value: "notApprovedSpeaker"
                        }
                    ]}
                    dropdownChangeValue={(e) => {
                        fetchSpeakerData(e.target.value)
                        setRefersh(e.target.value)
                    }}
                />
            </div>
        </div>
    )
}

export default Speaker