
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import './App.css';
import Dashboard from './pages/dashboard/dashboard';
import Login from './pages/login/login';

import { useEffect, useState } from 'react';
import Slidebar from './components/sidebar/sidebar';
import AdminPage from './pages/admin/admin';
import EventPage from './pages/events/Event';
import EventImages from './pages/events/EventImages/EventImages';
import EventForm from './pages/events/EventForm/EventForm';
import ParticipatePage from './pages/events/participate/participate';
import Feedback from './pages/getInvolved/feedback/Feedback';
import FeedbackForm from './pages/getInvolved/feedback/FeedbackForm';
import Host from './pages/getInvolved/host/Host';
import HostForm from './pages/getInvolved/host/HostForm';
import SpeakerForm from './pages/getInvolved/speaker/SpeakerForm';
import Speaker from './pages/getInvolved/speaker/Speaker';
import Volunteer from './pages/getInvolved/volunteer/Volunteer';
import VolunteerForm from './pages/getInvolved/volunteer/VolunteerForm';
import Members from './pages/member/Members';
import EditMemberForm from './pages/member/forms/EditMemberForm';
import NewMemberForm from './pages/member/forms/NewMemberForm';
import PageNotFound from './pages/notfound/pagenotfound';
import Otp from './pages/otp/otp';
import Teams from './pages/teams/teams';
import Slots from './pages/events/slots/Sloats';
import MemberShipPage from './pages/member/membership/membership';
import InvitationMember from './pages/member/Invitation/InvitationMember';
import Chapters from './pages/Chapter/Chapters';
import { useSelector } from 'react-redux';
import { ProSidebarProvider } from 'react-pro-sidebar';
import ClientFeedback from './pages/clientFeedback/ClientFeedback';
import ContactUs from './pages/contactus/contactus';
import { selectorUserLogin, selectorUserRole } from './redux/selector';
import { roleSuperUser } from './utils/globalVariable';
import Prospact from './pages/prospact/Prospact';
import ProspactForm from './pages/prospact/ProspactForm';
import ChapterForm from './pages/Chapter/ChapterForm';
import { projectTitle, projectFavicon } from './utils/globalVariable'


function App() {
    // const userRole = useSelector((state) => state?.authentication.loginData.userData.role);
    const userLogin = useSelector(selectorUserLogin);
    const userRole = useSelector(selectorUserRole);

    const [login, setLogin] = useState(true);

    useEffect(() => {
        if (document) {
            document.querySelector("link[rel*='icon']").href = projectFavicon
            document.title = projectTitle
        }

        if (userLogin) {
            setLogin(true);
        }
        else {
            setLogin(false);
        }
    }, [userLogin, document]);

    return (
        <div>
            <ToastContainer />
            {
                login ? (
                    <ProSidebarProvider>
                        <Slidebar>
                            <Routes>
                                <Route path='/' element={<Dashboard />} />
                                <Route path='/dashboard' element={<Dashboard />} />

                                {
                                    userRole && userRole === roleSuperUser ?
                                        <Route path='/admin' element={<AdminPage />} /> : ""
                                }

                                <Route path='/teams' element={<Teams />} />

                                <Route path='/events' element={<EventPage />} />
                                <Route path='/event/create/' element={<EventForm />} />
                                <Route path='/event/:eventId/view/' element={<EventForm />} />
                                <Route path='/event/:eventId/edit/' element={<EventForm />} />
                                <Route path='/event/:eventId/eventImages' element={<EventImages />} />
                                <Route path='/event/:eventId/participate/' element={<ParticipatePage />} />
                                <Route path='/event/:eventId/slots/' element={<Slots />} />

                                <Route path='/chapter/' element={<Chapters />} />
                                <Route path='/chapter/create' element={<ChapterForm />} />
                                <Route path='/chapter/:cid/view' element={<ChapterForm />} />

                                <Route path='/prospect/' element={<Prospact />} />
                                <Route path='/prospect/:pid/view/' element={<ProspactForm />} />
                                <Route path='/prospect/create/' element={<ProspactForm />} />
                                <Route path='/prospect/:pid/edit/' element={<ProspactForm />} />

                                <Route path='/members' element={<Members />} />
                                <Route path='/members/:userId/view/' element={<EditMemberForm />} />
                                <Route path='/members/:userId/edit/' element={<EditMemberForm />} />
                                <Route path='/editMember/:userId' element={<EditMemberForm />} />
                                <Route path='/membership' element={<MemberShipPage />} />
                                <Route path='/newMember/' element={<NewMemberForm />} />
                                <Route path='/invitation' element={<InvitationMember />} />

                                <Route path='/getInvolved/hosts/' element={<Host />} />
                                <Route path='/getInvolved/host/' element={<HostForm />} />
                                <Route path='/getInvolved/host/:hostId' element={<HostForm />} />

                                <Route path='/getInvolved/speakers' element={<Speaker />} />
                                <Route path='/getInvolved/speaker/' element={<SpeakerForm />} />
                                <Route path='/getInvolved/speaker/:SpeakerId' element={<SpeakerForm />} />

                                <Route path='/getInvolved/volunteer' element={<Volunteer />} />
                                <Route path='/getInvolved/newVolunteer' element={<VolunteerForm />} />
                                <Route path='/getInvolved/volunteer/:volunteerId' element={<VolunteerForm />} />

                                <Route path='/getInvolved/feedback' element={<Feedback />} />
                                <Route path='/getInvolved/newFeedback' element={<FeedbackForm />} />
                                <Route path='/getInvolved/feedback/:feedbackId' element={<FeedbackForm />} />

                                <Route path='/clientFeedback' element={<ClientFeedback />} />

                                <Route path='/contactus' element={<ContactUs />} />
                            </Routes>
                        </Slidebar>
                    </ProSidebarProvider>
                ) : (
                    <Routes>
                        <Route path='*' element={<PageNotFound />} />
                        <Route path='/' element={<Login />} />
                        <Route path='/otp' element={<Otp />} />
                    </Routes>
                )
            }
        </div>
    );
}

export default App;