import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorToast } from "../../../components/alert/tostPop";
import { SingleVolunteer_Data } from "../../../services/getServices";
import { EditVolunteer_Data, NewVolunteer_Data } from "../../../services/postServices";

import CustomButton from "../../../components/Buttons/custom/CustomButton";
import { SweetAlertBox } from "../../../components/alert/sweetAlertPop";
import FormikCheckBox from "../../../components/filedbox/formikCheckBox";
import FormikInput from "../../../components/filedbox/formikInput";
import CustomLoader from "../../../components/loader/loader";
import MainTitle from "../../../components/mainTitle/mainTitle";
import BackButton from "../../../components/Buttons/custom/BackButton";
import { validationSchemaVolunteerForm } from "../../../utils/validations";


const VolunteerForm = () => {

    const initialValues = {
        firstName: "",
        lastName: "",
        email: "",
        number: "",
        linkedIn: "",
        occupation: "",
        experience: "",
        availabity: "",
        areaOfInterest: [],
        preferredVolunteerRole: "",
        skillsOfVolunteer: "",
        additionalCommentOrQuestions: "",
    };

    // state define
    const { volunteerId } = useParams();
    const navigate = useNavigate();
    const [volunteerData, setVolunteerData] = useState(initialValues);
    const [loading, setLoading] = useState(false);


    // all functions

    // fetch single volunteer data
    const singleVolunteerData = async () => {
        setLoading(true);
        try {
            const res = await SingleVolunteer_Data(volunteerId);
            setVolunteerData(res.data.data);
            setLoading(false);
        } catch (e) {
            console.error(e);
        }
    };


    // submit form 
    const onSubmit = async () => {
        const formdata = {
            firstName: formik.values.firstName,
            lastName: formik.values.lastName,
            email: formik.values.email,
            number: formik.values.number,
            linkedIn: formik.values.linkedIn,
            occupation: formik.values.occupation,
            experience: formik.values.experience,
            availabity: formik.values.availabity,
            areaOfInterest: formik.values.areaOfInterest.toString(),
            preferredVolunteerRole: formik.values.preferredVolunteerRole === "" ? " " : formik.values.preferredVolunteerRole,
            skillsOfVolunteer: formik.values.skillsOfVolunteer === "" ? " " : formik.values.skillsOfVolunteer,
            additionalCommentOrQuestions: formik.values.additionalCommentOrQuestions === "" ? " " : formik.values.additionalCommentOrQuestions
        }
        try {
            if (volunteerId === undefined) {
                const res = await NewVolunteer_Data(formdata)
                if (res.status === 200) {
                    SweetAlertBox("success", "New Volunteer", `${formik.values.firstName + " " + formik.values.lastName} is created successfully`, () => {
                        navigate('/getInvolved/volunteer/')
                    })
                }
                else {
                    ErrorToast("Sorry, This record is updated.")
                    navigate('/getInvolved/volunteer/')
                }
            }
            else {
                const res = await EditVolunteer_Data(volunteerId, formdata)
                if (res.status === 200) {
                    SweetAlertBox("success", "Update Volunteer", `${formik.values.firstName + " " + formik.values.lastName} is update successfully`, () => {
                        navigate('/getInvolved/volunteer/')
                    })
                }
                else {
                    ErrorToast("Sorry, This record is updated.")
                    navigate('/getInvolved/volunteer/')
                }
            }
        }
        catch (e) {
            console.error(e);
        }
    };


    useEffect(() => {
        if (volunteerId !== undefined) {
            singleVolunteerData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [volunteerId])


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: volunteerData,
        validationSchema: validationSchemaVolunteerForm,
        onSubmit,
    });


    return (
        <div>
            <CustomLoader show={loading} />

            <Container>

                <div className="d-flex flex-column flex-sm-row gap-3 justify-content-center justify-content-sm-between align-items-center w-100 pb-4 px-3">
                        <BackButton />
                        <MainTitle heading={volunteerId && volunteerId !== undefined ? "Edit Volunteer" : "New Volunteer"} textAlign={"center"} />
                </div>

                <Container className="allDataTable p-4 pb-0 rounded">
                    <Form onSubmit={formik.handleSubmit} className="poolForm mb-5">
                        <Row>
                            <Col sm={12} lg={6}>
                                <FormikInput
                                    fieldName={"First Name"}
                                    type={"text"}
                                    as={"input"}
                                    name={"firstName"}
                                    placeholder={"Enter your first name"}
                                    formik={formik}
                                />
                            </Col>
                            <Col sm={12} lg={6}>
                                <FormikInput
                                    fieldName={"Last Name"}
                                    type={"text"}
                                    as={"input"}
                                    name={"lastName"}
                                    placeholder={"Enter your last name"}
                                    formik={formik}
                                />
                            </Col>
                        </Row>

                        <Row >
                            <Col sm={12} lg={6}>
                                <FormikInput
                                    fieldName={"Email Address"}
                                    type={"email"}
                                    as={"input"}
                                    name={"email"}
                                    placeholder={"Enter your Email Address"}
                                    formik={formik}
                                />
                            </Col>

                            <Col sm={12} lg={6}>
                                <FormikInput
                                    fieldName={"Mobile Number"}
                                    type={"text"}
                                    as={"input"}
                                    name={"number"}
                                    placeholder={"Enter your mobile number"}
                                    formik={formik}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName={"Linked In Profile"}
                                    type={"text"}
                                    as={"input"}
                                    name={"linkedIn"}
                                    placeholder={"Enter your linkedin profile link"}
                                    formik={formik}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName={"Occupation"}
                                    type={"text"}
                                    as={"input"}
                                    name={"occupation"}
                                    placeholder={"Enter your Occupation or Work"}
                                    formik={formik}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName={"Experience"}
                                    type={"text"}
                                    as={"textarea"}
                                    name={"experience"}
                                    placeholder={"Enter your Experience"}
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName={"Availability"}
                                    type={"text"}
                                    as={"input"}
                                    name={"availabity"}
                                    placeholder={"Enter your Availability"}
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormikCheckBox
                                    dataList={[
                                        {
                                            label: "Volunteering: Make a positive impact by supporting community initiatives, providing assistance, promoting inclusivity, and inspiring others to create change.",
                                            value: "volunteering",
                                        },
                                        {
                                            label: "Photography: Can capture the essence of a moment, convey emotions, share perspectives, and inspire appreciation for the unique perspective.",
                                            value: "Photography",
                                        },
                                        {
                                            label: "Event Planning and Coordination: Assist in organizing and coordinating events, including webinars, workshops, and networking sessions.",
                                            value: "EventPlanningAndCoordination",
                                        },
                                        {
                                            label: "Social Media Engagement: Help manage and grow our social media presence by creating engaging posts, interacting with community members, and sharing valuable resources.",
                                            value: "SocialMediaEngagement",
                                        },
                                        {
                                            label: "Content Creation: Contribute to the creation of educational and informative content, such as articles, blog posts, and video tutorials, to benefit the community.",
                                            value: "ContentCreation",
                                        },
                                    ]}
                                    fieldName={'Areas of Interest'}
                                    name="areaOfInterest"
                                    formik={formik}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName={"Preferred Volunteer Role"}
                                    type={"text"}
                                    as={"input"}
                                    name={"preferredVolunteerRole"}
                                    placeholder={"Enter your Preferred role as volunteer"}
                                    formik={formik}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName={"Skills relevant to  Volunteering opportunity, Marketing, Communication, Leadership, etc."}
                                    type={"text"}
                                    as={"input"}
                                    name={"skillsOfVolunteer"}
                                    placeholder={"Enter your answer"}
                                    formik={formik}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <FormikInput
                                fieldName={"Additional Comments or Questions"}
                                type={"text"}
                                as={"textarea"}
                                name={"additionalCommentOrQuestions"}
                                placeholder={"Enter your comments or question"}
                                formik={formik}
                            />
                        </Row>


                        <div className="d-flex justify-content-around align-items-center py-3">
                            <CustomButton buttonText={"Submit"} type={"submit"} />
                        </div>
                    </Form>
                </Container>
            </Container>
        </div>
    );
}

export default VolunteerForm