import React from 'react'
import { useNavigate } from 'react-router-dom';
import { IoMdArrowRoundBack } from 'react-icons/io'
import CustomButton from './CustomButton';

const BackButton = () => {
    const navigate = useNavigate();

    // back button click event
    const btnBack = () => {
        navigate(-1);
    };

    return (
        <CustomButton buttonText={<IoMdArrowRoundBack fontSize={"20px"} />} type={"button"} onClick={btnBack} />
    )
}

export default BackButton