import React, { useEffect, useState } from 'react'
import MainTitle from '../../components/mainTitle/mainTitle'
import CustomLoader from '../../components/loader/loader'
import { AllEvent_Data } from '../../services/getServices'
import { useNavigate } from 'react-router-dom'
import { ErrorToast } from '../../components/alert/tostPop'
import { EVENTS_DELETE_API } from '../../constant/api'
import { BsPlusLg } from 'react-icons/bs'

import CustomButton from '../../components/Buttons/custom/CustomButton'
import DeleteButton from '../../components/Buttons/deleteButton/deleteButton'
import DataTableCom from '../../components/datatable/datatablecom'
import { FaEye } from "react-icons/fa";
import { SiGooglesheets } from 'react-icons/si'
import { FcCamera, FcEditImage, FcReadingEbook } from 'react-icons/fc'
import { useSelector } from 'react-redux';
import { selectorUserRole } from '../../redux/selector';
import { roleSuperUser } from '../../utils/globalVariable';
import CustomBtn from '../../components/Buttons/CustomButton/CustomBtn'


const EventPage = () => {


    const userRole = useSelector(selectorUserRole);
    const [eventData, setEventData] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();


    const columns = [
        {
            name: 'No',
            selector: (row, index) => index + 1,
            sortable: true,
            grow: 0,
        },
        {
            name: 'Title',
            selector: (row) => row.eventTitle,
            sortable: true,
            grow: 0.5,
        },
        {
            name: 'Location',
            sortable: true,
            selector: (row) => row.location,
            grow: 0.2,
        },
        {
            name: 'Date',
            sortable: true,
            selector: (row) => row.date,
            grow: 0.5,
        },
        {
            name: 'Time',
            sortable: true,
            selector: (row) => row.startTime + " - " + row.endTime,
            grow: 0.5,
        },
        {

            name: 'Actions',
            button: true,
            grow: 10,
            cell: (item) => {
                return <>
                    <div className='d-flex gap-2'>
                        <CustomBtn
                            btnIcon={<FaEye size={20} color="#0d6efd" />}
                            btnText=""
                            className="actionEdit"
                            onClick={() => navigate(`/event/${item._id}/view/`)}
                        />
                        <CustomBtn
                            btnIcon={<SiGooglesheets size={20} color='#008000' />}
                            btnText=""
                            className="formButton"
                            onClick={() => navigate(`/event/${item._id}/slots/`)}
                        />
                        <CustomBtn
                            btnIcon={<FcReadingEbook size={20} />}
                            btnText=""
                            className="actionEdit"
                            onClick={() => navigate(`/event/${item._id}/participate`)}
                        />
                        <CustomBtn
                            btnIcon={<FcCamera size={20} />}
                            btnText=""
                            className="actionUpload"
                            onClick={() => navigate(`/event/${item._id}/eventImages`)}
                        />
                        <CustomBtn
                            btnIcon={<FcEditImage size={20} />}
                            btnText=""
                            className="actionEdit"
                            onClick={() => navigate(`/event/${item._id}/edit`)}
                        />
                        {
                            userRole && userRole === roleSuperUser ?
                                <DeleteButton
                                    Id={item._id}
                                    apiRoute={EVENTS_DELETE_API}
                                    handleBack={() => { fetchAllEventData() }}
                                /> : ""
                        }
                    </div>
                </>
            }
        }

    ];

    // fetch all events data
    const fetchAllEventData = async () => {
        setLoading(true);
        try {
            const res = await AllEvent_Data()
            const resCombinedEventData = [...(res.data.data.upcomingEvents || []), ...(res.data.data.completedEvents || [])]
            const combinedEventData = resCombinedEventData.filter(item => item?.isDefault !== true)
            setEventData(combinedEventData);
            setLoading(false);
        }
        catch (res) {
            ErrorToast(res.data.Message);
        }
    }

    // fetch upComing Events data
    const fetchAllUpComingData = async () => {
        try {
            setLoading(true);
            const res = await AllEvent_Data();
            const eventData = res.data.data.upcomingEvents.filter(item => item?.isDefault !== true)
            setEventData(eventData);
        }
        catch (err) {
            console.error(err)
        }
        finally {
            setLoading(false);
        }
    }

    // fetch completed Events data
    const fetchAllCompletedData = async () => {
        try {
            setLoading(true);
            const res = await AllEvent_Data();
            setEventData(res.data.data.completedEvents);
        }
        catch (err) {
            console.error(err)
        }
        finally {
            setLoading(false);
        }
    }

    // change select condition in events
    const handleChangeEvents = async (e) => {
        if (e.target.value === "completed") {
            fetchAllCompletedData()
        }
        else if (e.target.value === "nonCompleted") {
            fetchAllUpComingData()
        }
        else {
            fetchAllEventData();
        }
    }

    useEffect(() => {
        fetchAllEventData();
    }, [])


    return (
        <div>
            <CustomLoader show={loading} />

            <div className="d-flex flex-column flex-sm-row gap-3 justify-content-center justify-content-sm-between align-items-center w-100 pb-4 px-3">
                <MainTitle heading={"Events"} textAlign={"center"} />
                <CustomButton buttonText={<BsPlusLg fontSize={"22px"} />} title="New Event" onClick={() => navigate('/event/create/')} />
            </div>

            <div className='pb-5'>

                <DataTableCom
                    columns={columns}
                    data={eventData}
                    refreshData={fetchAllEventData}
                    dataList={[
                        {
                            label: "All",
                            value: "all"
                        },
                        {
                            label: "Completed",
                            value: "completed"
                        },
                        {
                            label: "Upcoming",
                            value: "nonCompleted"
                        },
                    ]}
                    dropdownChangeValue={handleChangeEvents}
                />
            </div>
        </div>
    )
}

export default EventPage