import React, { useState } from "react";
import CustomButton from "../../../components/Buttons/custom/CustomButton";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { IoMdClose } from "react-icons/io";
import { FcInvite } from "react-icons/fc";
import { Invitation_Mail } from "../../../services/postServices";
import { SweetAlertBox } from "../../../components/alert/sweetAlertPop";

const InvitationMail = () => {
    const [show, setShow] = useState(false);
    const [emailText, setEmailText] = useState("");

    const handleClose = () => {
        setShow(false);
        setEmailText("");
    };

    const handleShow = () => {
        setShow(true);
    };

    const sendInvitationMail = async () => {
        try {
            const res = await Invitation_Mail({ email: emailText });
            if (res.status === 200) {
                SweetAlertBox("success", "Invitation mail Sent Successfully.", "Thank you, the invitation email has been sent successfully.", () => handleClose());
            } else {
                SweetAlertBox("error", "Invitation mail not sended.", "Sorry!, the invitation email has been not sended.", () => handleClose())
            }
        } catch (e) { }
    };

    return (
        <div>
            <CustomButton
                type={"button"}
                buttonText={<FcInvite fontSize={25} />}
                onClick={handleShow}
                title={"Invitation Mail"}
            />

            <Modal
                show={show}
                size={"md"}
                onHide={handleClose}
                scrollable={true}
                backdrop="static"
                keyboard={false}
                className="vh-100"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Invitation Mail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="p-3">
                        <Form.Group className="position-relative">
                            <Form.Control
                                type="text"
                                value={emailText}
                                className="pe-5"
                                onChange={(e) => setEmailText(e.target.value)}
                                placeholder="enter email address"
                            />
                            {emailText && emailText.length !== 0 ? (
                                <IoMdClose
                                    className="searchClear"
                                    onClick={() => setEmailText("")}
                                />
                            ) : null}
                        </Form.Group>
                    </div>
                </Modal.Body>
                {emailText && emailText.length !== 0 ? (
                    <Modal.Footer>
                        <Row className="px-3">
                            <Col>
                                <Button
                                    variant="outline-primary"
                                    type="submit"
                                    onClick={sendInvitationMail}
                                >
                                    Send Mail
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                ) : null}
            </Modal>
        </div>
    );
};

export default InvitationMail;
