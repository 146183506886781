import React, { useState, useEffect } from "react";
import { Button, Form, Image, Modal, Row } from "react-bootstrap";
import CustomButton from "../../../components/Buttons/custom/CustomButton";
import { IMAGE_DEFAULT_AVATER } from "../../../assets";
import { AllMembers_Data } from "../../../services/getServices";
import { IoMdClose } from "react-icons/io";
import { useParams } from "react-router-dom";
import { EditEvent_Data } from "../../../services/postServices";
import { SweetAlertBox } from "../../../components/alert/sweetAlertPop";
import "./participate.css";
import { BsPlusLg } from "react-icons/bs";

const NewParticipate = ({ participateMember, bookClick }) => {
    const { eventId } = useParams();
    const [show, setShow] = useState(false);
    const [allMembersList, setAllMembersList] = useState([]);
    const [bookMemberList, setBookMemberList] = useState([]);
    const [filterText, setFilterText] = useState("");

    const handleClose = () => {
        setShow(false);
        setFilterText("");
    };

    const handleShow = () => setShow(true);

    // check already participate or not using member id
    const isParticipated = (memberId) => {
        return participateMember.some(
            (participateData) => participateData.user_id._id === memberId
        );
    };

    // fetch all member list
    const fetchAllMember = async () => {
        const res = await AllMembers_Data();
        const allMembers = res?.data.data || [];
        // const combineSpeakerData = [...(allMembers.approved || []), ...(allMembers.notApproved || [])]
        const notParticipate = allMembers.filter((memberData) => !isParticipated(memberData._id))
        const sortedNotParticipate = notParticipate?.slice().sort((a, b) => {
            const nameA = a.firstName.toUpperCase();
            const nameB = b.firstName.toUpperCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
        })
        setAllMembersList(sortedNotParticipate);
    };

    // checkbox check function
    const handleCheckBox = (userId) => {
        if (bookMemberList.includes(userId)) {
            setBookMemberList(bookMemberList.filter((id) => id !== userId));
        } else {
            setBookMemberList([...bookMemberList, userId]);
        }
    };

    // filter all member list
    const searchMember = allMembersList?.filter((item) => {
        const startFirstName = (item.firstName + "" + item.lastName).toLowerCase();
        const startLastName = (item.lastName + "" + item.firstName).toLowerCase();
        const searchText = filterText.toLowerCase();
        return (
            startFirstName.includes(searchText) ||
            startFirstName.replace(/\s/g, "").includes(searchText) ||
            startLastName.includes(searchText) ||
            startLastName.replace(/\s/g, "").includes(searchText)
        );
    });

    // Add Participate Member Save Function
    const bookEvent = async () => {
        if (bookMemberList.length !== 0) {
            const userEventData = {
                eventMembers: [],
            };

            bookMemberList.forEach((ele, index) => {
                userEventData.eventMembers.push({
                    user_id: ele?._id,
                });
            });

            const res = await EditEvent_Data(eventId, userEventData);
            if (res.status === 200) {
                SweetAlertBox("success", "New Participate Member", "New Participate Save Successfully.", () => {
                    bookClick()
                    setBookMemberList()
                })
            } else {
                SweetAlertBox("error", "Not Add Participate Member", "Sorry! This Participate Member Is Not Saved.", () => {
                    bookClick();
                    setBookMemberList()
                })
            }
        } else {
            SweetAlertBox("error", "Black Member List", "Please, check the member checkbox", () => {
                bookClick();
            })
        }
        handleClose();
    };

    useEffect(() => {
        if (participateMember !== undefined && eventId !== undefined) {
            fetchAllMember();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [participateMember]);

    return (
        <div>
            <CustomButton
                type={"button"}
                buttonText={<BsPlusLg fontSize={"22px"} />}
                onClick={handleShow}
            />

            <Modal
                show={show}
                size={"md"}
                onHide={handleClose}
                scrollable={true}
                backdrop="static"
                keyboard={false}
                className="vh-100 newParticipate"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Members List</Modal.Title>
                </Modal.Header>
                <Row>
                    <div className="px-4 py-3 shadow-sm">
                        <Form.Group className="position-relative">
                            <Form.Control
                                type="text"
                                value={filterText}
                                className="pe-5"
                                onChange={(e) => setFilterText(e.target.value)}
                                placeholder="Search member name"
                            />
                            {filterText && filterText.length !== 0 ? (
                                <IoMdClose
                                    className="searchClear"
                                    onClick={() => setFilterText("")}
                                />
                            ) : null}
                        </Form.Group>
                    </div>
                </Row>
                <Modal.Body>
                    <Row>
                        {searchMember && searchMember.length !== 0 ? (
                            <div>
                                {searchMember.map((data, index) => (
                                    <div
                                        className="participateBox my-3"
                                        key={index}
                                    >
                                        <div className="imageBox">
                                            <Image
                                                src={data.profile}
                                                alt="not found"
                                                className="image"
                                                onError={(e) =>
                                                (e.target.src =
                                                    IMAGE_DEFAULT_AVATER)
                                                }
                                            />
                                        </div>
                                        <div className="participateInfo">
                                            <div>
                                                <div className="w-100">
                                                    <h5>
                                                        {data.firstName}{" "}
                                                        {data.lastName}
                                                    </h5>
                                                    <span>{data.company}</span>
                                                </div>
                                            </div>
                                            <div>
                                                <Form.Check
                                                    type="checkbox"
                                                    value={data._id}
                                                    checked={bookMemberList?.includes(data)}
                                                    className="memberCheckBox"
                                                    onChange={() => handleCheckBox(data)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <h3 className="text-center text-secondary">
                                Not Found Members
                            </h3>
                        )}
                    </Row>
                </Modal.Body>
                {bookMemberList && bookMemberList.length !== 0 ? (
                    <Modal.Footer>
                        <div className="w-100 d-flex justify-content-between align-items-center">
                            <div>
                                <h5>Total: {bookMemberList.length}</h5>
                            </div>
                            <div>
                                <Button
                                    variant="primary"
                                    type="submit"
                                    onClick={bookEvent}
                                >
                                    Save Participate
                                </Button>
                            </div>
                        </div>
                    </Modal.Footer>
                ) : null}
            </Modal>
        </div>
    );
};

export default NewParticipate;
