import React, { useState } from 'react'
import { AllVolunteer_Data } from '../../services/getServices';
import CustomBtn from '../../components/Buttons/CustomButton/CustomBtn';
import { Button, Col, Form, Image, Modal, Row } from 'react-bootstrap';
import { FcCustomerSupport } from 'react-icons/fc';
import { IoMdClose } from 'react-icons/io';
import { SortAscendingFuncation } from '../../utils/globalFunctions';
import { IMAGE_DEFAULT_AVATER } from '../../assets';
import { FaLinkedin } from 'react-icons/fa';
import { SweetAlertBox } from '../../components/alert/sweetAlertPop';
import { NewChapterVolunteer_Data } from '../../services/postServices';


const ChapterVolunteer = ({ participateVolunteer, chapterId = "", refreshData }) => {

    const [show, setShow] = useState(false);
    const [allVolunteerList, setAllVolunteerList] = useState([]);
    const [bookVolunteerList, setBookVolunteerList] = useState([]);
    const [filterText, setFilterText] = useState("");

    // clear filter textbox function
    const handleClose = () => {
        setShow(false);
        setFilterText("");
    }

    // check already participate or not using member id
    const isParticipated = (volunteerId) => {
        return participateVolunteer?.some(participateData => participateData._id === volunteerId)
    }

    // fetch all member list
    const fetchAllVolunteer = async () => {
        try {
            const res = await AllVolunteer_Data();
            const allVolunteer = res.data.data;
            const notParticipateVolunteer = allVolunteer.filter(volunteerData => !isParticipated(volunteerData._id))
            const sortedNotParticipateVolunteer = SortAscendingFuncation(notParticipateVolunteer)
            setAllVolunteerList(sortedNotParticipateVolunteer);
        }
        catch (err) {
            console.error(err)
        }
    };

    // speaker search function
    const searchVolunteer = allVolunteerList?.filter((item) => {
        const startFirstName = (item.firstName + "" + item.lastName).toLowerCase();
        const startLastName = (item.lastName + "" + item.firstName).toLowerCase();
        const searchText = filterText.toLowerCase();
        return (
            startFirstName.includes(searchText) ||
            startFirstName.replace(/\s/g, "").includes(searchText) ||
            startLastName.includes(searchText) ||
            startLastName.replace(/\s/g, "").includes(searchText)
        );
    });

    // checkbox check function
    const handleCheckBox = (userId) => {
        if (bookVolunteerList.includes(userId)) {
            setBookVolunteerList(bookVolunteerList.filter((id) => id !== userId));
        } else {
            setBookVolunteerList([...bookVolunteerList, userId]);
        }
    };

    // speaker model box open funcation
    const handleShow = () => {
        if (chapterId !== "") {
            setShow(true)
            fetchAllVolunteer()
        }
    }

    const bookVolunteer = async () => {
        try {
            if (bookVolunteerList.length !== 0) {
                const chapterVolunteer = {
                    volunteerIds: [],
                };

                bookVolunteerList.forEach((ele, index) => {
                    chapterVolunteer.volunteerIds.push(ele?._id)
                })

                const res = await NewChapterVolunteer_Data(chapterId, chapterVolunteer);
                if (res.status === 200) {
                    SweetAlertBox("success", "Add Chapter Volunteer", "This volunteer Save Successfully.", () => {
                        setBookVolunteerList()
                        refreshData()
                    })
                } else {
                    SweetAlertBox("error", "Not Add Chapter Volunteer", "Sorry! This Volunteer Is Not Saved.", () => {
                        setBookVolunteerList()
                        refreshData();
                    })
                }
            } else {
                SweetAlertBox("error", "Black Member List", "Please, check the volunteer checkbox", () => {
                    setBookVolunteerList()
                    refreshData();
                })
            }
            handleClose();
        }
        catch (err) {
            console.error(err)
        }
    }

    return (
        <div>
            <CustomBtn
                btnIcon={<FcCustomerSupport size={20} />}
                btnText=""
                className="actionEdit"
                onClick={handleShow}
                hoverTitle={"Volunteer"}
            />

            <Modal
                show={show}
                size={"md"}
                onHide={handleClose}
                scrollable={true}
                backdrop="static"
                keyboard={false}
                className="vh-100 newParticipate"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Volunteer List</Modal.Title>
                </Modal.Header>
                <Row>
                    <Col>
                        <div className="px-4 py-3 shadow-sm">
                            <Form.Group className="position-relative">
                                <Form.Control
                                    type="text"
                                    value={filterText}
                                    className="pe-5"
                                    onChange={(e) => setFilterText(e.target.value)}
                                    placeholder="Search member name"
                                />
                                {filterText && filterText.length !== 0 ? <IoMdClose className="searchClear" onClick={() => setFilterText("")} /> : null}
                            </Form.Group>
                        </div>
                    </Col>
                </Row>
                <Modal.Body>
                    <Row>
                        {
                            searchVolunteer && searchVolunteer.length !== 0 ? (
                                <div>
                                    {searchVolunteer.map((data, index) => (
                                        <div
                                            className="participateBox my-3"
                                            key={index}
                                        >
                                            {/* <div className="imageBox">
                                                <Image
                                                    src={data.profile}
                                                    alt="not found"
                                                    className="image"
                                                    onError={e => e.target.src = IMAGE_DEFAULT_AVATER}
                                                />
                                            </div> */}
                                            <div className="participateInfo">
                                                <div>
                                                    <div className="w-100">
                                                        <h5>{`${data.firstName} ${data.lastName} ${data.designation !== "" ? '-' + data.designation : ''}`}</h5>
                                                        <div className='d-flex gap-2 align-items-center'>
                                                            <h6 className='m-0 fs-5'>{data.company}</h6>
                                                            <FaLinkedin fontSize={30} onClick={data.linkedIn} className='cursor-pointer' />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <Form.Check
                                                        type="checkbox"
                                                        value={data._id}
                                                        checked={bookVolunteerList?.includes(data)}
                                                        className="memberCheckBox"
                                                        onChange={() => handleCheckBox(data)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <h3 className="text-center text-secondary">
                                    Not Found Volunteer
                                </h3>
                            )}
                    </Row>
                </Modal.Body>
                {
                    bookVolunteerList && bookVolunteerList.length !== 0 ? (
                        <Modal.Footer>
                            <div className="w-100 d-flex justify-content-between align-items-center">
                                <div>
                                    <h5>Total: {bookVolunteerList.length}</h5>
                                </div>
                                <div>
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        onClick={bookVolunteer}
                                    >
                                        Save Speaker
                                    </Button>
                                </div>
                            </div>
                        </Modal.Footer>
                    ) : null}
            </Modal>
        </div>
    )
}

export default ChapterVolunteer