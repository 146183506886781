import React from 'react';
import { Form } from 'react-bootstrap';

const FormikSelect = ({ dataList, fieldName, name, disabled = false, formik }) => {

    return (
        <div className='my-3'>
            <Form.Group>
                <Form.Label htmlFor={name} className="fw-bold mx-1 text-capitalize">{fieldName}</Form.Label>
                <Form.Select
                    id={name}
                    name={name}
                    value={formik.values[name]}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={disabled}
                >
                    {
                        dataList?.map((arrItem, index) => (
                            <option value={arrItem.value} key={index} defaultChecked={arrItem?.default}>{arrItem.label}</option>
                        ))
                    }
                </Form.Select>
            </Form.Group>
            {
                formik.touched[name] && formik.errors[name] ? (
                    <p className="text-danger fw-bold mt-1">{formik.errors[name]}</p>
                ) : null
            }
        </div>
    );
};


export default FormikSelect;