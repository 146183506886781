import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Form, Row } from "react-bootstrap";
import MainTitle from "../../../components/mainTitle/mainTitle";
import CustomButton from "../../../components/Buttons/custom/CustomButton";
import { NewMember_Data } from "../../../services/postServices";
import { useFormik } from "formik";
import "./MemberForm.css";
import FormikInput from '../../../components/filedbox/formikInput'
import { SweetAlertBox } from "../../../components/alert/sweetAlertPop";
import BackButton from "../../../components/Buttons/custom/BackButton";
import { validationSchemaNewMember } from "../../../utils/validations";


const NewMemberForm = () => {

    const initialValues = {
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        password: "",
    }

    const navigate = useNavigate();
    const [MemberData] = useState(initialValues);

    const onSubmit = async (values) => {
        try {
            const newMemberData = {
                firstName: formik.values.firstName,
                lastName: formik.values.lastName,
                email: formik.values.email,
                mobile: formik.values.mobile,
                password: formik.values.password,
            }
            const res = await NewMember_Data(newMemberData)
            if (res.status === 201) {
                SweetAlertBox('success','Create New Member', `${formik.values.firstName + " " + formik.values.lastName} member is create successfully`, () => { 
                    navigate('/members')
                })
            }
            else {
                SweetAlertBox('error','Not Saved', `Sorry! ${formik.values.firstName + " " + formik.values.lastName} member is not saved.`, () => { 
                    navigate('/members')
                })
            }
        }
        catch (e) {
            console.error(e);
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: MemberData,
        validationSchema: validationSchemaNewMember,
        onSubmit,
    });


    return (
        <div>
            <Container>
                <Row className='py-4'>
                    <Col sm={2}>
                        <BackButton />
                    </Col>
                    <Col sm={10}>
                        <MainTitle
                            heading={"New Member Form"}
                            textAlign={"center"}
                        />
                    </Col>
                </Row>

                <Container className="allDataTable p-4 pb-0 rounded">
                    <Form onSubmit={formik.handleSubmit} className="poolForm mb-5">
                        <Row md={1} sm={1} lg={2}>
                            <Col>
                                <FormikInput
                                    fieldName={"Firstname"}
                                    type={"text"}
                                    as={"input"}
                                    name={"firstName"}
                                    placeholder={"enter firstname"}
                                    formik={formik}
                                />
                            </Col>
                            <Col>
                                <FormikInput
                                    fieldName={"Lastname"}
                                    type={"text"}
                                    as={"input"}
                                    name={"lastName"}
                                    placeholder={"enter lastname"}
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row md={1} sm={1} lg={2}>
                            <Col>
                                <FormikInput
                                    fieldName={"Email Address"}
                                    type={"email"}
                                    as={"input"}
                                    name={"email"}
                                    placeholder={"enter email address"}
                                    formik={formik}
                                />
                            </Col>
                            <Col>
                                <FormikInput
                                    fieldName={"Mobile"}
                                    type={"number"}
                                    as={"input"}
                                    name={"mobile"}
                                    placeholder={"enter mobile number"}
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <FormikInput
                                fieldName={"Password"}
                                type={"password"}
                                as={"input"}
                                name={"password"}
                                placeholder={"enter password"}
                                formik={formik}
                            />
                        </Row>

                        <div className="d-flex justify-content-around align-items-center py-3">
                            <CustomButton buttonText={"Submit"} type={"submit"} />
                        </div>
                    </Form>
                </Container>

            </Container>
        </div>
    )
}

export default NewMemberForm