import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const SuccessToast = (Message) => {

  toast.success(Message, {
    position: toast.POSITION.TOP_RIGHT
  });
}

export const ErrorToast = (Message) => {

  toast.error(Message, {
    position: toast.POSITION.TOP_RIGHT
  });

}
export const WarningToast = (Message) => {

  toast.warning(Message, {
    position: toast.POSITION.TOP_RIGHT
  });
}
export const InfoToast = () => {

  toast.info('Information Notification !', {
    position: toast.POSITION.BOTTOM_CENTER
  });
}

export const DefaultToast = () => {

  toast('Default Notification !', {
    position: toast.POSITION.BOTTOM_LEFT
  });
}
