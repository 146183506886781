import React, { useState } from 'react'
import { Button, Modal, Container } from 'react-bootstrap';
import CustomButton from '../../../components/Buttons/custom/CustomButton';
import { NewEventImage } from '../../../services/postServices';
import { FileUploader } from "react-drag-drop-files";

import './eventImages.css'
import { SweetAlertBox } from '../../../components/alert/sweetAlertPop';

const AddNewImage = ({ eventID, fetchData }) => {

    const [show, setShow] = useState(false);
    const [selectImage, setSelectImage] = useState(false);

    const [files, setFiles] = useState(null);

    const handleClose = () => {
        setShow(false)
        setFiles(null)
        setSelectImage(false)
        fetchData()
    }

    const handleChange = (file) => {
        setFiles(file);
        setSelectImage(true)
    };

    const SaveEventImages = async (eventID) => {
        try {
            const formData = new FormData();
            for (let i = 0; i < 9; i++) {
                formData.append('images', files[i]);
            }

            const res = await NewEventImage(eventID, formData)
            if (res.data.status === 200) {
                SweetAlertBox('success','Uploaded Successfully', 'Event Images uploaded successfully', () => {
                    handleClose()
                })
            }
            else {
                SweetAlertBox('error','Not Uploaded', 'Sorry!, This event images is not uploaded', () => {
                    handleClose()
                })
            }
        }
        catch (e) {
            console.error(e)
        }
    }

    return (
        <div>
            <CustomButton
                type={"button"}
                buttonText={"Add"}
                onClick={() => setShow(true)}
            />
            {
                show && show === true ?
                    <Modal show={show} size={'lg'} onHide={handleClose} keyboard={false} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>New Images</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Container>
                                <div className='fileUploadBox'>
                                    <FileUploader
                                        name="file"
                                        handleChange={handleChange}
                                        types={["JPG", "PNG", "JPEG"]}
                                        multiple={true}
                                        label={'Drop your image here, or browse'}
                                    />
                                </div>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer>
                            {
                                selectImage && selectImage === true ?
                                    <Button variant="outline-primary" onClick={() => SaveEventImages(eventID)}>Save</Button> : ""
                            }
                            <Button variant="outline-secondary" onClick={handleClose}>Close</Button>
                        </Modal.Footer>
                    </Modal>
                    : ''
            }


        </div>
    )
}

export default AddNewImage