import React from 'react'
import CustomButton from '../Buttons/custom/CustomButton'

const FilterBox = ({ filterText, onFilter, onClear, className }) => {
    return (
        <div className={`${className} d-flex justify-content-end align-items-center`}>
            <input
                className='p-2'
                id="search"
                type="text"
                placeholder="Filter table data..."
                autoComplete='off'
                value={filterText}
                onChange={onFilter}
            />
            <CustomButton buttonText={"X"} onClick={onClear} />
        </div>
    )
}

export default FilterBox