import React, { useState, useRef, useEffect } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import FormikInput from '../../components/filedbox/formikInput';
import { IMAGE_DEFAULT_AVATER } from '../../assets';
import { useFormik } from 'formik';
import { SingleClient_Feedback_Data } from '../../services/getServices';
import { EditClient_Feedback_Data, NewClient_Feedback_Data } from '../../services/postServices';
import { SweetAlertBox } from '../../components/alert/sweetAlertPop';
import { validationSchemaClientFeedback } from '../../utils/validations';
import { customChooseImage } from '../../utils/globalFunctions';

const ClientFeedabckForm = ({ feebackId, handleShow, handleClose, }) => {
  const initialValues = {
    profile: "",
    firstName: "",
    lastName: "",
    designation: "",
    rating: "",
    feedbackTitle: "",
    feedbackDescription: "",
  };

  const [customerData, setCustomerData] = useState(initialValues);
  const [memberImage, setMemberImage] = useState({});
  const [selectImage, setSelectImage] = useState(null);
  const [imageError, setImageError] = useState("");
  const inputImageRef = useRef(null)



  // eslint-disable-next-line
  const singleMemberData = async (feebackId) => {
    try {
      const res = await SingleClient_Feedback_Data(feebackId);
      setCustomerData(res.data.data)
      setMemberImage(res.data.data.profile)
    } catch (e) {
      console.error(e)
    }
  }

  const onSubmit = async () => {
    const formData = new FormData()
    formData.append("profile", selectImage)
    formData.append("firstName", formik.values.firstName)
    formData.append("lastName", formik.values.lastName)
    formData.append("designation", formik.values.designation)
    formData.append("rating", formik.values.rating)
    formData.append("feedbackTitle", formik.values.feedbackTitle)
    formData.append("feedbackDescription", formik.values.feedbackDescription)

    try {
      if (feebackId !== "") {
        const res = await EditClient_Feedback_Data(feebackId, formData)
        if (res.status === 200) {
          SweetAlertBox('success', 'Updated Successfully', 'Client Feedback has been updated successfully.', () => {
            handleClose()
          })
        }
        else {
          SweetAlertBox('error', 'Not Updated', 'Sorry! This Client Feedback has been not updated.', () => {
            handleClose()
          })
        }
      }
      else {
        const res = await NewClient_Feedback_Data(formData)
        if (res.status === 200) {
          SweetAlertBox('success', 'Added Successfully', 'Client Feedback has been Added successfully.', () => {
            handleClose()
          })
        }
        else {
          SweetAlertBox('error', 'Not Saved', 'Sorry! This Client Feedback has been not saved.', () => {
            handleClose()
          })
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: customerData,
    validationSchema: validationSchemaClientFeedback,
    onSubmit
  })

  useEffect(() => {
    if (feebackId !== "") {
      singleMemberData(feebackId);
    }
  }, [feebackId]);


  return (
    <div>
      <Modal show={handleShow} size={"lg"} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{feebackId !== "" ? "Edit Client Feedback" : "New Client Feedback"}</Modal.Title>
        </Modal.Header>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <Row>
              <div className="w-100 mb-3">
                <div className="team_profile_image text-center my-3">
                  <img
                    src={selectImage ? (URL.createObjectURL(selectImage)) : (formik.values.profile)}
                    alt="Not found"
                    className="rounded-circle w-100 h-100 object-fit-cover"
                    onClick={() => inputImageRef.current.click()}
                    title='choose your image'
                    onError={(e) => { e.target.src = IMAGE_DEFAULT_AVATER }}
                  />
                </div>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="file"
                    ref={inputImageRef}
                    name="filename"
                    accept='.png, .jpg, .jpeg'
                    className="d-none"
                    onChange={(e) => {
                      const res = customChooseImage(e)
                      setImageError(res.error)
                      setSelectImage(res.file)
                    }}
                  />
                </Form.Group>
                {
                  imageError && imageError !== "" ? <span className='text-danger fw-semibold'>{imageError}</span> : ''
                }
              </div>
            </Row>
            <Row>
              <Col>
                <FormikInput
                  fieldName="Firstname"
                  type="text"
                  as="input"
                  name="firstName"
                  formik={formik}
                />
              </Col>
              <Col>
                <FormikInput
                  fieldName="Lastname"
                  type="text"
                  as="input"
                  name="lastName"
                  formik={formik}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormikInput
                  fieldName="Rating"
                  type="number"
                  as="input"
                  name="rating"
                  formik={formik}
                />
              </Col>
              <Col>
                <FormikInput
                  fieldName="Designation"
                  type="text"
                  as="input"
                  name="designation"
                  formik={formik}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormikInput
                  fieldName="Feedback Title"
                  type="text"
                  as="input"
                  name="feedbackTitle"
                  formik={formik}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormikInput
                  fieldName="Feedback Description"
                  type="text"
                  as="textarea"
                  name="feedbackDescription"
                  formik={formik}
                />
              </Col>
            </Row>
            <Row className='px-3'>
              <Button variant="primary" type='submit'>Submit</Button>
            </Row>
          </Modal.Body>
        </Form>
      </Modal>
    </div>
  );
}

export default ClientFeedabckForm