import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CustomButton from "../../../components/Buttons/custom/CustomButton"
import DeleteButton from '../../../components/Buttons/deleteButton/deleteButton'
import CustomLoader from '../../../components/loader/loader'
import MainTitle from '../../../components/mainTitle/mainTitle'
import { FEEDBACK_API } from '../../../constant/api'
import { AllFeedback_Data } from '../../../services/getServices'
import DataTableCom from '../../../components/datatable/datatablecom'
import { useSelector } from 'react-redux';
import { selectorUserRole } from '../../../redux/selector';
import { roleSuperUser } from '../../../utils/globalVariable';
import CustomBtn from '../../../components/Buttons/CustomButton/CustomBtn'
import { FcEditImage } from 'react-icons/fc'


const Feedback = () => {

    const userRole = useSelector(selectorUserRole);
    const [feedbackData, setFeedbackData] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const columns = [
        {
            name: 'No',
            selector: (row, index) => index + 1,
            sortable: true,
            width: '5%',
            minWidth: '5%',
        },
        {
            name: 'Name',
            selector: (row) => row.firstName + " " + row.lastName,
            sortable: true,
        },
        {
            name: 'Email',
            sortable: true,
            selector: (row) => row.email,
        },
        {
            name: 'Mobile',
            sortable: true,
            selector: (row) => row.number,
        },
        {
            name: 'Designation',
            sortable: true,
            selector: (row) => row.designation,
        },
        {
            name: 'Company',
            sortable: true,
            selector: (row) => row.company,
        },
        {

            name: 'Actions',
            button: true,
            cell: (item) => {
                return (
                    <div className='d-flex gap-3'>
                        <CustomBtn
                            btnIcon={<FcEditImage size={20} />}
                            btnText=""
                            className="actionEdit"
                            onClick={() => navigate(`/getInvolved/feedback/${item._id}`)}
                        />
                        {
                            userRole && userRole === roleSuperUser ?
                                <DeleteButton
                                    Id={item._id}
                                    apiRoute={FEEDBACK_API}
                                    handleBack={() => { fetchAllFeedbackData() }}
                                /> : ""
                        }
                    </div>
                )
            }
        }

    ];

    // fetch all feedback data
    const fetchAllFeedbackData = async () => {
        setLoading(true);
        try {
            const res = await AllFeedback_Data();
            setFeedbackData(res.data.data);
            setLoading(false);
        }
        catch (res) {
            console.error(res)
        }
    }

    useEffect(() => {
        fetchAllFeedbackData();
    }, [])


    return (
        <div>
            <CustomLoader show={loading} />

            <div className="d-flex flex-column flex-sm-row gap-3 justify-content-center justify-content-sm-between align-items-center w-100 pb-4 px-3">
                <MainTitle heading={"Feedbacks"} textAlign={"center"} />
                <CustomButton buttonText={"Add"} onClick={() => navigate('/getInvolved/newFeedback')} />
            </div>

            <div className='pb-5'>
                <DataTableCom
                    columns={columns}
                    data={feedbackData}
                    refreshData={fetchAllFeedbackData}
                />
            </div>
        </div>
    )
}

export default Feedback