import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Col, Container, Form, Row } from "react-bootstrap";
import MainTitle from "../../../components/mainTitle/mainTitle";
import CustomButton from "../../../components/Buttons/custom/CustomButton";
import { EditMember_Data } from "../../../services/postServices";
import { useFormik } from "formik";
import "./MemberForm.css";
import { SingleMember_Data } from "../../../services/getServices";
import CustomLoader from "../../../components/loader/loader";
import { IMAGE_DEFAULT_AVATER } from "../../../assets";
import FormikInput from '../../../components/filedbox/formikInput'
import { SweetAlertBox } from "../../../components/alert/sweetAlertPop";
import BackButton from "../../../components/Buttons/custom/BackButton";
import FormikSelect from "../../../components/filedbox/formikSelect";
import { validationSchemaEditMemberForm } from "../../../utils/validations";
import { customChooseImage } from "../../../utils/globalFunctions";
import { FaLinkedin } from "react-icons/fa";


const EditMemberForm = () => {

    const initialValues = {
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        linkedIn: "",
        city: "",
        area: "",
        company: "",
        designation: "",
        yearOfExperience: "",
        salesExperience: "",
        areaOfExpertise: "",
        skillSet: "",
        areaOfInterest: "",
        whereYouHear: "",
        addValueToCommunity: "",
        lookingFor: "",
        profile: "",
        approved: "",
        additionalComment: "",
    };

    const navigate = useNavigate();
    const { userId } = useParams();
    const inputImageRef = useRef(null)
    const currentPath = useLocation()
    const checkUrls = currentPath.pathname.split('/')
    const [MemberData, setMemberData] = useState(initialValues);
    const [selectImage, setSelectImage] = useState(null);
    const [imageError, setImageError] = useState("");
    const [loading, setLoading] = useState(false);

    const onSubmit = async () => {
        try {
            const formData = new FormData();
            formData.append("profile", selectImage)
            formData.append("firstName", formik.values.firstName)
            formData.append("lastName", formik.values.lastName)
            formData.append("mobile", formik.values.mobile)
            formData.append("linkedIn", formik.values.linkedIn)
            formData.append("city", formik.values.city)
            formData.append("area", formik.values.area)
            formData.append("company", formik.values.company)
            formData.append("designation", formik.values.designation)
            formData.append("yearOfExperience", formik.values.yearOfExperience)
            formData.append("salesExperience", formik.values.salesExperience)
            formData.append("areaOfExpertise", formik.values.areaOfExpertise)
            formData.append("skillSet", formik.values.skillSet)
            formData.append("areaOfInterest", formik.values.areaOfInterest)
            formData.append("whereYouHear", formik.values.whereYouHear)
            formData.append("addValueToCommunity", formik.values.addValueToCommunity)
            formData.append("lookingFor", formik.values.lookingFor)
            formData.append("additionalComment", formik.values.additionalComment)
            formData.append("approved", formik.values.approved === "yes" ? true : false)

            const resMember = await EditMember_Data(userId, formData);
            if (resMember.status === 200) {
                SweetAlertBox('success', 'Update Member', `${formik.values.firstName + " " + formik.values.lastName}'s member has been updated successfully.`, () => {
                    navigate('/members')
                })
            }
            else {
                SweetAlertBox('error', 'Not Updated', `Sorry! ${formik.values.firstName + " " + formik.values.lastName}'s member has been not updated.`, () => {
                    navigate('/members')
                })
            }
        } catch (e) {
            console.error(e);
        }

    };

    const singleMemberData = async (userId) => {
        setLoading(true);
        try {
            const res = await SingleMember_Data(userId);
            setMemberData(res.data.data);
            setLoading(false);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        if (userId !== undefined) {
            singleMemberData(userId);
        }
    }, [userId]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: MemberData,
        validationSchema: validationSchemaEditMemberForm,
        onSubmit,
    });

    return (
        <div>
            <CustomLoader isLoading={loading} />
            <Container>
                <Row className='py-4'>
                    <Col sm={2}>
                        <BackButton />
                    </Col>
                    <Col sm={8}>
                        <MainTitle
                            heading={checkUrls.includes('edit') ? "Edit Member" : "View Member"}
                            textAlign={"center"}
                        />
                    </Col>
                    <Col sm={2}>
                        {
                            checkUrls.includes('view') ?
                                <CustomButton type="button" buttonText="edit" onClick={() => navigate(`/members/${userId}/edit/`)} /> : ""
                        }
                    </Col>
                </Row>

                <Container className="allDataTable p-4 pb-0 rounded">
                    <Form onSubmit={formik.handleSubmit} className="poolForm mb-5">

                        <div className="w-100 mb-3">
                            <div className="team_profile_image text-center my-3">
                                <img
                                    src={selectImage !== null ? (URL.createObjectURL(selectImage)) : formik?.values.profile !== "" ? formik?.values.profile : IMAGE_DEFAULT_AVATER}
                                    alt="Not found"
                                    className="rounded-circle w-100 h-100 object-fit-cover"
                                    onClick={() => checkUrls.includes('edit') ? inputImageRef.current.click() : ''}
                                    onError={(e) => { e.target.src = IMAGE_DEFAULT_AVATER }}
                                />
                            </div>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    type="file"
                                    name="filename"
                                    ref={inputImageRef}
                                    className="d-none"
                                    accept='.png, .jpg, .jpeg'
                                    onChange={(e) => {
                                        const res = customChooseImage(e)
                                        setImageError(res.error)
                                        setSelectImage(res.file)
                                    }}
                                />
                            </Form.Group>
                            {
                                imageError && imageError !== '' ? <span className='text-danger fw-semibold'>{imageError}</span> : ''
                            }
                        </div>
                        <Row md={1} sm={1} lg={2}>
                            <Col>
                                <FormikInput
                                    fieldName={"Firstname"}
                                    disabled={checkUrls.includes('edit') ? false : true}
                                    type={"text"}
                                    as={"input"}
                                    name={"firstName"}
                                    placeholder={"enter firstname"}
                                    formik={formik}
                                />
                            </Col>
                            <Col>
                                <FormikInput
                                    fieldName={"Lastname"}
                                    disabled={checkUrls.includes('edit') ? false : true}
                                    type={"text"}
                                    as={"input"}
                                    name={"lastName"}
                                    placeholder={"enter lastname"}
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row md={1} sm={1} lg={2}>
                            <Col>
                                <FormikInput
                                    fieldName={"Email Address"}
                                    disabled={checkUrls.includes('edit') ? false : true}
                                    type={"email"}
                                    as={"input"}
                                    name={"email"}
                                    placeholder={"enter email address"}
                                    formik={formik}
                                />
                            </Col>
                            <Col>
                                <FormikInput
                                    fieldName={"Mobile"}
                                    disabled={checkUrls.includes('edit') ? false : true}
                                    type={"number"}
                                    as={"input"}
                                    name={"mobile"}
                                    placeholder={"enter mobile number"}
                                    formik={formik}
                                />
                            </Col>
                        </Row>

                        <Row md={1} sm={1} lg={2}>
                            <Col>
                                <FormikInput
                                    fieldName={"Area"}
                                    disabled={checkUrls.includes('edit') ? false : true}
                                    type={"text"}
                                    as={"input"}
                                    name={"area"}
                                    required={true}
                                    placeholder={"enter area"}
                                    formik={formik}
                                />
                            </Col>
                            <Col>
                                <FormikInput
                                    fieldName={"City"}
                                    disabled={checkUrls.includes('edit') ? false : true}
                                    type={"text"}
                                    as={"input"}
                                    name={"city"}
                                    required={true}
                                    placeholder={"enter city"}
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row md={1} sm={1} lg={2}>
                            <Col>
                                <FormikInput
                                    fieldName={"Designation"}
                                    disabled={checkUrls.includes('edit') ? false : true}
                                    type={"text"}
                                    as={"input"}
                                    name={"designation"}
                                    required={true}
                                    placeholder={"enter designation"}
                                    formik={formik}
                                />
                            </Col>
                            <Col>
                                <FormikInput
                                    fieldName={"Company"}
                                    disabled={checkUrls.includes('edit') ? false : true}
                                    type={"text"}
                                    as={"input"}
                                    name={"company"}
                                    required={true}
                                    placeholder={"enter company name"}
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="position-relative">
                                <FormikInput
                                    fieldName={"LinkedIn"}
                                    disabled={checkUrls.includes('edit') ? false : true}
                                    type={"text"}
                                    as={"input"}
                                    name={"linkedIn"}
                                    required={true}
                                    placeholder={"enter linkedin"}
                                    formik={formik}
                                />
                                {
                                    formik.values.linkedIn !== '' ?
                                        <Link to={formik.values.linkedIn} target="_blank">
                                            <div className="position-absolute cursor-pointer" style={{ right: '20px', top: '10px' }}>
                                                <FaLinkedin fontSize={25} />
                                            </div>
                                        </Link> : ""
                                }
                            </Col>
                        </Row>
                        <Row md={1} sm={1} lg={3}>
                            <Col>
                                <FormikInput
                                    fieldName={"Year of experience"}
                                    disabled={checkUrls.includes('edit') ? false : true}
                                    type={"text"}
                                    as={"input"}
                                    name={"yearOfExperience"}
                                    required={true}
                                    placeholder={"enter year of experience"}
                                    formik={formik}
                                />
                            </Col>
                            <Col>
                                <FormikInput
                                    fieldName={"Sales Experience"}
                                    disabled={checkUrls.includes('edit') ? false : true}
                                    type={"text"}
                                    as={"input"}
                                    name={"salesExperience"}
                                    required={true}
                                    placeholder={"enter sales experience"}
                                    formik={formik}
                                />
                            </Col>
                            <Col>
                                <FormikInput
                                    fieldName={"Area of Expertise"}
                                    disabled={checkUrls.includes('edit') ? false : true}
                                    type={"text"}
                                    as={"input"}
                                    name={"areaOfExpertise"}
                                    required={true}
                                    placeholder={"enter area of expertise"}
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row md={1} sm={1} lg={2}>
                            <Col>
                                <FormikInput
                                    fieldName={"Skill Set"}
                                    disabled={checkUrls.includes('edit') ? false : true}
                                    type={"text"}
                                    as={"textarea"}
                                    name={"skillSet"}
                                    required={true}
                                    placeholder={"enter area skill set"}
                                    formik={formik}
                                />
                            </Col>
                            <Col>
                                <FormikInput
                                    fieldName={"Area of Interest"}
                                    disabled={checkUrls.includes('edit') ? false : true}
                                    type={"text"}
                                    as={"textarea"}
                                    name={"areaOfInterest"}
                                    required={true}
                                    placeholder={"enter area of interest"}
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row md={1} sm={1} lg={2}>
                            <Col>
                                <FormikInput
                                    fieldName={"Add Value To Community"}
                                    disabled={checkUrls.includes('edit') ? false : true}
                                    type={"text"}
                                    as={"textarea"}
                                    name={"addValueToCommunity"}
                                    required={true}
                                    placeholder={"enter add value to community"}
                                    formik={formik}
                                />
                            </Col>
                            <Col>
                                <FormikInput
                                    fieldName={"Looking For"}
                                    disabled={checkUrls.includes('edit') ? false : true}
                                    type={"text"}
                                    as={"textarea"}
                                    name={"lookingFor"}
                                    required={true}
                                    placeholder={"enter looking for"}
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <FormikInput
                                fieldName={"Additional Comment"}
                                disabled={checkUrls.includes('edit') ? false : true}
                                type={"text"}
                                as={"textarea"}
                                name={"additionalComment"}
                                required={true}
                                placeholder={"enter additional comment"}
                                formik={formik}
                            />
                        </Row>
                        <Row>
                            <FormikSelect
                                dataList={[
                                    {
                                        label: "Approved",
                                        value: "yes"
                                    },
                                    {
                                        label: "Not Approved",
                                        value: "no"
                                    }
                                ]}
                                fieldName={"Approved"}
                                disabled={checkUrls.includes('edit') ? false : true}
                                name={"approved"}
                                formik={formik}
                            />
                        </Row>

                        {
                            checkUrls.includes('edit') ?
                                <div className="d-flex justify-content-around align-items-center py-3">
                                    <CustomButton buttonText={"Submit"} type={"submit"} />
                                </div> : ""
                        }
                    </Form>
                </Container>

            </Container>
        </div>
    );
};

export default EditMemberForm