import CryptoJS from 'crypto-js'
const secretKey = "_Fk5M%fyaprG_Ho7%R26ez7bp{5wR#S)+g?h2_Rd(X~&Q:(="

export const convertEncrypted = (data) => {
    const jsonToString = JSON.stringify(data)
    const encryptedData = CryptoJS.AES.encrypt(jsonToString, secretKey).toString();
    return encryptedData;
}

export const convertDecrypted = (encryptedData) => {
    try {
        const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
        const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
        const decryptedObject = JSON.parse(decryptedString);
        return decryptedObject
    }
    catch (e) {
        console.error(e)
    }
}


export const customChooseImage = (e) => {
    const selectedFile = e?.target?.files[0];
    const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    const maxSizeKB = 1024;

    if (selectedFile !== undefined) {
        if (!allowedTypes.includes(selectedFile?.type)) {
            e.target.value = null;
            return { error: "Please Select Only JPG/PNG/JPEG Format", file: null }
        }
        else if ((selectedFile.size <= maxSizeKB * 1024) === false) {
            e.target.value = null;
            return { error: "Please choose Minimum 1MB image", file: null }
        }
        else {
            return { error: "", file: selectedFile }
        }
    }
}

export const SortAscendingFuncation = (data) => {
    const sortData = data?.slice().sort((a, b) => {
        const nameA = a.firstName.toUpperCase();
        const nameB = b.firstName.toUpperCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
    })

    return sortData
}