import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import { SingleFeedback_Data } from "../../../services/getServices";
import { EditFeedback_Data, NewFeedback_Data } from "../../../services/postServices";

import CustomButton from "../../../components/Buttons/custom/CustomButton";
import { SweetAlertBox } from "../../../components/alert/sweetAlertPop";
import FormikInput from "../../../components/filedbox/formikInput";
import FormikRadio from "../../../components/filedbox/formikRadio";
import CustomLoader from "../../../components/loader/loader";
import MainTitle from "../../../components/mainTitle/mainTitle";
import BackButton from "../../../components/Buttons/custom/BackButton";
import { validationSchemaFeedbackForm } from "../../../utils/validations";


const FeedbackForm = () => {

    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        designation: '',
        company: '',
        eventExperience: '',
        improvementSuggestions: '',

        eventRating: '',
        speakerPresentation: '',

        eventEngaging: '',
        eventVenueFacilitiesTimings: '',
        eventTopicInterest: '',

        eventinFormativeValuable: '',
        opportunitiesToConnect: '',

        enoughNetworkingBreaks: '',
        eventOrganizedCommunicated: '',
        eventParticularlyEnjoy: '',
        topicsInFutureEvents: '',

        attendFutureEvents: '',
    };

    // state define
    const { feedbackId } = useParams();
    const navigate = useNavigate();
    const [feedbackData, setFeedbackData] = useState(initialValues);
    const [loading, setLoading] = useState(false);


    // all functions

    // fetch single feedback data
    const singleFeedbackData = async () => {
        setLoading(true);
        try {
            const res = await SingleFeedback_Data(feedbackId);
            setFeedbackData(res.data);
            setLoading(false);
        } catch (e) {
            console.error(e);
        }
    };


    // submit form
    const onSubmit = async () => {
        const formData = {
            firstName: formik.values.firstName,
            lastName: formik.values.lastName,
            email: formik.values.email,
            number: formik.values.mobile,
            designation: formik.values.designation,
            company: formik.values.company,
            eventExperience: formik.values.eventExperience,
            improvementSuggestions: formik.values.improvementSuggestions,
            eventRating: formik.values.eventRating,
            speakerPresentation: formik.values.speakerPresentation,
            eventEngaging: formik.values.eventEngaging === "" ? " " : formik.values.eventEngaging,
            eventVenueFacilitiesTimings: formik.values.eventVenueFacilitiesTimings === "" ? " " : formik.values.eventVenueFacilitiesTimings,
            eventTopicInterest: formik.values.eventTopicInterest === "" ? " " : formik.values.eventTopicInterest,
            eventinFormativeValuable: formik.values.eventinFormativeValuable,
            opportunitiesToConnect: formik.values.opportunitiesToConnect,
            enoughNetworkingBreaks: formik.values.enoughNetworkingBreaks === "" ? " " : formik.values.enoughNetworkingBreaks,
            eventOrganizedCommunicated: formik.values.eventOrganizedCommunicated === "" ? " " : formik.values.eventOrganizedCommunicated,
            eventParticularlyEnjoy: formik.values.eventParticularlyEnjoy === "" ? " " : formik.values.eventParticularlyEnjoy,
            topicsInFutureEvents: formik.values.topicsInFutureEvents === "" ? " " : formik.values.topicsInFutureEvents,
            attendFutureEvents: formik.values.attendFutureEvents === "" ? " " : formik.values.attendFutureEvents,
        }

        if (feedbackId === undefined) {
            const res = await NewFeedback_Data(formData)
            if (res.status === 200) {
                SweetAlertBox("success", "Saved Feedback", `${formik.values.firstName + " " + formik.values.lastName} is saved successfully`, () => {
                    navigate('/getInvolved/feedback/')
                })
            }
            else {
                SweetAlertBox("error", "Not Saved", `Sorry! ${formik.values.firstName + " " + formik.values.lastName} has been not saved.`, () => {
                    navigate('/getInvolved/feedback/')
                })
            }
        }

        else {
            const res = await EditFeedback_Data(feedbackId, formData)
            if (res.status === 200) {
                SweetAlertBox("success", "Update Feedback", `${formik.values.firstName + " " + formik.values.lastName} is update successfully`, () => {
                    navigate('/getInvolved/feedback/')
                })
            }
            else {
                SweetAlertBox("error", "Not Updated", `Sorry! ${formik.values.firstName + " " + formik.values.lastName} has been not  updated.`, () => {
                    navigate('/getInvolved/feedback/')
                })
            }
        }
    };


    useEffect(() => {
        if (feedbackId !== undefined) {
            singleFeedbackData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [feedbackId])


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: feedbackData,
        validationSchema: validationSchemaFeedbackForm,
        onSubmit,
    });


    return (
        <div>
            <CustomLoader show={loading} />

            <Container>

                <div className="d-flex flex-column flex-sm-row gap-3 justify-content-center justify-content-sm-between align-items-center w-100 pb-4 px-3">
                    <BackButton />
                    <MainTitle heading={feedbackId && feedbackId !== undefined ? "Edit Feedback" : "New Feedback"} textAlign={"center"} />
                </div>

                <Container className="allDataTable p-4 pb-0 rounded">
                    <Form onSubmit={formik.handleSubmit} className="poolForm mb-5">
                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName="Firstname"
                                    type="text"
                                    as="input"
                                    name="firstName"
                                    placeholder="enter firstname"
                                    formik={formik}
                                />
                            </Col>
                            <Col>
                                <FormikInput
                                    fieldName="Lastname"
                                    type="text"
                                    as="input"
                                    name="lastName"
                                    placeholder="enter lastname"
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName="Email Address"
                                    type="email"
                                    as="input"
                                    name="email"
                                    placeholder="enter email address"
                                    formik={formik}
                                />
                            </Col>
                            <Col>
                                <FormikInput
                                    fieldName="Mobile"
                                    type="text"
                                    as="input"
                                    name="mobile"
                                    placeholder="enter mobile"
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName="Designation"
                                    type="text"
                                    as="input"
                                    name="designation"
                                    placeholder="enter designation"
                                    formik={formik}
                                />
                            </Col>
                            <Col>
                                <FormikInput
                                    fieldName="Company"
                                    type="text"
                                    as="input"
                                    name="company"
                                    placeholder="enter company"
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName="Event Experience"
                                    type="text"
                                    as="textarea"
                                    name="eventExperience"
                                    placeholder="enter event experience"
                                    formik={formik}
                                />
                            </Col>
                            <Col>
                                <FormikInput
                                    fieldName="Improvement Suggestions"
                                    type="text"
                                    as="textarea"
                                    name="improvementSuggestions"
                                    placeholder="enter improvement suggestions"
                                    formik={formik}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <FormikRadio
                                    dataList={[
                                        {
                                            label: "Excellent",
                                            value: "Excellent"
                                        },
                                        {
                                            label: "Above Average",
                                            value: "AboveAverage"
                                        },
                                        {
                                            label: "Avarage Below",
                                            value: "AvarageBelow"
                                        },
                                        {
                                            label: "Average Poor",
                                            value: "AveragePoor"
                                        }
                                    ]}
                                    fieldName={'Overall Event Rating'}
                                    name={'eventRating'}
                                    formik={formik}
                                />
                            </Col>
                            <Col>
                                <FormikRadio
                                    dataList={[
                                        {
                                            label: "Outstanding",
                                            value: "outstanding"
                                        },
                                        {
                                            label: "Good",
                                            value: "good"
                                        },
                                        {
                                            label: "Mediocre",
                                            value: "mediocre"
                                        },
                                        {
                                            label: "Below Par",
                                            value: "belowpar"
                                        }
                                    ]}
                                    fieldName={"Rate the speaker's knowledge and presentation?"}
                                    name={'speakerPresentation'}
                                    formik={formik}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName="Event Engaging"
                                    type="text"
                                    as="textarea"
                                    name="eventEngaging"
                                    placeholder="enter event engaging"
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName="Event Venue Facilities Timings"
                                    type="text"
                                    as="textarea"
                                    name="eventVenueFacilitiesTimings"
                                    placeholder="enter event venue facilities timings"
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName="event Topic Interest"
                                    type="text"
                                    as="textarea"
                                    name="eventTopicInterest"
                                    placeholder="enter eventTopicInterest"
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormikRadio
                                    dataList={[
                                        {
                                            label: 'Yes',
                                            value: 'yes'
                                        },
                                        {
                                            label: 'No',
                                            value: 'no'
                                        }
                                    ]}
                                    fieldName={'Was the session informative and valuable'}
                                    name={'eventinFormativeValuable'}
                                    formik={formik}
                                />
                            </Col>
                            <Col>
                                <FormikRadio
                                    dataList={[
                                        {
                                            label: 'Certainly',
                                            value: 'certainly'
                                        },
                                        {
                                            label: 'Absolutely',
                                            value: 'absolutely'
                                        }
                                    ]}
                                    fieldName={'Opportunities to network with other attendees?'}
                                    name={'opportunitiesToConnect'}
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName="Enough Networking Breaks"
                                    type="text"
                                    as="textarea"
                                    name="enoughNetworkingBreaks"
                                    placeholder="enter people commit to participate"
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName="Event Organized Communicated"
                                    type="text"
                                    as="textarea"
                                    name="eventOrganizedCommunicated"
                                    placeholder="enter event organized communicated"
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName="Event Particularly Enjoy"
                                    type="text"
                                    as="textarea"
                                    name="eventParticularlyEnjoy"
                                    placeholder="enter event particularly enjoy"
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName="Topics In Future Events"
                                    type="text"
                                    as="textarea"
                                    name="topicsInFutureEvents"
                                    placeholder="enter topics in future events"
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName={"How likely are you to attend our future events?"}
                                    name={"attendFutureEvents"}
                                    type={"text"}
                                    formik={formik}
                                />
                            </Col>
                        </Row>

                        <div className="d-flex justify-content-around align-items-center py-3">
                            <CustomButton buttonText={"Submit"} type={"submit"} />
                        </div>
                    </Form>
                </Container>
            </Container>
        </div>
    );
}

export default FeedbackForm