import React, { useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { FcEditImage } from 'react-icons/fc'
import { useFormik } from 'formik';
import FormikInput from '../../components/filedbox/formikInput'
import { SingleContactUs_Data } from '../../services/getServices'
import { BsPlusLg } from 'react-icons/bs';
import CustomButton from '../../components/Buttons/custom/CustomButton';
import { EditContactUs_Data, NewContactUs_Data } from '../../services/postServices';
import { SweetAlertBox } from '../../components/alert/sweetAlertPop';
import { Oval } from 'react-loader-spinner';
import { validationSchemaContactUs } from '../../utils/validations';


const ContactUsForm = ({ contactId, refreshData }) => {

    const initialValues = {
        firstName: "",
        lastName: "",
        email: "",
        mobileNumber: "",
        message: ""
    };

    const [contactData, setContactData] = useState(initialValues);
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false)

    const onSubmit = async () => {
        try {
            const formData = {
                firstName: formik.values.firstName,
                lastName: formik.values.lastName,
                email: formik.values.email,
                mobileNumber: formik.values.mobileNumber.toString(),
                message: formik.values.message,
            }
            setLoading(true)
            if (contactId !== undefined) {
                const res = await EditContactUs_Data(contactId, formData)
                if (res.status === 200) {
                    SweetAlertBox("success", "Contact Us Updated", "Contact US record is update successfully.", () => refreshData())
                }
                else {
                    SweetAlertBox("error", "Not Updated", "Sorry! This contact us record has been not updated.", () => refreshData())
                }
            }
            else {
                const res = await NewContactUs_Data(formData)
                if (res.status === 200) {
                    SweetAlertBox("success", "Contact Us Saved", "Contact US record is saved successfully.", () => refreshData())
                }
                else {
                    SweetAlertBox("error", "Not Saved", "Sorry! This contact us record has been not saved.", () => refreshData())
                }
            }
            setShow(false)
            setLoading(false)
            setContactData({
                firstName: "",
                lastName: "",
                email: "",
                mobileNumber: "",
                message: ""
            })
        }
        catch (e) {
            console.error(e)
            setLoading(false)
        }
    }

    const singleContactData = async (cid) => {
        try {
            const res = await SingleContactUs_Data(cid)
            setContactData(res.data.data)
        }
        catch (e) {
            console.error(e)
        }
    }

    const actionEditRecord = (cid) => {
        setShow(true)
        singleContactData(cid)
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: contactData,
        validationSchema: validationSchemaContactUs,
        onSubmit
    })

    return (
        <div>
            {
                contactId === undefined ?
                    <CustomButton buttonText={<BsPlusLg fontSize={"22px"} />} title="New Contact US" onClick={() => setShow(true)} />
                    :
                    <button className='actionEdit' onClick={() => actionEditRecord(contactId)} title='edit'>
                        <FcEditImage size={20} />
                    </button>
            }
            <Modal show={show} size={"lg"} onHide={() => setShow(false)} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {
                            contactId === undefined ? "New Contact Us" : "Edit Contact Us"
                        }
                    </Modal.Title>
                </Modal.Header>
                <Form noValidate onSubmit={formik.handleSubmit}>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName="Firstname"
                                    type="text"
                                    as="input"
                                    name="firstName"
                                    formik={formik}
                                />
                            </Col>
                            <Col>
                                <FormikInput
                                    fieldName="Lastname"
                                    type="text"
                                    as="input"
                                    name="lastName"
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName="Email Address"
                                    type="email"
                                    as="input"
                                    name="email"
                                    formik={formik}
                                />
                            </Col>
                            <Col>
                                <FormikInput
                                    fieldName="Mobile"
                                    type="number"
                                    as="input"
                                    name="mobileNumber"
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName="Message"
                                    as="textarea"
                                    name="message"
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row className='px-3'>
                            {
                                loading && loading ?
                                    <Button variant='outline-primary' className='d-flex justify-content-center align-items-center'>
                                        <Oval
                                            visible={true}
                                            height="18"
                                            width="18"
                                            color="#4fa94d"
                                            ariaLabel="oval-loading"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            secondaryColor='gray'
                                            strokeWidth={5}
                                        />
                                    </Button>
                                    :
                                    <Button variant="outline-primary" type='submit'>Submit</Button>
                            }
                        </Row>
                    </Modal.Body>
                </Form>
            </Modal>
        </div>
    )
}

export default ContactUsForm