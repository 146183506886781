import React, { useState, useEffect } from 'react';
import { Image } from 'react-bootstrap';
import { DELETE_MEMBER_API } from '../../../constant/api';
import { AllMembers_Data } from '../../../services/getServices';
import { IMAGE_DEFAULT_AVATER } from '../../../assets/index';
import { ErrorToast } from '../../../components/alert/tostPop';
import CustomButton from '../../../components/Buttons/custom/CustomButton';
import DeleteButton from '../../../components/Buttons/deleteButton/deleteButton';
import CustomLoader from '../../../components/loader/loader';
import MainTitle from '../../../components/mainTitle/mainTitle';
import DataTableCom from '../../../components/datatable/datatablecom';
import { FaFileExport } from 'react-icons/fa'
import csvDownload from 'json-to-csv-export'
import { SweetConfirm, SweetAlertBox } from '../../../components/alert/sweetAlertPop';
import { EditMemberShip_Data } from '../../../services/postServices';
import { useSelector } from 'react-redux';
import { selectorUserRole } from '../../../redux/selector';
import { roleSuperUser } from '../../../utils/globalVariable';
import CustomBtn from '../../../components/Buttons/CustomButton/CustomBtn';
import { FcCancel, FcCheckmark, FcEditImage } from 'react-icons/fc';
import { useNavigate } from 'react-router-dom';



const MemberShipPage = () => {

    const userRole = useSelector(selectorUserRole);
    const [memberData, setMemberData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [exportCSVData, setExportCSVData] = useState(null)
    const navigate = useNavigate();


    // fetch all members data
    const fetchMembersData = async () => {
        setLoading(true);
        try {
            const res = await AllMembers_Data();
            const allMembers = res?.data.data || [];
            // const combineMemberData = [...(allMembers.approved || []), ...(allMembers.notApproved || [])]
            setMemberData(allMembers);
            let exportData = []
            allMembers.map((item, index) => (
                exportData = [
                    ...exportData,
                    {
                        no: index + 1,
                        firstname: item.firstName,
                        lastname: item.lastName,
                        email: item.email,
                        mobile: item.mobile,
                        city: item.city,
                        company: item.company,
                        designation: item.designation,

                        memberShip: item.approved ? "approved" : "notApproved"
                    }
                ]
            ))
            const data = {
                data: exportData,
                filename: "Membership Data List",
                delimiter: ',',
                headers: ["No", "Firstname", "Lastname", "Email Address", "Mobile", "City", "Company", "Designation", "MemberShip"]
            }
            setExportCSVData(data)
            setLoading(false);
        }
        catch (err) {
            console.error(err);
            setLoading(false);
        }
    }

    const handleActionButton = (memberData, action) => {
        const sendMemberData = {
            firstName: memberData.firstName,
            lastName: memberData.lastName,
            email: memberData.email,
        }
        if (action === "yes") {
            sendMemberData.approved = true;
            SweetConfirm('Membership Approved', 'Are you sure this membership is approved?', "Confirm", async () => {
                const res = await EditMemberShip_Data(memberData.id, sendMemberData)
                if (res.status === 200) {
                    SweetAlertBox('success', "Membership Approved", "This membership is approved successfully", () => {
                        fetchMembersData()
                    })
                }
                else {
                    ErrorToast("Sorry, This record is updated.")
                    fetchMembersData()
                }
            })
        }
        else {
            sendMemberData.approved = false;
            SweetConfirm('Membership Not Approved', 'Are you sure this membership is not approved?', "Confirm", async () => {
                const res = await EditMemberShip_Data(memberData.id, sendMemberData)
                if (res.status === 200) {
                    SweetAlertBox('success', "Membership Not Approved", "This membership is not approved successfully", () => {
                        fetchMembersData()
                    })
                }
                else {
                    ErrorToast("Sorry, This record is updated.")
                    fetchMembersData()
                }
            })
        }
    }


    const columns = [
        {
            name: 'No',
            selector: (row, index) => index + 1,
            sortable: true,
            width: '5%',
            minWidth: '5%',
        },
        {
            name: 'Image',
            sortable: true,
            selector: (row) => row.image,
            width: '10%',
            minWidth: '10%',
            cell: (item) => {
                return <Image src={item.profile} alt="not found" onError={(e) => e.target.src = IMAGE_DEFAULT_AVATER} style={{ width: "50px", height: "50px", objectFit: "cover" }} className="p-1 rounded-pill"></Image>
            }
        },
        {
            name: 'Name',
            selector: (row) => row.firstName + " " + row.lastName,
            sortable: true,
        },
        {
            name: 'Company',
            selector: (row) => row.company,
            sortable: true,
        },
        {
            name: 'Designation',
            selector: (row) => row.designation,
            sortable: true,
        },
        {

            name: 'Actions',
            button: true,
            cell: (item) => {
                return (
                    <>
                        {
                            item?.approved !== true ?
                                <div className="d-flex gap-3">
                                    <CustomBtn
                                        btnIcon={<FcCheckmark size={20} />}
                                        btnText=""
                                        className="checkButton"
                                        onClick={() => handleActionButton({ id: item._id, firstName: item.firstName, lastName: item.lastName, email: item.email }, 'yes')}
                                    />
                                    <CustomBtn
                                        btnIcon={<FcCancel size={20} />}
                                        btnText=""
                                        className="cancelButton"
                                        onClick={() => handleActionButton({ id: item._id, firstName: item.firstName, lastName: item.lastName, email: item.email }, 'no')}
                                    />
                                </div> :
                                <div className='d-flex gap-3'>
                                    <CustomBtn
                                        btnIcon={<FcEditImage size={20} />}
                                        btnText=""
                                        className="actionEdit"
                                        onClick={() => navigate(`/editMember/${item._id}`)}
                                    />
                                    {
                                        userRole && userRole === roleSuperUser ?
                                            <DeleteButton
                                                Id={item._id}
                                                apiRoute={DELETE_MEMBER_API}
                                                handleBack={() => { fetchMembersData() }}
                                            /> : ""
                                    }
                                </div>
                        }
                    </>
                )
            }
        }
    ];


    // change select condition in events
    const handleChangeMember = async (e) => {
        if (e.target.value === "approved") {
            setLoading(true);
            try {
                const res = await AllMembers_Data();
                const allMembers = res?.data.data || [];
                setMemberData(allMembers.approved);
                setLoading(false);
            }
            catch (err) {
                console.error(err);
                setLoading(false);
            }
        }
        else if (e.target.value === "notApproved") {
            setLoading(true);
            try {
                const res = await AllMembers_Data();
                const allMembers = res?.data.data || [];
                setMemberData(allMembers.notApproved);
                setLoading(false);
            }
            catch (err) {
                console.error(err);
                setLoading(false);
            }
        }
        else {
            fetchMembersData();
        }
    }


    useEffect(() => {
        fetchMembersData();
    }, [])


    return (
        <div>
            <CustomLoader show={loading} />

            <div className="d-flex flex-column flex-sm-row gap-3 justify-content-center justify-content-sm-between align-items-center w-100 pb-4 px-3">
                <MainTitle heading={"Membership Requests"} textAlign={"center"} />
                {
                    exportCSVData && <CustomButton className={'ms-2'} title="Export CSV" buttonText={<FaFileExport fontSize={"22px"} />} type={"button"} onClick={() => csvDownload(exportCSVData)} />
                }
            </div>


            <div className='pb-5'>
                <DataTableCom
                    columns={columns}
                    data={memberData}
                    refreshData={fetchMembersData}
                    dataList={[
                        {
                            label: "All",
                            value: "all"
                        },
                        {
                            label: "Approved",
                            value: "approved"
                        },
                        {
                            label: "Not Approved",
                            value: "notApproved"
                        },
                    ]}
                    dropdownChangeValue={handleChangeMember}
                />
            </div>
        </div>
    )
}

export default MemberShipPage