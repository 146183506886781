import React from 'react'
import './customButton.css'

const CustomBtn = ({ btnIcon, btnText, className, onClick, hoverTitle }) => {
    return (
        <button className={className} onClick={onClick} title={hoverTitle}>
            {btnIcon} {btnText}
        </button>
    )
}

export default CustomBtn