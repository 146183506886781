import React, { useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useFormik } from 'formik';
import { FcEditImage } from 'react-icons/fc';
import { BsPlusLg } from 'react-icons/bs';
import { EditEventFormData, NewEventBookSlot } from '../../../services/postServices';
import { SingleEventFormData } from '../../../services/getServices';
import FormikInput from '../../../components/filedbox/formikInput';
import FormikSelect from '../../../components/filedbox/formikSelect';
import CustomButton from '../../../components/Buttons/custom/CustomButton';
import { SweetAlertBox } from '../../../components/alert/sweetAlertPop';
import { validationSchemaSloatForm } from '../../../utils/validations';


const SloatForm = ({ eventData, sloatId, refreshData }) => {

    const initialValues = {
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        city: "",
        company: "",
        designation: "",
        linkedIn: "",
        emailSendStatus: "",
        // eventStatus: "",
        confirmationStatus: ""
    };

    const [customerData, setCustomerData] = useState(initialValues);
    const [show, setShow] = useState(false)

    // eslint-disable-next-line
    const singleMemberData = async (eventData, sloatId) => {
        setShow(true)
        try {
            const res = await SingleEventFormData(eventData, sloatId);
            setCustomerData(res.data.data)
        } catch (e) {
            console.error(e)
        }
    }


    const onSubmit = async () => {
        const formData = {
            firstName: formik.values.firstName,
            lastName: formik.values.lastName,
            email: formik.values.email,
            mobile: formik.values.mobile,
            city: formik.values.city,
            company: formik.values.company,
            designation: formik.values.designation,
            linkedIn: formik.values.linkedIn,
            // eventStatus: formik.values.eventStatus,
            emailSendStatus: formik.values.emailSendStatus,
            remark: formik.values.remark,
            confirmationStatus: formik.values.confirmationStatus,
        }

        const newBookSlotData = {
            "formMembers": [
                {
                    firstName: formik.values.firstName,
                    lastName: formik.values.lastName,
                    email: formik.values.email,
                    mobile: formik.values.mobile,
                    city: formik.values.city,
                    designation: formik.values.designation,
                    company: formik.values.company,
                    linkedIn: formik.values.linkedIn,
                    whatsappJoined: "yes",
                    confirmationStatus: formik.values.confirmationStatus,
                }
            ],
            "firstName": formik.values.firstName,
            "lastName": formik.values.lastName,
            "email": formik.values.email,
            "eventTitle": eventData.eventTitle,
            "eventDate": eventData.date,
            "eventTime": eventData.startTime + " - " + eventData.endTime
        }


        try {
            if (sloatId !== undefined) {
                const res = await EditEventFormData(eventData, sloatId, formData)
                if (res.status === 200) {
                    SweetAlertBox('success', 'Updated Successfully', `${formik.values.firstName} ${formik.values.lastName} has been updated successfully.`, () => {
                        setShow(false)
                        refreshData()
                        setCustomerData({})
                    })
                }
                else {
                    SweetAlertBox('error', 'Not Updated', `Sorry! ${formik.values.firstName} ${formik.values.lastName} has been not updated.`, () => {
                        setShow(false)
                        refreshData()
                        setCustomerData({})
                    })
                }
            }
            else {
                const res = await NewEventBookSlot(eventData._id, newBookSlotData)
                if (res.status === 200) {
                    SweetAlertBox('success', 'Event Booked', `${formik.values.firstName} ${formik.values.lastName} event booking was successfully.`, () => {
                        setShow(false)
                        refreshData()
                        setCustomerData({})
                    })
                }
                else {
                    SweetAlertBox('error', 'Not Event Book', `Sorry! ${formik.values.firstName} ${formik.values.lastName} event have been not booked.`, () => {
                        setShow(false)
                        refreshData()
                        setCustomerData({})
                    })
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: customerData,
        validationSchema: validationSchemaSloatForm,
        onSubmit
    })

    return (
        <div>
            {
                sloatId === undefined ?
                    <CustomButton buttonText={<BsPlusLg fontSize={"22px"} />} title="New Event Book Slot" onClick={() => setShow(true)} />
                    :
                    <button className='actionEdit' onClick={() => singleMemberData(eventData, sloatId)} title='edit'>
                        <FcEditImage size={20} />
                    </button>
            }
            <Modal show={show} size={"lg"} onHide={() => setShow(false)} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {
                            sloatId === undefined ? "New Event Sloat" : "Edit Event Sloat"
                        }
                    </Modal.Title>
                </Modal.Header>
                <Form noValidate onSubmit={formik.handleSubmit}>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName="Firstname"
                                    type="text"
                                    as="input"
                                    name="firstName"
                                    formik={formik}
                                />
                            </Col>
                            <Col>
                                <FormikInput
                                    fieldName="Lastname"
                                    type="text"
                                    as="input"
                                    name="lastName"
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName="Email Address"
                                    type="email"
                                    as="input"
                                    name="email"
                                    formik={formik}
                                />
                            </Col>
                            <Col>
                                <FormikInput
                                    fieldName="Mobile"
                                    type="number"
                                    as="input"
                                    name="mobile"
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName="Company"
                                    type="text"
                                    as="input"
                                    name="company"
                                    formik={formik}
                                />
                            </Col>
                            <Col>
                                <FormikInput
                                    fieldName="Designation"
                                    type="text"
                                    as="input"
                                    name="designation"
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName="LinkedIn"
                                    type="text"
                                    as="input"
                                    name="linkedIn"
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName="City"
                                    type="text"
                                    as="input"
                                    name="city"
                                    formik={formik}
                                />
                            </Col>
                            <Col>
                                <FormikSelect
                                    dataList={[
                                        {
                                            label: "Coming",
                                            value: "coming"
                                        },
                                        {
                                            label: "Not Coming",
                                            value: "notComing"
                                        },
                                        {
                                            label: "May Come",
                                            value: "mayCome"
                                        },
                                    ]}
                                    fieldName="Confirmation"
                                    name="confirmationStatus"
                                    formik={formik}
                                />
                            </Col>
                            {
                                sloatId !== undefined ?
                                    <Col>
                                        <FormikSelect
                                            dataList={[
                                                {
                                                    label: "Confirm",
                                                    value: true
                                                },
                                                {
                                                    label: "Not Confirm",
                                                    value: false
                                                }
                                            ]}
                                            fieldName="Email Confirmation"
                                            name="emailSendStatus"
                                            formik={formik}
                                        />
                                    </Col> : ""
                            }
                        </Row>
                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName="Remark Name"
                                    type="text"
                                    as="input"
                                    name="remark"
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row className='px-3'>
                            <Button variant="primary" type='submit'>Submit</Button>
                        </Row>
                    </Modal.Body>
                </Form>
            </Modal>
        </div>
    );
}

export default SloatForm