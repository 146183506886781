import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { Col, Container, Form, Row } from "react-bootstrap";
import { IMAGE_NOTFOUND } from "../../../assets/index";
import { SingleHost_Data } from "../../../services/getServices";
import { EditHost_Data, newHost_Data } from "../../../services/postServices";
import { ErrorToast } from "../../../components/alert/tostPop";

import MainTitle from "../../../components/mainTitle/mainTitle";
import CustomButton from "../../../components/Buttons/custom/CustomButton";
import FormikInput from "../../../components/filedbox/formikInput"
import CustomLoader from "../../../components/loader/loader";
import { SweetAlertBox } from "../../../components/alert/sweetAlertPop"
import FormikRadio from "../../../components/filedbox/formikRadio";
import FormikCheckBox from "../../../components/filedbox/formikCheckBox";
import BackButton from "../../../components/Buttons/custom/BackButton";
import { validationSchemaHostForm } from "../../../utils/validations";


const HostForm = () => {

    const initialValues = {
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        city: "",
        designation: "",
        company: "",
        intrestedToHost: "",
        durationOfEvent: "",
        facilitiesProvide: [],
        attendeesAccommodate: [],
        participatePeople: "",
        contact1: "",
        contact2: "",
        contact3: "",
        address: "",
        venueImage: ""
    };


    // state define
    const { hostId } = useParams();
    const navigate = useNavigate();
    const [venueImage, setVenueImage] = useState({});
    const [hostData, setHostData] = useState(initialValues);
    const [selectShowImage, setSelectShowImage] = useState("");
    const [imageError, setImageError] = useState("");
    const [loading, setLoading] = useState(false);


    // all functions

    // fetch single event data
    const singleEventData = async () => {
        setLoading(true);
        try {
            const res = await SingleHost_Data(hostId);
            setHostData(res.data.data);
            setLoading(false);
        } catch (e) {
            console.error(e);
        }
    };

    // user select banner image
    const onChangePicture = (e) => {
        const selectedFile = e?.target?.files[0];
        const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        const maxSizeKB = 1024;

        if (selectedFile !== undefined) {
            if (!allowedTypes.includes(selectedFile?.type)) {
                e.target.value = null;
                setImageError("Please Select Only JPG/PNG/JPEG Format");
            }
            else if ((selectedFile.size <= maxSizeKB * 1024) === false) {
                e.target.value = null;
                setImageError("Please choose Minimum 1MB image");
            }
            else {
                setImageError("");
                setSelectShowImage(selectedFile);
                setVenueImage({ filename: e.target.files[0] });
            }
        }
    };



    // submit form 
    const onSubmit = async () => {
        try {
            const formData = new FormData()
            formData.append("firstName", formik.values.firstName)
            formData.append("lastName", formik.values.lastName)
            formData.append("email", formik.values.email)
            formData.append("mobile", formik.values.mobile)
            formData.append("city", formik.values.city)
            formData.append("designation", formik.values.designation)
            formData.append("company", formik.values.company)
            formData.append("intrestedToHost", formik.values.intrestedToHost)
            formData.append("durationOfEvent", formik.values.durationOfEvent)
            formData.append("facilitiesProvide", formik.values.facilitiesProvide)
            formData.append("attendeesAccommodate", formik.values.attendeesAccommodate)
            formData.append("peopleCommitToParticipate", formik.values.peopleCommitToParticipate)
            formData.append("pointOfContact1", formik.values.pointOfContact1)
            formData.append("pointOfContact2", formik.values.pointOfContact2)
            formData.append("pointOfContact3", formik.values.pointOfContact3)
            formData.append("completeAddress", formik.values.completeAddress)

            if (hostId === undefined) {
                formData.append("venueImage", venueImage.filename)

                const res = await newHost_Data(formData)
                if (res.status === 200) {
                    SweetAlertBox("success", "Saved Host Data", `${formik.values.firstName + " " + formik.values.lastName} is added successfully`, () => {
                        navigate('/getInvolved/hosts/')
                    })
                }
                else {
                    SweetAlertBox("error", "Not Saved", `Sorry! ${formik.values.firstName + " " + formik.values.lastName} ha been not saved`, () => {
                        navigate('/getInvolved/hosts/')
                    })
                }
            }
            else {
                //Edit Update
                const venue_image = venueImage?.filename !== undefined ? venueImage?.filename : hostData?.venueImage
                formData.append("venueImage", venue_image)

                const res = await EditHost_Data(hostId, formData)
                if (res.status === 200) {
                    SweetAlertBox("success", "Update Host", `${formik.values.firstName + " " + formik.values.lastName} is update successfully`, () => {
                        navigate('/getInvolved/hosts/')
                    })
                }
                else {
                    ErrorToast("Sorry, This record is updated.")
                    navigate('/getInvolved/hosts/')
                }
            }
        }
        catch (e) {
            console.error(e);
        }
    };


    useEffect(() => {
        if (hostId !== undefined) {
            singleEventData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hostId])


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: hostData,
        validationSchema: validationSchemaHostForm,
        onSubmit,
    });

    const facilitiesArr = [
        {
            label: "Projector",
            value: "projector",
        },

        {
            label: "Stationery(Flip charts, Markers, White Board)",
            value: "stationery"
        },
        {
            label: "Internet",
            value: "internet"
        },
        {
            label: "Water",
            value: "water"
        },
        {
            label: "Tea/Coffee",
            value: "tea/coffee"
        },
        {
            label: "Snacks",
            value: "snacks"
        },
        {
            label: "Lunch",
            value: "lunch"
        },
        {
            label: "None",
            value: "none"
        },
    ]

    const accommodateArr = [
        {
            label: "10 - 30",
            value: "10-30"
        },
        {
            label: "30 - 50",
            value: "30-50"
        },
        {
            label: "50 - 70",
            value: "50-70"
        },
        {
            label: "80 - 100",
            value: "80-100"
        },
    ]


    return (
        <div>
            <CustomLoader show={loading} />

            <Container>

                <div className="d-flex flex-column flex-sm-row gap-3 justify-content-center justify-content-sm-between align-items-center w-100 pb-4 px-3">
                    <BackButton />
                    <MainTitle heading={hostId === undefined ? "New Host" : "Edit Host"} textAlign={"center"} />
                </div>

                <Container className="allDataTable p-4 pb-0 rounded">
                    <Form onSubmit={formik.handleSubmit} className="poolForm mb-5">
                        <Row>
                            <div className="w-100 mb-3">
                                <div className="event_banner">
                                    <img
                                        src={selectShowImage !== '' ? (URL.createObjectURL(selectShowImage)) : (formik.values.venueImage)}
                                        alt="Not found"
                                        onError={(e) => e.target.src = IMAGE_NOTFOUND}
                                    />
                                </div>
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        type="file"
                                        name="filename"
                                        accept='.png,.jpg,.jpeg'
                                        onChange={(e) => onChangePicture(e)}
                                    />
                                </Form.Group>
                                {
                                    imageError && imageError !== '' ? <span className='text-danger fw-semibold'>{imageError}</span> : ''
                                }
                            </div>
                        </Row>
                        <Row>
                            <Col sm={12} lg={6}>
                                <FormikInput
                                    fieldName="Firstname"
                                    type="text"
                                    as="input"
                                    name="firstName"
                                    placeholder="enter firstname"
                                    formik={formik}
                                />
                            </Col>
                            <Col sm={12} lg={6}>
                                <FormikInput
                                    fieldName="Lastname"
                                    type="text"
                                    as="input"
                                    name="lastName"
                                    placeholder="enter lastname"
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} lg={6}>
                                <FormikInput
                                    fieldName="Email Address"
                                    type="email"
                                    as="input"
                                    name="email"
                                    placeholder="enter email address"
                                    formik={formik}
                                />
                            </Col>
                            <Col sm={12} lg={6}>
                                <FormikInput
                                    fieldName="Mobile"
                                    type="number"
                                    as="input"
                                    name="mobile"
                                    placeholder="enter mobile"
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} lg={6}>
                                <FormikInput
                                    fieldName="Designation"
                                    type="text"
                                    as="input"
                                    name="designation"
                                    placeholder="enter designation"
                                    formik={formik}
                                />
                            </Col>
                            <Col sm={12} lg={6}>
                                <FormikInput
                                    fieldName="Company"
                                    type="text"
                                    as="input"
                                    name="company"
                                    placeholder="enter company"
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} lg={6}>
                                <FormikInput
                                    fieldName="City"
                                    type="text"
                                    as="input"
                                    name="city"
                                    placeholder="enter city"
                                    formik={formik}
                                />
                            </Col>
                            <Col sm={12} lg={6}>
                                <FormikInput
                                    fieldName="Complete Address"
                                    type="text"
                                    as="textarea"
                                    name="completeAddress"
                                    placeholder="enter complete address"
                                    formik={formik}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={12} lg={6}>
                                <FormikRadio
                                    dataList={[
                                        {
                                            label: "Half Day (4-5 Hours)",
                                            value: "4-5hours"
                                        },
                                        {
                                            label: "Full Day (8-9 Hours)",
                                            value: "8-9hours"
                                        },
                                    ]}
                                    fieldName={'Duration of an Event?'}
                                    name={"durationOfEvent"}
                                    formik={formik}
                                />
                            </Col>
                            <Col sm={12} lg={6}>
                                <FormikInput
                                    fieldName={"Interested to Host on?"}
                                    type={"text"}
                                    as={"input"}
                                    name={"intrestedToHost"}
                                    placeholder="enter interested to host on"
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} lg={6}>
                                <FormikCheckBox
                                    dataList={facilitiesArr}
                                    fieldName={'What amenities/facilities you can provide?'}
                                    name={'facilitiesProvide'}
                                    formik={formik}
                                />

                            </Col>
                            <Col sm={12} lg={6}>
                                <FormikCheckBox
                                    dataList={accommodateArr}
                                    fieldName={'How many attendees can you accommodate?'}
                                    name={'attendeesAccommodate'}
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName={"Expected Company Participants"}
                                    type={"text"}
                                    name={"peopleCommitToParticipate"}
                                    as="input"
                                    placeholder="enter people commit to participate"
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} lg={4}>
                                <FormikInput
                                    fieldName="Point of Contact - 1"
                                    type="number"
                                    as="input"
                                    name="pointOfContact1"
                                    placeholder="enter point of contact 1"
                                    formik={formik}
                                />
                            </Col>
                            <Col sm={12} lg={4}>
                                <FormikInput
                                    fieldName="Point of Contact - 2"
                                    type="number"
                                    as="input"
                                    name="pointOfContact2"
                                    placeholder="enter point of contact 2"
                                    formik={formik}
                                />
                            </Col>
                            <Col sm={12} lg={4}>
                                <FormikInput
                                    fieldName="Point of Contact - 3"
                                    type="number"
                                    as="input"
                                    name="pointOfContact3"
                                    placeholder="enter point of contact 3"
                                    formik={formik}
                                />
                            </Col>
                        </Row>

                        <div className="d-flex justify-content-around align-items-center py-3">
                            <CustomButton buttonText={"Submit"} type={"submit"} />
                        </div>
                    </Form>
                </Container>
            </Container>
        </div>
    );
}

export default HostForm
