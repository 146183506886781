import { projectAPIUrl } from "../utils/globalVariable";

export const BASE_URL = projectAPIUrl;


// Authentication API
export const LOGIN_API = BASE_URL + "/admin_login";

export const OTP_API = BASE_URL + "/admin_login_verify";

export const LOGIN_REGISTRATION_PASSWORD_API = BASE_URL + "/admin-register";
export const LOGIN_PASSWORD_API = BASE_URL + "/admin-login";
export const LOGIN_PASSWORD_RESET_API = BASE_URL + "/reset-password-admin";


// Admin API
export const ADMINS_API = BASE_URL + "/admin/";
export const DELETE_ADMIN_API = BASE_URL + "/delete_admin/";


// DASHBOARD API
export const DASHBOARD_API = BASE_URL + "/dashbord"

// MEMBER API
export const MEMBERS_API = BASE_URL + "/member/";
export const ALLMEMBERS_API = BASE_URL + "/member/";
export const NEW_MEMBERS_API = BASE_URL + "/register/";
export const DELETE_MEMBER_API = BASE_URL + "/delete_member/";

// Membership
export const MEMBERSHIP_API = BASE_URL + "/member-request/"
export const MEMBERSHIP_APPROVED_API = BASE_URL + "/member-approve/"

// Invitation
export const INVITATION_MAIL_API = BASE_URL + "/invite/"


// EVENT API
export const EVENTS_API = BASE_URL + "/event/";
export const ALL_EVENTS_API = BASE_URL + "/all-events/";
export const EVENTS_DELETE_API = BASE_URL + "/delete_event/";
export const COMPLETE_EVENT_API = BASE_URL + "/completed-events";
export const EVENTBOOKSLOT_API = BASE_URL + "/form/"


//Teams API
export const TEAM_API = BASE_URL + "/about/"


// GET INVOLVED API
export const FEEDBACK_API = BASE_URL + "/feedback/"
export const VOLUNTEER_API = BASE_URL + "/volunteer/"
export const SPEAKER_API = BASE_URL + "/speaker/"
export const HOST_API = BASE_URL + "/host/"


// LOCATION API
export const LOCATION_API = BASE_URL + "/location/"


// CONTACT US API
export const CONTACTUS_API = BASE_URL + "/contact-us/"


// CLIENT FEEDBACK API
export const CLIENT_FEEDBACK_API = BASE_URL + "/communityfeedback/"

// CLIENT FEEDBACK API
export const PROSPECTS_API = BASE_URL + "/prospacts/"


// CUSTOM EMAIL API
export const CUSTOM_SEND_MAIL = BASE_URL + "/send-mail/"


// CHAPTERS API
export const CHAPTERS_API = BASE_URL + "/chapters/"
export const CHAPTER_API = BASE_URL + "/chapter/"