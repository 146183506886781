import React, { useState } from "react";
import CustomButton from "../../components/Buttons/custom/CustomButton";
import { Form, Modal, Row, Col } from "react-bootstrap";
import { ErrorToast } from "../../components/alert/tostPop";
import FormikInput from "../../components/filedbox/formikInput";
import { useFormik } from "formik";
import { EditAdminPassword_Data, EditAdmin_Data } from "../../services/postServices";
import { SweetAlertBox } from "../../components/alert/sweetAlertPop";
import FormikSelect from "../../components/filedbox/formikSelect";
import { FcEditImage } from "react-icons/fc";
import { validationSchemaAdminOtp, validationSchemaAdminPassword } from "../../utils/validations";

const EditAdmin = ({ submitForm, email, role, setData = 'adminOtp' }) => {

    const initialValues = {
        email: "",
        role: "",
        password: ""
    };

    const [show, setShow] = useState(false);
    const [adminData, setAdminData] = useState(initialValues);

    const handleClose = () => {
        setShow(false)
        setAdminData({})
    }

    const handleShow = () => {
        setShow(true);
        setAdminData({
            email: email,
            role: role,
        })
    }

    const onSubmit = async () => {
        try {
            console.log(setData)
            if (setData === 'adminOtp') {
                // Update OTP API Calling
                const res = await EditAdmin_Data({ email: formik.values.email, role: formik.values.role });
                if (res.status === 200) {
                    SweetAlertBox("success", "Update Admin Member", `${formik.values.email} is mail address update successfully`, () => {
                        handleClose();
                        submitForm();
                    })
                }
                else {
                    ErrorToast("Not Updated");
                    handleClose();
                    submitForm();
                }
            }
            else if (setData === 'adminPassword') {
                // Update Password API Call
                const res = await EditAdminPassword_Data({ email: formik.values.email, newPassword: formik.values.password, role: formik.values.role, });
                if (res.status === 200) {
                    SweetAlertBox("success", "Update Admin Member", `${formik.values.email} is update successfully.`, () => {
                        handleClose();
                        submitForm();
                    })
                }
                else {
                    SweetAlertBox("error", "Not Updated!", `Sorry! ${formik.values.email} is not updated.`, () => {
                        handleClose();
                        submitForm();
                    })
                }
            }
            else {
                handleClose()
            }
        } catch (e) {
            console.error(e);
        }
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: adminData,
        validationSchema: setData && setData === 'adminOtp' ? validationSchemaAdminOtp : validationSchemaAdminPassword,
        onSubmit,
    });    

    return (
        <div>
            <button className="actionEdit" onClick={handleShow} title="edit">
                <FcEditImage size={20} />
            </button>

            {
                show && show === true ?
                    <Modal
                        show={show}
                        size={"md"}
                        onHide={handleClose}
                        scrollable={true}
                        backdrop="static"
                        keyboard={false}
                        className="vh-100"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Update Admin Member</Modal.Title>
                        </Modal.Header>
                        <Form noValidate onSubmit={formik.handleSubmit}>
                            <Modal.Body>
                                <Row>
                                    {
                                        setData && setData !== 'adminPassword' ?
                                            <Col className="col-12">
                                                <FormikInput
                                                    fieldName="Email Address"
                                                    type="email"
                                                    as="input"
                                                    name="email"
                                                    placeholder="enter email address"
                                                    formik={formik}
                                                />
                                            </Col> : ""
                                    }
                                    {
                                        setData && setData === 'adminPassword' ?
                                            <Col className="col-12">
                                                <FormikInput
                                                    fieldName="Password"
                                                    type="password"
                                                    as="input"
                                                    name="password"
                                                    placeholder="enter password"
                                                    formik={formik}
                                                />
                                            </Col> : ""
                                    }
                                    <Col className="col-12">
                                        <FormikSelect
                                            dataList={[
                                                {
                                                    label: "Select Role",
                                                    value: "",
                                                },
                                                {
                                                    label: "Super Admin",
                                                    value: "super-admin",
                                                },
                                                {
                                                    label: "Admin",
                                                    value: "admin",
                                                },
                                                {
                                                    label: "User",
                                                    value: "user",
                                                },
                                            ]}
                                            fieldName={"Role"}
                                            name={"role"}
                                            formik={formik}
                                        />
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Row className="px-3">
                                    <CustomButton
                                        type={"submit"}
                                        buttonText={"Update Admin"}
                                    />
                                </Row>
                            </Modal.Footer>
                        </Form>
                    </Modal>
                    : ""
            }
        </div>
    );
};

export default EditAdmin;
