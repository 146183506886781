import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import MainTitle from "../../components/mainTitle/mainTitle";
import "./dashboard.css";
import {
    FcBusinessman,
    FcButtingIn,
    FcCollaboration,
    FcConferenceCall,
    FcCustomerSupport,
    FcPlanner,
    FcGlobe,
    FcPodiumWithSpeaker,
} from "react-icons/fc";
import { Link } from "react-router-dom";
import { Dashboard_Data } from "../../services/getServices";

const Dashboard = () => {

    const [dashboardData, setDashboardData] = useState([])

    const fetchDashboardData = async () => {
        try {
            const res = await Dashboard_Data()
            setDashboardData(res.data.data)
        }
        catch(e){
            console.error(e)
        }
    }

    useEffect(() => {
        fetchDashboardData()
    }, [])

    return (
        <Container className="dashboard">
            <div className="my-4">
                <MainTitle
                    heading={"Welcome to the dashboard"}
                    textAlign={"center"}
                />
            </div>
            <div className="py-3">
                <Row sm={1} md={2} lg={4}>
                    <Col>
                        <Link to={"/members"} className="card">
                            <FcConferenceCall className="countIcon" />
                            <span className="countName">Members</span>
                            <div className="countValue">{dashboardData.TotalMember}</div>
                        </Link>
                    </Col>
                    <Col>
                        <Link to={"/chapter"} className="card">
                            <FcGlobe className="countIcon" />
                            <span className="countName">Chapters</span>
                            <div className="countValue">{dashboardData.TotalChapter}</div>
                        </Link>
                    </Col>
                    <Col>
                        <Link to={"/events"} className="card">
                            <FcPlanner className="countIcon" />
                            <span className="countName">Events</span>
                            <div className="countValue">{dashboardData.TotalEvent}</div>
                        </Link>
                    </Col>
                    <Col>
                        <Link to={"/admin"} className="card">
                            <FcPodiumWithSpeaker className="countIcon" />
                            <span className="countName">Admin</span>
                            <div className="countValue">{dashboardData.TotalAdmin}</div>
                        </Link>
                    </Col>
                    <Col>
                        <Link to={"/getInvolved/host"} className="card">
                            <FcBusinessman className="countIcon" />
                            <span className="countName">Host</span>
                            <div className="countValue">{dashboardData.TotalHost}</div>
                        </Link>
                    </Col>
                    <Col>
                        <Link to={"/getInvolved/speakers"} className="card">
                            <FcButtingIn className="countIcon" />
                            <span className="countName">Speaker</span>
                            <div className="countValue">{dashboardData.TotalSpeaker}</div>
                        </Link>
                    </Col>
                    <Col>
                        <Link to={"/getInvolved/volunteer"} className="card">
                            <FcCustomerSupport className="countIcon" />
                            <span className="countName">Volunteer</span>
                            <div className="countValue">{dashboardData.TotalVolunteer}</div>
                        </Link>
                    </Col>
                    <Col>
                        <Link to={"/getInvolved/feedback"} className="card">
                            <FcCollaboration className="countIcon" />
                            <span className="countName">Feedback</span>
                            <div className="countValue">{dashboardData.TotalFeedback}</div>
                        </Link>
                    </Col>
                </Row>
            </div>
        </Container>
    );
};

export default Dashboard;
