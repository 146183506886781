import React, { useState, useEffect } from 'react'
import { Col, Form, Image, Row } from 'react-bootstrap'
import '../login/login.css'
import MainTitle from '../../components/mainTitle/mainTitle'
import CustomButton from '../../components/Buttons/custom/CustomButton'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setAuthentication } from '../../redux/authSlice'
import { loginUser, otpUser } from '../../services/postServices';

import { IMAGE_HRC_LOGO, IMAGE_ISC_LOGO } from '../../assets';
import OTPInput from 'react-otp-input';
import { SweetAlertBox } from "../../components/alert/sweetAlertPop";
import Cookies from 'js-cookie'
import { useFormik } from "formik";
import * as yup from 'yup';
import { convertEncrypted } from '../../utils/globalFunctions'
import { checkISC, projectName } from '../../utils/globalVariable'


const Otp = () => {

    const initialValues = {
        otp: ""
    }

    const [otpData, setOtpData] = useState(initialValues);
    const [GetEmail, setGetEmail] = useState(null)
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const validationSchema = yup.object({
        otp: yup
            .string()
            .required('Please enter your OTP')
            .matches(/^\d{4}$/, 'Invalid OTP Number'),
    });

    const onSubmit = async () => {
        const params = { email: GetEmail, OTP: formik.values.otp };
        const res = await otpUser(params)
        if (res.status === 200) {
            SweetAlertBox('success', 'Welcome Back', 'user has been login successfully', () => {
                Cookies.remove('em')
                const storeCookies = {
                    refereshToken: res.data.data.refresh,
                    accessToken: res.data.data.access,
                    userData: {
                        email: res.data.data.userDetail.email,
                        role: res.data.data.userDetail.role
                    }
                }
                const encrypted = convertEncrypted(storeCookies)
                Cookies.set('accessControl', encrypted, { expires: 1 })
                dispatch(setAuthentication(storeCookies));
                navigate('/');
            })
        }
        else {
            SweetAlertBox("error", "Invalid OTP Number", "Sorry!, Currect the OTP number.", () => setOtpData({ otp: "" }))
        }
    }

    const handleReSendOTP = async () => {
        try {
            const res = await loginUser({ email: GetEmail })
            if (res.status === 200) {
                SweetAlertBox('success', 'Send OTP Mail', 'Check Your email box', () => {
                    navigate('/otp')
                })
            }
            else {
                SweetAlertBox('error', 'Oops', `Sorry! ${GetEmail} is invalied. please check again.`, () => { })
            }
        }
        catch (err) {
            console.error(err)
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: otpData,
        validationSchema,
        onSubmit,
    });

    useEffect(() => {
        const getLocalEmail = Cookies.get("em")

        if (getLocalEmail !== undefined) {
            setGetEmail(getLocalEmail)
        }
        else {
            navigate('/')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <div className='loginContainer'>
            <div className='loginForm'>
                <div className="logoBox">
                    {
                        projectName && projectName === checkISC ?
                            <Image
                                src={IMAGE_ISC_LOGO}
                                className="h-100 w-100 py-3"
                                alt="logo not found"
                            />
                            :
                            <Image
                                src={IMAGE_HRC_LOGO}
                                className="h-100 w-100 py-3"
                                alt="logo not found"
                            />
                    }
                </div>

                <hr className="text-secondary" />

                <MainTitle heading={"OTP Verification"} textAlign={"center"} />
                <Row>
                    <Col>
                        <Form onSubmit={formik.handleSubmit}>
                            <div className='w-100 py-3'>
                                <div>
                                    <OTPInput
                                        value={formik.values.otp}
                                        numInputs={4}
                                        shouldAutoFocus={true}
                                        onChange={(otp) => {
                                            formik.setFieldValue('otp', otp);
                                        }}
                                        inputType='number'
                                        renderSeparator={<span className='px-2'></span>}
                                        renderInput={(inputProps, index) => (
                                            <input
                                                {...inputProps}
                                                className='w-25 form-control fw-bold'
                                                name={`otp-${index}`}
                                            />
                                        )}
                                    />
                                </div>
                                <div className='pt-3 text-end'>
                                    <span className='fw-bold text-danger cursor-pointer' onClick={handleReSendOTP}>Resend OTP</span>
                                </div>
                                <div>
                                    {
                                        formik.touched.otp && formik.errors.otp ? (
                                            <p className="text-danger fw-bold mt-1">{formik.errors.otp}</p>
                                        ) : null
                                    }
                                </div>
                            </div>
                            <div className="text-center py-3">
                                <CustomButton buttonText={"submit"} type={"submit"} />
                            </div>
                        </Form>
                    </Col>
                </Row>
            </div>

        </div>
    )
}

export default Otp