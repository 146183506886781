import { Puff } from 'react-loader-spinner'
import './loader.css'


const CustomLoader = ({ show }) => (
    show ?
        <div className={'loader'}>
            <div>
                <Puff
                    height="100"
                    width="100"
                    radius={1}
                    color="#1c2444"
                    ariaLabel="puff-loading"
                    visible={true}
                />
                <h4 style={{ color: "#1c2444" }}>Loading....</h4>
            </div>
        </div>
        : ""
);


export default CustomLoader