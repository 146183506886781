import axios from "axios";
import { BASE_URL } from '../constant/api';

axios.defaults.baseURL = BASE_URL;

const getHeaderInfo = async function () {

    return {
        headers: {
            "Content-Type": "application/json",
            // "Access-Control-Allow-Origin": "*",
        },
    };
};

const handleResponse = (response) => {
    return response
}

export const post = async function (url, body) {
    try {
        let response = await axios.post(url, body);
        return handleResponse(response);
    } catch (err) {
        return handleResponse(err.response)
    }
};

export const get = async function (url, params = {}) {
    let header = await getHeaderInfo();
    try {
        let resp = await axios.get(url, { ...header, params });
        return handleResponse(resp);
    } catch (err) {
        throw handleResponse(err.response)
    }
};

export const patch = async function (url, params) {
    try {
        let resp = await axios.patch(url, params);

        return handleResponse(resp);
    } catch (err) {
        throw handleResponse(err.response);
    }
};

export const all = async function (url, params = {}) {
    let header = await getHeaderInfo();
    try {
        let resp = await axios.all(url, { ...header, params });
        return handleResponse(resp);
    } catch (err) {
        throw handleResponse(err.response)
    }
};

export const deleteApi = async function (url) {
    let header = await getHeaderInfo();

    try {
        let resp = await axios.delete(url, { header });

        return handleResponse(resp);
    } catch (err) {
        throw handleResponse(err.response)
    }
};