import React, { useState } from 'react'
import { AllSpeaker_Data } from '../../services/getServices';
import CustomBtn from '../../components/Buttons/CustomButton/CustomBtn';
import { Button, Col, Form, Image, Modal, Row } from 'react-bootstrap';
import { FcButtingIn } from 'react-icons/fc';
import { IoMdClose } from 'react-icons/io';
import { SortAscendingFuncation } from '../../utils/globalFunctions';
import { IMAGE_DEFAULT_AVATER } from '../../assets';
import { FaLinkedin } from 'react-icons/fa';
import { SweetAlertBox } from '../../components/alert/sweetAlertPop';
import { NewChapterSpeaker_Data } from '../../services/postServices';

const ChapterSpeaker = ({ participateSpeaker, chapterId = "", refreshData }) => {

    const [show, setShow] = useState(false);
    const [allSpeakerList, setAllSpeakerList] = useState([]);
    const [bookSpeakerList, setBookSpeakerList] = useState([]);
    const [filterText, setFilterText] = useState("");

    // clear filter textbox function
    const handleClose = () => {
        setShow(false);
        setFilterText("");
    }

    // check already participate or not using member id
    const isParticipated = (speakerId) => {
        return participateSpeaker.some(participateData => participateData._id === speakerId)
    }

    // fetch all member list
    const fetchAllSpeakers = async () => {
        try {
            const res = await AllSpeaker_Data();
            const allSpeakers = res.data.data;
            const combineSpeakerData = [...(allSpeakers.approvedSpeakers || []), ...(allSpeakers.notApprovedSpeakers || [])]
            const notParticipateSpeaker = combineSpeakerData.filter(speakerData => !isParticipated(speakerData._id))
            const sortedNotParticipateSpeakers = SortAscendingFuncation(notParticipateSpeaker)
            console.log(sortedNotParticipateSpeakers);
            setAllSpeakerList(sortedNotParticipateSpeakers);
        }
        catch (err) {
            console.error(err)
        }
    };

    // speaker search function
    const searcSpeaker = allSpeakerList?.filter((item) => {
        const startFirstName = (item.firstName + "" + item.lastName).toLowerCase();
        const startLastName = (item.lastName + "" + item.firstName).toLowerCase();
        const searchText = filterText.toLowerCase();
        return (
            startFirstName.includes(searchText) ||
            startFirstName.replace(/\s/g, "").includes(searchText) ||
            startLastName.includes(searchText) ||
            startLastName.replace(/\s/g, "").includes(searchText)
        );
    });

    // checkbox check function
    const handleCheckBox = (userId) => {
        if (bookSpeakerList.includes(userId)) {
            setBookSpeakerList(bookSpeakerList.filter((id) => id !== userId));
        } else {
            setBookSpeakerList([...bookSpeakerList, userId]);
        }
    };

    // speaker model box open funcation
    const handleShow = () => {
        if (chapterId !== "") {
            setShow(true)
            fetchAllSpeakers()
        }
    }

    const bookSpeakers = async () => {
        try {
            if (bookSpeakerList.length !== 0) {
                const chapterSpeakers = {
                    speakerIds: [],
                };

                bookSpeakerList.forEach((ele, index) => {
                    chapterSpeakers.speakerIds.push(ele?._id)
                })

                const res = await NewChapterSpeaker_Data(chapterId, chapterSpeakers);
                if (res.status === 200) {
                    SweetAlertBox("success", "Add Chapter Speakers", "This speakers Save Successfully.", () => {
                        setBookSpeakerList()
                        refreshData()
                    })
                } else {
                    SweetAlertBox("error", "Not Add Chapter Speakers", "Sorry! This Speaker Is Not Saved.", () => {
                        setBookSpeakerList()
                        refreshData();
                    })
                }
            } else {
                SweetAlertBox("error", "Black Member List", "Please, check the member checkbox", () => {
                    setBookSpeakerList()
                    refreshData();
                })
            }
            handleClose();
        }
        catch (err) {
            console.error(err)
        }
    }


    return (
        <div>
            <CustomBtn
                btnIcon={<FcButtingIn size={20} />}
                btnText=""
                className="actionEdit"
                onClick={handleShow}
                hoverTitle={"Speaker"}
            />

            <Modal
                show={show}
                size={"md"}
                onHide={handleClose}
                scrollable={true}
                backdrop="static"
                keyboard={false}
                className="vh-100 newParticipate"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Speaker List</Modal.Title>
                </Modal.Header>
                <Row>
                    <Col>
                        <div className="px-4 py-3 shadow-sm">
                            <Form.Group className="position-relative">
                                <Form.Control
                                    type="text"
                                    value={filterText}
                                    className="pe-5"
                                    onChange={(e) => setFilterText(e.target.value)}
                                    placeholder="Search member name"
                                />
                                {filterText && filterText.length !== 0 ? <IoMdClose className="searchClear" onClick={() => setFilterText("")} /> : null}
                            </Form.Group>
                        </div>
                    </Col>
                </Row>
                <Modal.Body>
                    <Row>
                        {
                            searcSpeaker && searcSpeaker.length !== 0 ? (
                                <div>
                                    {searcSpeaker.map((data, index) => (
                                        <div
                                            className="participateBox my-3"
                                            key={index}
                                        >
                                            {/* <div className="imageBox">
                                                <Image
                                                    src={data.profile}
                                                    alt="not found"
                                                    className="image"
                                                    onError={e => e.target.src = IMAGE_DEFAULT_AVATER}
                                                />
                                            </div> */}
                                            <div className="participateInfo">
                                                <div>
                                                    <div className="w-100">
                                                        <h5>{`${data.firstName} ${data.lastName} - ${data.designation}`}</h5>
                                                        <div className='d-flex gap-2 align-items-center'>
                                                            <h6 className='m-0 fs-5'>{data.companyName}</h6>
                                                            <FaLinkedin fontSize={30} onClick={data.linkedIn} className='cursor-pointer' />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <Form.Check
                                                        type="checkbox"
                                                        value={data._id}
                                                        checked={bookSpeakerList?.includes(data)}
                                                        className="memberCheckBox"
                                                        onChange={() => handleCheckBox(data)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <h3 className="text-center text-secondary">
                                    Not Found Speaker
                                </h3>
                            )}
                    </Row>
                </Modal.Body>
                {
                    bookSpeakerList && bookSpeakerList.length !== 0 ? (
                        <Modal.Footer>
                            <div className="w-100 d-flex justify-content-between align-items-center">
                                <div>
                                    <h5>Total: {bookSpeakerList.length}</h5>
                                </div>
                                <div>
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        onClick={bookSpeakers}
                                    >
                                        Save Speaker
                                    </Button>
                                </div>
                            </div>
                        </Modal.Footer>
                    ) : null}
            </Modal>
        </div>
    );
}

export default ChapterSpeaker