import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { Col, Container, Form, Row } from "react-bootstrap";
import { IMAGE_NOTFOUND } from "../../../assets/index";
import { GetLocation_Data, SingleEvent_Data } from "../../../services/getServices";
import { NewEvent_Data, EditEvent_Data } from "../../../services/postServices";
import { ErrorToast } from "../../../components/alert/tostPop";

import "./EventForm.css"
import MainTitle from "../../../components/mainTitle/mainTitle";
import CustomButton from "../../../components/Buttons/custom/CustomButton";
import FormikInput from "../../../components/filedbox/formikInput"
import CustomLoader from "../../../components/loader/loader";
import { SweetAlertBox } from "../../../components/alert/sweetAlertPop"
import BackButton from "../../../components/Buttons/custom/BackButton";
import FormikSelect from "../../../components/filedbox/formikSelect";
import { useLocation } from "react-router-dom"
import { FaMapMarkerAlt } from "react-icons/fa";
import { validationSchemaEventForm } from "../../../utils/validations";
import { customChooseImage } from "../../../utils/globalFunctions";


const EventForm = () => {

    const initialValues = {
        eventTitle: "",
        date: "",
        startTime: "",
        endTime: "",
        venue: "",
        location: "",
        venueMapLink: "",
        slots: "",
        entryFees: "",
        forInvite: "",
        keynoteSpeakers: "",
        moderator: "",
        objective: "",
        organizedBy: "",
        poster: "",
        keyHighlights: "",

        whyAttendTitle1: "",
        whyAttendDescription1: "",
        whyAttendTitle2: "",
        whyAttendDescription2: "",
        whyAttendTitle3: "",
        whyAttendDescription3: "",
        whyAttendTitle4: "",
        whyAttendDescription4: "",
        whyAttendTitle5: "",
        whyAttendDescription5: "",
    };



    // state define
    const { eventId } = useParams();
    const navigate = useNavigate();
    const [eventData, setEventData] = useState(initialValues);
    const [locationList, setLocationList] = useState();
    const [selectImage, setSelectImage] = useState(null);
    const [imageError, setImageError] = useState("");
    const [loading, setLoading] = useState(false);
    const inputImageRef = useRef(null)
    const currentPath = useLocation()
    const checkUrls = currentPath.pathname.split('/')


    // all functions

    // get all chapter
    const getEventChapter = async () => {
        try {
            const res = await GetLocation_Data();
            setLocationList(res.data.data)
        }
        catch (err) {
            console.error(err)
        }
    }

    // fetch single event data
    const singleEventData = async () => {
        setLoading(true);
        try {
            const res = await SingleEvent_Data(eventId);

            const setEvent = { ...res.data.data };
            // Loop over whyAttend array and assign titles and descriptions dynamically
            for (let i = 0; i < 5 && i < res.data.data?.whyAttend?.length; i++) {
                setEvent[`whyAttendTitle${i + 1}`] = res.data.data.whyAttend[i].title;
                setEvent[`whyAttendDescription${i + 1}`] = res.data.data.whyAttend[i].description;
            }

            setEventData(setEvent);
            setLoading(false);
        } catch (e) {
            console.error(e);
        }
    };


    // convert to time formate
    const convertTime = (dbTime) => {
        const timeFormatRegex = /^(1[0-2]|0?[1-9]):([0-5][0-9]) [APap][Mm]$/;
        if (timeFormatRegex.test(dbTime)) {
            return dbTime
        }
        else {
            const [hour, minutes] = dbTime?.split(':');
            let period = 'AM';
            if (parseInt(hour) >= 12) {
                period = 'PM';
            }
            const hour12 = parseInt(hour) % 12 || 12;
            return `${hour12}:${minutes} ${period}`
        }
    }


    // convert to date formate
    const convertDate = (dbDate) => {
        const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
        if (dateFormatRegex.test(dbDate)) {
            const [year, month, date] = dbDate?.split("-")
            return `${date}-${month}-${year}`
        }
        else {
            const dateObject = new Date(dbDate);
            const day = String(dateObject.getDate()).padStart(2, '0');
            const month = String(dateObject.getMonth() + 1).padStart(2, '0');
            const year = dateObject.getFullYear();
            return `${day}-${month}-${year}`;
        }
    }


    // submit form 
    const onSubmit = async () => {
        if (checkUrls.includes('edit') || checkUrls.includes('create')) {

            const formData = new FormData();

            formData.append("poster", selectImage)
            formData.append("eventTitle", formik.values.eventTitle)
            formData.append("date", convertDate(formik.values.date))
            formData.append("startTime", convertTime(formik.values.startTime))
            formData.append("endTime", convertTime(formik.values.endTime))
            formData.append("venue", formik.values.venue)
            formData.append("location", formik.values.location)
            formData.append("venueMapLink", formik.values.venueMapLink)
            formData.append("slots", formik.values.slots)
            formData.append("entryFees", formik.values.entryFees)
            formData.append("forInvite", formik.values.forInvite)
            formData.append("keynoteSpeakers", formik.values.keynoteSpeakers)
            formData.append("moderator", formik.values.moderator)
            formData.append("objective", formik.values.objective)
            formData.append("organizedBy", formik.values.organizedBy)

            if (eventId === undefined) {
                if (selectImage !== null) {
                    try {
                        // new events data
                        const res = await NewEvent_Data(formData)
                        if (res.status === 201) {
                            SweetAlertBox("success", "Create Event", `${formik.values.eventTitle} event is create successfully`, () => {
                                navigate('/events')
                            })
                        }
                        else {
                            SweetAlertBox("error", "Not Saved", `Sorry!, ${formik.values.eventTitle} event has been  create successfully`, () => {
                                navigate('/events')
                            })
                            navigate('/events')
                        }
                    }
                    catch (e) {
                        console.error(e);
                    }
                }
                else {
                    setImageError("Please choose event poster")
                }
            }
            else {
                try {
                    formData.append("keyHighlights", formik.values.keyHighlights)

                    for (let index = 0; index < 5; index++) {
                        formData.append(`whyAttend[${index}][title]`, formik.values[`whyAttendTitle${index + 1}`]);
                        formData.append(`whyAttend[${index}][description]`, formik.values[`whyAttendDescription${index + 1}`]);
                    }

                    // Update events data
                    const res = await EditEvent_Data(eventId, formData);
                    if (res.status === 200) {
                        SweetAlertBox("success", "Update Event", `${formik.values.eventTitle} event is update successfully`, () => {
                            navigate('/events')
                        })
                    }
                    else {
                        ErrorToast("Sorry! Member Is not updated.")
                        navigate('/events')
                    }
                }
                catch (e) {
                    console.error(e);
                }
            }
        }
    };


    useEffect(() => {
        getEventChapter()
        if (eventId !== undefined) {
            singleEventData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventId])


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: eventData,
        validationSchema: validationSchemaEventForm,
        onSubmit,
    });


    return (
        <div>
            <CustomLoader show={loading} />

            <Container>
                <Row className='py-4 '>
                    <Col sm={2}>
                        <BackButton />
                    </Col>
                    <Col sm={8}>
                        <MainTitle
                            heading={checkUrls.includes('edit') || checkUrls.includes('create') ? eventId && eventId !== undefined ? "Edit Event" : "New Event" : "View Event"}
                            textAlign={"center"}
                        />
                    </Col>
                    <Col sm={2}>
                        {
                            checkUrls.includes('view') ?
                                <CustomButton type="button" buttonText="edit" onClick={() => navigate(`/event/${eventId}/edit/`)} /> : ""
                        }
                    </Col>
                </Row>

                <Form onSubmit={formik.handleSubmit} className="poolForm mb-5">
                    <Container>
                        <div className="allDataTable p-4 my-4 rounded">
                            <Row>
                                <Col>
                                    <div className="w-100">
                                        <div className="event_banner">
                                            <img
                                                src={selectImage ? (URL.createObjectURL(selectImage)) : (formik.values.poster)}
                                                alt="Not found"
                                                onClick={() => checkUrls.includes('edit') || checkUrls.includes('create') ? inputImageRef.current.click() : ""}
                                                onError={(e) => { e.target.src = IMAGE_NOTFOUND }}
                                            />
                                        </div>
                                        {
                                            checkUrls.includes('edit') || checkUrls.includes('create') ?
                                                <>
                                                    <Form.Group className="mb-3">
                                                        <Form.Control
                                                            type="file"
                                                            name="filename"
                                                            className="d-none"
                                                            accept='.png,.jpg,.jpeg'
                                                            ref={inputImageRef}
                                                            onChange={(e) => {
                                                                const res = customChooseImage(e)
                                                                setImageError(res.error)
                                                                setSelectImage(res.file)
                                                            }}
                                                        />
                                                    </Form.Group>
                                                    {imageError && imageError !== '' ? <span className='text-danger fw-semibold'>{imageError}</span> : ''}
                                                </>
                                                : ""
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormikInput
                                        fieldName="event Title"
                                        type="text"
                                        as="input"
                                        name="eventTitle"
                                        disabled={checkUrls.includes('edit') || checkUrls.includes('create') ? false : true}
                                        placeholder="enter event title"
                                        formik={formik}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormikInput
                                        fieldName="event date"
                                        type={checkUrls.includes('edit') || checkUrls.includes('create') ? "date" : "text"}
                                        as="input"
                                        name="date"
                                        disabled={checkUrls.includes('edit') || checkUrls.includes('create') ? false : true}
                                        placeholder="dd-mm-yyyy"
                                        formik={formik}
                                        extra={formik.values.date}
                                    />
                                </Col>
                                <Col>
                                    <FormikInput
                                        fieldName="start time"
                                        type={checkUrls.includes('edit') || checkUrls.includes('create') ? "time" : "text"}
                                        as="input"
                                        name="startTime"
                                        disabled={checkUrls.includes('edit') || checkUrls.includes('create') ? false : true}
                                        placeholder="enter event start time"
                                        formik={formik}
                                        extra={formik.values.startTime}
                                    />
                                </Col>
                                <Col>
                                    <FormikInput
                                        fieldName="end time"
                                        type={checkUrls.includes('edit') || checkUrls.includes('create') ? "time" : "text"}
                                        as="input"
                                        name="endTime"
                                        disabled={checkUrls.includes('edit') || checkUrls.includes('create') ? false : true}
                                        placeholder="enter event end time"
                                        formik={formik}
                                        extra={formik.values.endTime}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormikInput
                                        fieldName="venue"
                                        type="text"
                                        as="textarea"
                                        name="venue"
                                        disabled={checkUrls.includes('edit') || checkUrls.includes('create') ? false : true}
                                        placeholder="enter venue"
                                        formik={formik}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {
                                        locationList &&
                                        <FormikSelect
                                            dataList={
                                                locationList.map((item) => (
                                                    {
                                                        label: item.location,
                                                        value: item.location
                                                    }
                                                ))
                                            }
                                            fieldName={"Location"}
                                            name={"location"}
                                            disabled={checkUrls.includes('edit') || checkUrls.includes('create') ? false : true}
                                            formik={formik}
                                        />
                                    }
                                </Col>
                                <Col>
                                    <FormikInput
                                        fieldName="venue map link"
                                        type="text"
                                        as="input"
                                        name="venueMapLink"
                                        disabled={checkUrls.includes('edit') || checkUrls.includes('create') ? false : true}
                                        placeholder="enter venue map link"
                                        formik={formik}
                                        icon={
                                            <Link to={formik.values.venueMapLink} target="_blank">
                                                <FaMapMarkerAlt size={20} />
                                            </Link>
                                        }
                                    />
                                </Col>
                            </Row>
                        </div>

                        <div className="allDataTable p-4 my-4 rounded">
                            <Row>
                                <Col>
                                    <FormikInput
                                        fieldName="slots"
                                        type="number"
                                        as="input"
                                        name="slots"
                                        disabled={checkUrls.includes('edit') || checkUrls.includes('create') ? false : true}
                                        placeholder="enter event slots"
                                        formik={formik}
                                    />
                                </Col>
                                <Col>
                                    <FormikInput
                                        fieldName="entry fees"
                                        type="text"
                                        as="input"
                                        name="entryFees"
                                        disabled={checkUrls.includes('edit') || checkUrls.includes('create') ? false : true}
                                        placeholder="enter event fees"
                                        formik={formik}
                                    />
                                </Col>
                                <Col>
                                    <FormikInput
                                        fieldName="invite for"
                                        type="text"
                                        as="input"
                                        name="forInvite"
                                        disabled={checkUrls.includes('edit') || checkUrls.includes('create') ? false : true}
                                        placeholder="enter invite for"
                                        formik={formik}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormikInput
                                        fieldName="key note speakers"
                                        type="text"
                                        as="textarea"
                                        name="keynoteSpeakers"
                                        disabled={checkUrls.includes('edit') || checkUrls.includes('create') ? false : true}
                                        placeholder="enter key note speakers"
                                        formik={formik}
                                    />
                                </Col>
                                <Col>
                                    <FormikInput
                                        fieldName="moderator"
                                        type="text"
                                        as="textarea"
                                        name="moderator"
                                        disabled={checkUrls.includes('edit') || checkUrls.includes('create') ? false : true}
                                        placeholder="enter moderator"
                                        formik={formik}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormikInput
                                        fieldName="objective"
                                        type="text"
                                        as="textarea"
                                        name="objective"
                                        disabled={checkUrls.includes('edit') || checkUrls.includes('create') ? false : true}
                                        placeholder="enter objective"
                                        formik={formik}
                                    />
                                </Col>
                                <Col>
                                    <FormikInput
                                        fieldName="organized by"
                                        type="text"
                                        as="textarea"
                                        name="organizedBy"
                                        disabled={checkUrls.includes('edit') || checkUrls.includes('create') ? false : true}
                                        placeholder="enter organized by"
                                        formik={formik}
                                    />
                                </Col>
                            </Row>
                        </div>

                        {
                            (checkUrls.includes('edit') || checkUrls.includes('view')) && (
                                <div className="allDataTable p-4 my-4 rounded">
                                    <Row>
                                        <Col>
                                            <FormikInput
                                                fieldName="Key Highlights"
                                                type="text"
                                                as="textarea"
                                                name="keyHighlights"
                                                disabled={!checkUrls.includes('edit') && !checkUrls.includes('create')}
                                                placeholder="enter keyHighlights"
                                                formik={formik}
                                            />
                                        </Col>
                                    </Row>
                                    {[...Array(5)].map((_, index) => (
                                        <Row key={index}>
                                            <Col className="col-12 col-md-6">
                                                <FormikInput
                                                    fieldName={`Why Attend Title ${index + 1}`}
                                                    type="text"
                                                    as="input"
                                                    name={`whyAttendTitle${index + 1}`}
                                                    disabled={!checkUrls.includes('edit') && !checkUrls.includes('create')}
                                                    placeholder="enter attend title"
                                                    formik={formik}
                                                />
                                            </Col>
                                            <Col className="col-12 col-md-6">
                                                <FormikInput
                                                    fieldName={`Why Attend Description ${index + 1}`}
                                                    type="text"
                                                    as="textarea"
                                                    name={`whyAttendDescription${index + 1}`}
                                                    disabled={!checkUrls.includes('edit') && !checkUrls.includes('create')}
                                                    placeholder="enter attend description"
                                                    formik={formik}
                                                />
                                            </Col>
                                        </Row>
                                    ))}
                                </div>
                            )
                        }


                        {
                            checkUrls.includes('edit') || checkUrls.includes('create') ?
                                <div className="allDataTable  my-4 rounded">
                                    <div className="text-center py-3">
                                        <CustomButton buttonText={"Submit"} type={"submit"} />
                                    </div>
                                </div> : ""
                        }
                    </Container>
                </Form>
            </Container>
        </div>
    );
}

export default EventForm