import React, { useState, useRef } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import FormikInput from '../../components/filedbox/formikInput';
import { IMAGE_DEFAULT_AVATER } from '../../assets';
import FormikSelect from '../../components/filedbox/formikSelect';
import { useFormik } from 'formik';
import { SingleTeam_Data } from '../../services/getServices';
import { EditTeamMember_Data, NewTeamMember_Data } from '../../services/postServices';
import { SweetAlertBox } from '../../components/alert/sweetAlertPop';
import { ErrorToast } from '../../components/alert/tostPop';
import CustomButton from '../../components/Buttons/custom/CustomButton';
import { FcEditImage } from 'react-icons/fc';
import { validationSchemaTeamForm } from '../../utils/validations';
import { customChooseImage } from '../../utils/globalFunctions';

const TeamMemberForm = ({ teamId = null, actionControl, refreshData }) => {

    const initialValues = {
        firstName: "",
        lastName: "",
        designation: "",
        linkedIn: "",
        role: "",
        profile: ""
    };

    const [teamMember, setTeamMember] = useState(initialValues);
    const [selectImage, setSelectImage] = useState(null);
    const [imageError, setImageError] = useState("");
    const inputImageRef = useRef(null)
    const [show, setShow] = useState(false);

    // eslint-disable-next-line
    const singleMemberData = async (TID) => {
        try {
            const res = await SingleTeam_Data(TID)
            const getData = res.data.data;
            setSelectImage(getData.profile)
            setTeamMember(getData)
        } catch (e) {
            console.error(e)
        }
    }

    const onSubmit = async () => {
        if (selectImage !== null) {
            const formData = new FormData()
            formData.append("profile", selectImage)
            formData.append("firstName", formik.values.firstName)
            formData.append("lastName", formik.values.lastName)
            formData.append("designation", formik.values.designation)
            formData.append("linkedIn", formik.values.linkedIn)
            formData.append("role", formik.values.role)

            try {
                if (teamId !== null || actionControl === "edit") {
                    const res = await EditTeamMember_Data(teamId, formData)
                    if (res.status === 200) {
                        SweetAlertBox('success', 'Updated Successfully', 'Team  member has been updated successfully.', () => {
                            setShow(false)
                            refreshData()
                            setTeamMember({})
                            setSelectImage(null)
                        })
                    }
                    else {
                        ErrorToast("Participate not updated!")
                    }
                }
                else {
                    const res = await NewTeamMember_Data(formData)
                    if (res.status === 200) {
                        SweetAlertBox('success', 'Added Successfully', 'Team  member has been Added successfully.', () => {
                            setShow(false)
                            refreshData()
                            setTeamMember({})
                            setSelectImage(null)
                        })
                    }
                    else {
                        ErrorToast("Team Member not Added!")
                    }
                }
            } catch (e) {
                console.log(e);
            }
        }
        else {
            setImageError("please choose your profile")
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: teamMember,
        validationSchema: validationSchemaTeamForm,
        onSubmit
    })

    const handleAction = (id) => {
        setShow(true)
        if (id !== "") {
            singleMemberData(id)
        }
        setTeamMember(initialValues)
    }

    const handleClose = () => {
        setSelectImage(null)
        setTeamMember({})
        setShow(false)
    }

    return (
        <div>
            {
                actionControl && actionControl === "add" ?
                    <CustomButton type="button" buttonText="Add" onClick={() => handleAction("")} />
                    : <button className="actionEdit mx-1" onClick={() => handleAction(teamId)}>
                        <FcEditImage size={20} />
                    </button>
            }

            <Modal show={show} size={"lg"} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{teamId !== null ? "Edit Team" : "New Team"}</Modal.Title>
                </Modal.Header>
                <Form noValidate onSubmit={formik.handleSubmit}>
                    <Modal.Body>
                        <Row>
                            <div className="w-100 mb-3 text-center">
                                <div className="team_profile_image my-3">
                                    <img
                                        src={selectImage ? (URL.createObjectURL(selectImage)) : (formik.values.profile)}
                                        alt="Not found"
                                        className="rounded-circle w-100 h-100 object-fit-cover"
                                        onClick={() => inputImageRef.current.click()}
                                        title='choose your image'
                                        onError={(e) => { e.target.src = IMAGE_DEFAULT_AVATER }}
                                    />
                                </div>
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        type="file"
                                        ref={inputImageRef}
                                        name="filename"
                                        accept='.png, .jpg, .jpeg'
                                        className="d-none"
                                        onChange={(e) => {
                                            const res = customChooseImage(e)
                                            setImageError(res.error)
                                            setSelectImage(res.file)
                                        }}
                                    />
                                </Form.Group>
                                {
                                    imageError && imageError !== "" ? <span className='text-danger fw-semibold'>{imageError}</span> : ''
                                }
                            </div>
                        </Row>
                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName="Firstname"
                                    type="text"
                                    as="input"
                                    name="firstName"
                                    formik={formik}
                                />
                            </Col>
                            <Col>
                                <FormikInput
                                    fieldName="Lastname"
                                    type="text"
                                    as="input"
                                    name="lastName"
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormikSelect
                                    dataList={[
                                        {
                                            label: "Select Role",
                                            value: ""
                                        },
                                        {
                                            label: "Developer",
                                            value: "Developer"
                                        },
                                        {
                                            label: "Team",
                                            value: "Team"
                                        }
                                    ]}
                                    fieldName={"Role"}
                                    name="role"
                                    formik={formik}
                                />
                            </Col>
                            <Col>
                                <FormikInput
                                    fieldName="Designation"
                                    type="text"
                                    as="input"
                                    name="designation"
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <FormikInput
                                fieldName="LinkedIn"
                                type="text"
                                as="input"
                                name="linkedIn"
                                formik={formik}
                            />
                        </Row>
                        <Row className='px-3'>
                            <Button variant="primary" type='submit'>
                                Submit
                            </Button>
                        </Row>
                    </Modal.Body>
                </Form>
            </Modal>
        </div >
    );
}

export default TeamMemberForm
