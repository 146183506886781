import React, { useState, useEffect } from "react";
import { Col, Image, Row } from "react-bootstrap";
import InvitationMail from "./InvitationMail";
import MainTitle from "../../../components/mainTitle/mainTitle";
import { IMAGE_DEFAULT_AVATER } from "../../../assets";
import "./InvitationStyle.css";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt, FaLinkedinIn } from "react-icons/fa";
import { AllMembers_Data } from "../../../services/getServices";
import FilterBox from "../../../components/filterBox/FilterBox";
import CustomButton from "../../../components/Buttons/custom/CustomButton";
import { BiRefresh } from "react-icons/bi";
import CustomLoader from '../../../components/loader/loader';


const InvitationMember = () => {

    const [filterText, setFilterText] = useState("");
    const [allMembersList, setAllMembersList] = useState([]);
    const [loading, setLoading] = useState(false)

    // fetch all member list
    const fetchAllMember = async () => {
        try {
            setLoading(true)
            const res = await AllMembers_Data();
            const allMembers = res?.data.data || [];
            setAllMembersList(allMembers);
        }
        catch (e) {
            console.error(e)
        }
        setLoading(false)
    };

    const searchMember = allMembersList?.filter((item) => {
        return Object.values(item).some((value) => typeof value === 'string' && value.toLowerCase().includes(filterText.toLowerCase()));
    })

    useEffect(() => {
        fetchAllMember();
    }, []);

    return (
        <div className="invitationComponents">

            <CustomLoader show={loading} />

            <div className="d-flex flex-column flex-sm-row gap-3 justify-content-center justify-content-sm-between align-items-center w-100 pb-4 px-3">
                <MainTitle
                    heading={"Invitation Member"}
                    textAlign={"center"}
                />
                <InvitationMail />
            </div>

            <div className="allDataTable invitationContainer rounded">

                <div className="sticky-headerBar border-bottom border-2 rounded p-4 d-flex bg-white justify-content-center justify-content-md-between align-items-center flex-wrap gap-3">
                    <div className="d-flex align-items-center">
                        <CustomButton
                            className={"me-2"}
                            buttonText={<BiRefresh fontSize={"20px"} />}
                            type={"button"}
                            onClick={fetchAllMember}
                        />
                        <h5 className={"ms-2"}>
                            Total Members: {searchMember.length}
                        </h5>
                    </div>
                    <FilterBox
                        onFilter={(e) => setFilterText(e.target.value)}
                        onClear={() => setFilterText("")}
                        filterText={filterText}
                    />
                </div>

                <div className="layoutsComponents">

                    <Row className="row-cols-1" md={2} xl={3} xxl={4}>
                        {
                            searchMember.map((data, index) => (
                                <Col className="my-3" key={index}>
                                    <div className="allDataTable gridCard p-4 rounded bg-white d-flex flex-column justify-content-center align-items-center gap-3">
                                        <div className="imageBox shadow p-2">
                                            <Image
                                                src={data.profile}
                                                alt="not found"
                                                className="image"
                                                onError={(e) =>
                                                (e.target.src =
                                                    IMAGE_DEFAULT_AVATER)
                                                }
                                            />
                                        </div>
                                        <div className="my-3 text-center">
                                            <h5 className="text-capitalize">
                                                {data.firstName}{" "}
                                                {data.lastName}
                                            </h5>
                                            <p className="text-lowercase">
                                                {data.company}
                                            </p>
                                            <div className="d-flex justify-content-center align-items-center gap-3">
                                                <a href={`tel:${data.mobile}`} target="_blank" rel="noopener noreferrer">
                                                    <div className="shadow p-2 rounded social-media phone">
                                                        <FaPhoneAlt fontSize={20} />
                                                    </div>
                                                </a>
                                                <a href={`mailto:${data.email}`} target="_blank" rel="noopener noreferrer">
                                                    <div className="shadow p-2 rounded social-media mail">
                                                        <MdEmail fontSize={20} />
                                                    </div>
                                                </a>
                                                <a href={data.linkedin} target="_blank" rel="noopener noreferrer">
                                                    <div className="shadow p-2 rounded social-media linkedin">
                                                        <FaLinkedinIn fontSize={20} />
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default InvitationMember;
