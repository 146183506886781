import React, { useEffect, useState } from 'react'
import CustomLoader from '../../components/loader/loader';
import CustomButton from '../../components/Buttons/custom/CustomButton';
import { BsPlusLg } from 'react-icons/bs';
import { FaEye, FaFileExport, FaLinkedin, FaWhatsapp } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { selectorUserRole } from '../../redux/selector';
import { Link, useNavigate } from 'react-router-dom';
import { AllProspect_Data } from '../../services/getServices';
import { FcEditImage } from 'react-icons/fc';
import DeleteButton from '../../components/Buttons/deleteButton/deleteButton';
import { PROSPECTS_API } from '../../constant/api';
import CustomBtn from '../../components/Buttons/CustomButton/CustomBtn';
import MainTitle from '../../components/mainTitle/mainTitle';
import csvDownload from 'json-to-csv-export';
import DataTableCom from '../../components/datatable/datatablecom';
import { roleSuperUser } from '../../utils/globalVariable';
import { IoMdMail } from "react-icons/io";
import MailBox from '../../components/mailBox/mailBox';

const Prospact = () => {

    const userRole = useSelector(selectorUserRole);
    const [prospactData, setProspactData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [exportCSVData, setExportCSVData] = useState(null)
    const navigate = useNavigate();
    const [sendMail, setSendMail] = useState('')

    // fetch all members data
    const fetchProspactData = async () => {
        setLoading(true);
        try {
            const res = await AllProspect_Data();
            setProspactData(res.data.data);
            let exportData = []
            res.data.data.map((item, index) => (
                exportData = [
                    ...exportData,
                    {
                        no: index + 1,
                        firstname: item.firstName,
                        lastname: item.lastName,
                        email: item.email,
                        mobile: item.mobile,
                        company: item.company,
                        designation: item.designation,
                        linkedIn: item.linkedIn,
                    }
                ]
            ))

            const data = {
                data: exportData,
                filename: "Prospact Data List",
                delimiter: ',',
                headers: ["No", "Firstname", "Lastname", "Email Address", "Mobile", "Company", "Designation", "LinkedIn"]
            }

            setExportCSVData(data)
            setLoading(false);
        }
        catch (err) {
            console.error(err);
            setLoading(false);
        }
    }


    const columns = [
        {
            name: 'No',
            selector: (row, index) => index + 1,
            sortable: true,
            width: '5%',
            minWidth: '5%',
        },
        {
            name: 'Name',
            selector: (row) => row.firstName + " " + row.lastName,
            sortable: true,
        },
        {
            name: 'Email Address',
            selector: (row) => row.email,
            sortable: true,
        },
        {
            name: 'Mobile',
            selector: (row) => row.mobile,
            sortable: true,
        },
        {
            name: 'Company',
            selector: (row) => row.company,
            sortable: true,
        },
        {
            name: 'Designation',
            selector: (row) => row.designation,
            sortable: true,
        },
        {
            name: 'Linkedin',
            selector: (row) => <Link to={row.linkedIn} target="_blank"><FaLinkedin fontSize={25} /></Link>,
            sortable: true,
            grow: 0.5,
        },
        {
            name: 'Actions',
            button: true,
            cell: (item) => {
                return (
                    <div className="d-flex gap-3">
                        <Link to={`https://wa.me/${item.mobile}/`} target='_blank' className='actionWhatsapp'>
                            <FaWhatsapp size={20} color="#000" />
                        </Link>
                        <CustomBtn
                            btnIcon={<IoMdMail size={20} />}
                            btnText=""
                            className="actionEdit"
                            onClick={() => setSendMail(item.email)}
                        />
                        <CustomBtn
                            btnIcon={<FaEye size={20} color="#0d6efd" />}
                            btnText=""
                            className="actionEdit"
                            onClick={() => navigate(`/prospect/${item._id}/view/`)}
                        />
                        <CustomBtn
                            btnIcon={<FcEditImage size={20} />}
                            btnText=""
                            className="actionEdit"
                            onClick={() => navigate(`/prospect/${item._id}/edit/`)}
                        />
                        {
                            userRole && userRole === roleSuperUser ?
                                <DeleteButton
                                    Id={item._id}
                                    apiRoute={PROSPECTS_API}
                                    handleBack={() => { fetchProspactData() }}
                                /> : ""
                        }
                    </div>
                )
            }
        }
    ];

    useEffect(() => {
        fetchProspactData();
    }, [])

    return (
        <div>
            <CustomLoader show={loading} />

            <div className="d-flex flex-column flex-sm-row gap-3 justify-content-center justify-content-sm-between align-items-center w-100 pb-4 px-3">
                <MainTitle heading={"Prospect"} textAlign={"center"} />
                <div className='d-flex gap-3 justify-content-center align-items-center'>
                    <CustomButton buttonText={<BsPlusLg fontSize={"22px"} />} title="New Prospact" onClick={() => navigate('/prospact/create/')} />
                    {
                        exportCSVData && <CustomButton title="Export CSV" buttonText={<FaFileExport fontSize={"22px"} />} type={"button"} onClick={() => csvDownload(exportCSVData)} />
                    }
                </div>
            </div>

            <div className='pb-5'>
                <DataTableCom
                    columns={columns}
                    data={prospactData}
                    refreshData={fetchProspactData}
                />
            </div>

            <MailBox memberEmail={sendMail} />
        </div>
    )
}

export default Prospact