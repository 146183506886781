import {
    ADMINS_API,
    EVENTS_API,
    FEEDBACK_API,
    HOST_API,
    LOGIN_API,
    MEMBERS_API,
    NEW_MEMBERS_API,
    OTP_API,
    VOLUNTEER_API,
    SPEAKER_API,
    TEAM_API,
    LOCATION_API,
    MEMBERSHIP_APPROVED_API,
    INVITATION_MAIL_API,
    EVENTBOOKSLOT_API,
    CLIENT_FEEDBACK_API,
    CONTACTUS_API,
    PROSPECTS_API,
    CUSTOM_SEND_MAIL,
    LOGIN_REGISTRATION_PASSWORD_API,
    LOGIN_PASSWORD_API,
    LOGIN_PASSWORD_RESET_API,
    CHAPTERS_API,
    CHAPTER_API
} from "../constant/api";

import { deleteApi, post, patch } from "./api";

// AUTHENTICATION API METHODS
export const loginUser = async (params) => {
    return post(LOGIN_API, params);
}

export const otpUser = async (params) => {
    return post(OTP_API, params);
}

export const NewAdminPassword_Data = async (params) => {
    return post(LOGIN_REGISTRATION_PASSWORD_API, params)
}

export const LoginAdminPassword_Data = async (params) => {
    return post(LOGIN_PASSWORD_API, params)
}

export const EditAdminPassword_Data = async (params) => {
    return post(LOGIN_PASSWORD_RESET_API, params)
}


// ADMIN SECTION API METHODS
export const NewAdmin_Data = async (params) => {
    return post(ADMINS_API, params)
}

export const EditAdmin_Data = async (params) => {
    return patch(ADMINS_API, params)
}



// MEMBER UPDATE API METHODS
export const NewMember_Data = async (params) => {
    return post(NEW_MEMBERS_API, params);
}

export const EditMember_Data = async (id, params) => {
    return patch(MEMBERS_API + id, params);
}

export const EditParticipateMember_Data = async (id, params) => {
    return patch(EVENTS_API + "update-member/" + id, params);
}

export const DeleteParticipateMember_Data = async (eventId, memberId) => {
    return deleteApi(`${EVENTS_API}delete-member/${eventId}/${memberId}`);
}

// MEMBERSHIP API METHODS
export const EditMemberShip_Data = async (id, params) => {
    return patch(MEMBERSHIP_APPROVED_API + id, params)
}

// INVITATION API METHODS
export const Invitation_Mail = async (params) => {
    return post(INVITATION_MAIL_API, params)
}

//Teams API METHODS
export const NewTeamMember_Data = async (params) => {
    return post(TEAM_API, params);
}

export const EditTeamMember_Data = async (id, params) => {
    return patch(TEAM_API + id, params);
}

export const DeleteTeamMember = async (id) => {
    return deleteApi(TEAM_API + id)
}



// EVENTS POST API METHODS
export const NewEvent_Data = async (params) => {
    return post(EVENTS_API, params);
}

export const EditEvent_Data = async (id, params) => {
    return patch(EVENTS_API + id, params);
}

export const EditEventProfile_Data = async (userId, params) => {
    return patch(EVENTS_API + "update-member/" + userId, params)
}

export const NewEventImage = async (eventId, params) => {
    return post(EVENTS_API + eventId + "/add-image", params)
}

export const DeleteEventImage = async (eventId, imageIndex) => {
    return deleteApi(EVENTS_API + eventId + "/delete-image/" + imageIndex)
}


// EVENT FORM METHODS
export const NewEventBookSlot = async (eventId, params) => {
    return patch(EVENTBOOKSLOT_API + eventId, params)
}

export const EditEventFormData = async (eventId, userId, params) => {
    return patch(`${EVENTS_API}${eventId}/formMembers/${userId}`, params);
}

export const DeleteEventFormData = async (eventId, userId) => {
    return deleteApi(`${EVENTS_API}${eventId}/formMembers/${userId}`);
}

export const EventConfirmationMail = async (params) => {
    return post(EVENTS_API + "event-email", params)
}

// GET INVOLVED API
export const newHost_Data = async (params) => {
    return post(HOST_API, params)
}

export const EditHost_Data = async (userid, params) => {
    return patch(HOST_API + userid, params)
}


export const NewFeedback_Data = async (params) => {
    return post(FEEDBACK_API, params)
}

export const EditFeedback_Data = async (userid, params) => {
    return patch(FEEDBACK_API + userid, params)
}

export const NewVolunteer_Data = async (params) => {
    return post(VOLUNTEER_API, params);
}

export const EditVolunteer_Data = async (userid, params) => {
    return patch(VOLUNTEER_API + userid, params)
}

export const NewSpeaker_Data = async (params) => {
    return post(SPEAKER_API, params)
}

export const EditSpeaker_Data = async (userid, params) => {
    return patch(SPEAKER_API + userid, params)
}

// Location All API
export const NewLocation_Data = async (params) => {
    return post(LOCATION_API, params)
}

export const DeleteLocation_Data = async (id) => {
    return deleteApi(LOCATION_API, id)
}

// client feedback API
export const NewClient_Feedback_Data = async (params) => {
    return post(CLIENT_FEEDBACK_API, params)
}

export const EditClient_Feedback_Data = async (id, params) => {
    return patch(CLIENT_FEEDBACK_API + id, params)
}

export const DeleteClient_Feedback_Data = async (id) => {
    return deleteApi(CLIENT_FEEDBACK_API + id)
}


// contact us form API
export const NewContactUs_Data = async (params) => {
    return post(CONTACTUS_API, params)
}

export const EditContactUs_Data = async (id, params) => {
    return patch(CONTACTUS_API + id, params)
}



// All Prospect
export const NewProspect_Data = async (params) => {
    return post(PROSPECTS_API, params)
}


export const EditProspect_Data = async (id, pages) => {
    return patch(PROSPECTS_API + id, pages)
}


// Custome Mail Send Api
export const NewCustomEmail_Data = async (params) => {
    return post(CUSTOM_SEND_MAIL, params)
}


// chapter all apis
export const NewChapter_Data = async (params) => {
    return post(CHAPTERS_API, params)
}

export const NewChapterSpeaker_Data = async (id, params) => {
    return patch(CHAPTER_API + id, params)
}

export const NewChapterVolunteer_Data = async (id, params) => {
    return patch(CHAPTER_API + id, params)
}