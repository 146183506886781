import React from 'react';
import CustomLoader from '../../components/loader/loader';
import { Col, Row } from 'react-bootstrap';
import MainTitle from '../../components/mainTitle/mainTitle';
import { useState } from 'react';
import './teamImages.css';
import { FcFullTrash } from 'react-icons/fc';
import TeamMemberForm from './addTeamMember';
import CustomButton from '../../components/Buttons/custom/CustomButton';
import { AllTeam_Data } from '../../services/getServices';
import { useEffect } from 'react';
import { BiRefresh } from 'react-icons/bi'
import { IMAGE_DEFAULT_AVATER } from '../../assets';
import { DeleteTeamMember } from '../../services/postServices';
import { SweetConfirm, SweetAlertBox } from '../../components/alert/sweetAlertPop';
import { useSelector } from 'react-redux';
import { selectorUserRole } from '../../redux/selector';
import { roleSuperUser } from '../../utils/globalVariable';



const TeamImages = () => {
    const [loading, setLoading] = useState(false);
    const [teamData, setTeamData] = useState([])
    const userRole = useSelector(selectorUserRole);


    const fetchTeamsMembersData = async () => {
        try {
            setLoading(true);
            const res = await AllTeam_Data();
            setTeamData(res.data.data)
        } catch (err) {
            console.error(err);
        }
        finally {
            setLoading(false)
        }
    }

    const deleteTeamMember = async (id) => {
        try {
            SweetConfirm('Delete Record', 'Confirm deletion of selected item?', 'Delete', async () => {
                setLoading(true)
                const res = await DeleteTeamMember(id);
                if (res.status === 200) {
                    SweetAlertBox('success', 'Deleted', 'That record is deleted successfully.', () => fetchTeamsMembersData())
                }
                else {
                    SweetAlertBox('error', 'Error Occurred', 'An unexpected error has occurred. Please try again later', () => { })
                }
            })
        }
        catch (err) {
            console.error(err)
        }
        finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchTeamsMembersData();
    }, [])


    return (
        <div className="teamMembersComponent">

            <CustomLoader show={loading} />

            <div className="d-flex flex-column flex-sm-row gap-3 justify-content-center justify-content-sm-between align-items-center w-100 pb-4 px-3">
                <CustomButton className={'me-2'} buttonText={<BiRefresh fontSize={"20px"} />} type={"button"} onClick={fetchTeamsMembersData} />
                <MainTitle heading={"Our Team"} textAlign={"center"} />
                <TeamMemberForm actionControl={"add"} refreshData={fetchTeamsMembersData} />
            </div>

            <div className="allDataTable teamsContainer w-100 rounded">
                <div className="layoutsComponents">
                    <Row className="row-cols-1" md={2} xl={3} xxl={4}>
                        {
                            teamData && teamData.length !== 0 ? (
                                teamData.map((item) => (
                                    <Col key={item.id}>
                                        <div className="allDataTable gridCard p-4 rounded bg-white d-flex flex-column justify-content-start align-items-center gap-3">
                                            <div className="imageBox shadow p-2">
                                                <img
                                                    src={item.profile}
                                                    alt="not found"
                                                    className="image"
                                                    onError={(e) => (e.target.src = IMAGE_DEFAULT_AVATER)}
                                                />
                                            </div>
                                            <div className="my-3 text-center">
                                                <div className="text-center text-danger mb-3">
                                                    <span className="fw-bold fs-5">{item.role}</span>
                                                </div>
                                                <h5 className="text-capitalize">
                                                    {item.firstName} {item.lastName}
                                                </h5>
                                                <h6 className="text-capitalize">{item.designation}</h6>
                                                <p className="text-lowercase">{item.role}</p>
                                            </div>

                                            <div className="participateActionControls">
                                                <TeamMemberForm actionControl={"edit"} teamId={item._id} refreshData={fetchTeamsMembersData} />
                                                {
                                                    userRole && userRole === roleSuperUser ?
                                                        <button className="actionDelete mx-1" onClick={() => deleteTeamMember(item._id)}>
                                                            <FcFullTrash size={20} />
                                                        </button> : ""
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                ))
                            ) : (
                                <div className="text-center w-100">
                                    <h2 className='text-secondary m-0'>No team members found</h2>
                                </div>
                            )
                        }
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default TeamImages;