// const project = 'isc'
const project = 'hrc'
const projectType = 'production'
// const projectType = 'development'



const ISCDATA = {
    projectFavicon: "itfavicon.ico",
    projectTitle: "Admin Panel For IT Sales Community",
    projectName: "ISC",
    projectUrl: "https://itsalescommunity.com",
    projectAPIUrl: "https://api.itsalescommunity.com/api",
    projectLocalAPIUrl: "http://localhost:8000/api",
    // projectLocalAPIUrl: "https://itsc-backend.vercel.app/api",
}

const HRCDATA = {
    projectFavicon: "hrfavicon.ico",
    projectTitle: "Admin Panel For HR Professional Community",
    projectName: "HRC",
    projectUrl: "https://hrprofessionalcommunity.com",
    projectAPIUrl: "https://api.hrprofessionalcommunity.com/api",
    projectLocalAPIUrl: "http://localhost:7000/api",
}



const projectData = project === 'isc' ? ISCDATA : HRCDATA
export const projectFavicon = projectData.projectFavicon
export const projectTitle = projectData.projectTitle
export const projectName = projectData.projectName
export const projectUrl = projectData.projectUrl
export const projectAPIUrl = projectType === "production" ? projectData.projectAPIUrl : projectData.projectLocalAPIUrl


export const roleSuperUser = "super-admin"
export const roleAdmin = "admin"
export const roleUser = "user"
export const checkISC = "ISC"
export const checkHRC = "HRC"
