import React, { useEffect, useState } from 'react'
import { IoMdClose } from 'react-icons/io'
import FormikInput from '../filedbox/formikInput'
import './mailBox.css'
import { useFormik } from 'formik'
import { Form } from 'react-bootstrap'
import { validationSchemaMailBox } from '../../utils/validations'
import { NewCustomEmail_Data } from '../../services/postServices'
import { SweetAlertBox } from '../alert/sweetAlertPop'
import { CgMaximizeAlt } from "react-icons/cg";

const MailBox = ({ memberEmail = '' }) => {

    const initialValues = {
        to: '',
        cc: '',
        bcc: '',
        subject: '',
        message: ''
    }

    const [emailData, setEmailData] = useState(initialValues);
    const [show, setShow] = useState(false)
    const [boxSize, setBoxSize] = useState(false)

    const onSubmit = async () => {
        try {
            const formEmailData = {
                email: formik.values.to,
                ccMail: formik.values.cc,
                bccMail: formik.values.bcc,
                subject: formik.values.subject,
                body: formik.values.message,
            }
            console.log(formEmailData)
            const res = await NewCustomEmail_Data(formEmailData)
            if (res.data.status === 200) {
                SweetAlertBox('success', 'Email Send', 'Your Email Send Successfully', () => {
                    setShow()
                    setEmailData({})
                })
            }
            else {
                SweetAlertBox('error', 'Email Not Send!', 'Sorry! Your Email has been not send!', () => {
                    setShow()
                    setEmailData({})
                })
            }
        }
        catch (err) {
            console.error(err)
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: emailData,
        validationSchema: validationSchemaMailBox,
        onSubmit,
    })

    useEffect(() => {
        if (memberEmail !== '') {
            setEmailData({
                ...emailData,
                to: memberEmail
            })
            setShow(true)
        }
    }, [memberEmail])


    return (
        <div className='mailBoxContainer'>
            <div className={`mailBox ${show ? 'active' : ''} ${boxSize ? 'maximize' : ''}`}>
                <div className='px-4 py-3 d-flex justify-content-between align-items-center border- border-bottom border-secondary border-2'>
                    <div className='fs-5 fw-bold'>New Email</div>
                    <div className='d-flex justify-content-center align-items-center gap-3'>
                        <CgMaximizeAlt fontSize={20} onClick={() => setBoxSize(!boxSize)} className='cursor-pointer' />
                        <IoMdClose fontSize={20} onClick={() => setShow(false)} className='cursor-pointer' />
                    </div>
                </div>
                <Form className='px-4 py-2' onSubmit={formik.handleSubmit}>
                    <div>
                        <FormikInput
                            fieldName=""
                            type="text"
                            as="input"
                            name="to"
                            placeholder="to"
                            formik={formik}
                        />
                    </div>
                    <div>
                        <FormikInput
                            fieldName=""
                            type="text"
                            as="input"
                            name="cc"
                            placeholder="cc"
                            formik={formik}
                        />
                    </div>
                    <div>
                        <FormikInput
                            fieldName=""
                            type="text"
                            as="input"
                            name="bcc"
                            placeholder="bcc"
                            formik={formik}
                        />
                    </div>
                    <div>
                        <FormikInput
                            fieldName=""
                            type="text"
                            as="input"
                            name="subject"
                            placeholder="subject"
                            formik={formik}
                        />
                    </div>
                    <div>
                        <FormikInput
                            fieldName=""
                            type="text"
                            as="textarea"
                            name="message"
                            placeholder="message"
                            row={8}
                            formik={formik}
                        />
                    </div>
                    <div>
                        <button type='submit' className='btn btn-primary'>Send Mail</button>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default MailBox