import React, { useEffect, useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import MainTitle from "../../../components/mainTitle/mainTitle";
import CustomLoader from "../../../components/loader/loader";
import { IMAGE_DEFAULT_AVATER } from "../../../assets/index";
import "./participate.css";
import { SingleEvent_Data } from "../../../services/getServices";
import { useNavigate, useParams } from "react-router-dom";
import FilterBox from "../../../components/filterBox/FilterBox";
import EditParticipate from "./EditParticipate";
import NewParticipate from "./NewParticipate";
import CustomButton from "../../../components/Buttons/custom/CustomButton";
import { FcEditImage, FcFullTrash } from "react-icons/fc";
import { DeleteParticipateMember_Data } from "../../../services/postServices";
import { SweetConfirm, SweetAlertBox } from "../../../components/alert/sweetAlertPop";
import { BiRefresh } from "react-icons/bi";
import BackButton from "../../../components/Buttons/custom/BackButton";
import { useSelector } from 'react-redux';
import { selectorUserRole } from '../../../redux/selector';
import { roleSuperUser } from '../../../utils/globalVariable';


const ParticipatePage = () => {

    const userRole = useSelector(selectorUserRole);
    const { eventId } = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [participateData, setParticipateData] = useState([]);
    const [filterText, setFilterText] = useState("");

    // edit participate state
    const [show, setShow] = useState(false);
    const [selectedParticipant, setSelectedParticipant] = useState(null);

    const handleShow = (data) => {
        setShow(true);
        setSelectedParticipant(data);
    };

    // fetch participate data
    const fetchParticipateData = async () => {
        try {
            setLoading(true);
            const res = await SingleEvent_Data(eventId);
            const sortedParticipate = res?.data.data.eventMembers
                .slice()
                .sort((a, b) => {
                    const nameA = a.user_id.firstName.toUpperCase();
                    const nameB = b.user_id.firstName.toUpperCase();
                    if (nameA < nameB) return -1;
                    if (nameA > nameB) return 1;
                    return 0;
                });
            setParticipateData(sortedParticipate);
            setLoading(false);
        } catch (err) {
            console.error(err);
        }
    };

    // filter x btn clear text
    const handleClear = () => {
        if (filterText) {
            setFilterText("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    // filter participate data
    const filteredParticipate = participateData?.filter((item) => {
        const startFirstName = (item.user_id.firstName + "" + item.user_id.lastName).toLowerCase();
        const startLastName = (item.user_id.lastName + "" + item.user_id.firstName).toLowerCase();
        const searchText = filterText.toLowerCase();
        return (
            startFirstName.includes(searchText) ||
            startFirstName.replace(/\s/g, "").includes(searchText) ||
            startLastName.includes(searchText) ||
            startLastName.replace(/\s/g, "").includes(searchText)
        )
    })

    // Delete Participate Member Function
    const handleParticipateDelete = (event_id, member_ID) => {
        SweetConfirm("Delete Record", "Are your sure this participate deleted?", "Delete", async () => {
            const res = await DeleteParticipateMember_Data(event_id, member_ID)
            if (res.status === 200) {
                SweetAlertBox("success", "Delete Participate Member", `Participate member is delete successfully`, () => {
                    fetchParticipateData();
                })
            } else {
                SweetAlertBox("error", "Not Deleted", `Sorry! This Participate member is not deleted.`, () => {
                    fetchParticipateData()
                })
            }
        }
        );
    };

    useEffect(() => {
        if (eventId !== undefined) {
            fetchParticipateData();
        } else {
            navigate("/events/");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventId]);

    return (
        <div className="participateMembersComponent">
            <CustomLoader show={loading} />

            <div className="d-flex flex-wrap justify-content-center align-items-center justify-content-lg-between py-5 px-0 px-md-5">
                <div className="d-flex flex-wrap justify-content-center align-items-center gap-3">
                    <BackButton />
                    <MainTitle
                        heading={"Participate Members"}
                        textAlign={"center"}
                    />
                </div>
                <div className="d-flex flex-wrap justify-content-center align-items-center gap-3">
                    <NewParticipate
                        participateMember={participateData}
                        bookClick={fetchParticipateData}
                    />
                </div>
            </div>

            <div className="allDataTable participateContainer w-100 rounded">
                <div>
                    <div className="sticky-headerBar border-bottom border-2 rounded p-4 d-flex bg-white justify-content-center justify-content-md-between align-items-center flex-wrap gap-3">
                        <div className="d-flex align-items-center">
                            <CustomButton
                                className={"me-2"}
                                buttonText={<BiRefresh fontSize={"20px"} />}
                                type={"button"}
                                onClick={() => fetchParticipateData()}
                            />
                            <h5 className={"ms-2"}>
                                Total Participate:{" "}
                                {filteredParticipate.length}
                            </h5>
                        </div>
                        <FilterBox
                            onFilter={(e) => setFilterText(e.target.value)}
                            onClear={handleClear}
                            filterText={filterText}
                        />
                    </div>

                    <div className="layoutsComponents">
                        <Row className="row-cols-1" md={2} xl={3} xxl={4}>
                            {
                                filteredParticipate && filteredParticipate.map((data, index) => (
                                    <Col className="my-3" key={index}>
                                        <div className="allDataTable gridCard p-4 rounded bg-white d-flex flex-column justify-content-start align-items-center gap-3">
                                            <div className="imageBox shadow p-2">
                                                <Image
                                                    src={data.user_id.profile}
                                                    alt="not found"
                                                    className="image"
                                                    onError={(e) => (e.target.src = IMAGE_DEFAULT_AVATER)}
                                                />
                                            </div>
                                            <div className="my-3 text-center">
                                                <div className="text-center text-danger mb-3">
                                                    <span className="fw-bold fs-5">{data.role}</span>
                                                </div>
                                                <h5 className="text-capitalize">
                                                    {data.user_id.firstName}{" "}{data.user_id.lastName}
                                                </h5>
                                                <h6 className="text-capitalize">
                                                    {data.user_id.designation}
                                                </h6>
                                                <p className="text-lowercase">
                                                    {data.user_id.company}
                                                </p>
                                            </div>

                                            <div className="participateActionControls">
                                                <button className="actionEdit mx-1" onClick={() => handleShow(data)}>
                                                    <FcEditImage size={20} />
                                                </button>
                                                {
                                                    userRole && userRole === roleSuperUser ?
                                                        <button className="actionDelete mx-1" onClick={() => handleParticipateDelete(eventId, data.user_id._id)}>
                                                            <FcFullTrash size={20} />
                                                        </button> : ""
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                ))
                            }
                        </Row>
                        {
                            show && selectedParticipant !== null ? (
                                <EditParticipate
                                    data={selectedParticipant}
                                    handleClose={() => setShow(false)}
                                    handleSubmit={() => {
                                        setShow(false);
                                        fetchParticipateData();
                                    }}
                                />
                            ) : ""
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ParticipatePage;
