export const customStyles = {
    rows: {
        style: {
            minHeight: '50px', // override the row height
            // maxWidth: '1500px', // override the row height
            padding: '10px 20px',
            textAlign: 'left',
        },
    },
    headCells: {
        style: {
            fontSize: 16,
            // textAlign: 'center',
            // display: 'flex',
            // justifyContent: 'center',
            fontWeight: 700,
        },
    },
    cells: {
        style: {
            fontSize: 16,
            fontWeight: 500,
            // textAlign: 'left',
        },
    },
};