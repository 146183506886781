import React, { useState, useEffect } from 'react'
import { Row, Col, Image } from 'react-bootstrap'
import CustomLoader from '../../../components/loader/loader'
import MainTitle from '../../../components/mainTitle/mainTitle'
import { useNavigate, useParams } from 'react-router-dom'
import { SingleEvent_Data } from '../../../services/getServices'
import './eventImages.css'
import { DeleteEventImage } from '../../../services/postServices'
import { FcFullTrash } from 'react-icons/fc'
import { SweetConfirm, SweetAlertBox } from '../../../components/alert/sweetAlertPop'
import AddNewImage from './AddNewImage'
import { IMAGE_NOTFOUND } from '../../../assets'
import BackButton from '../../../components/Buttons/custom/BackButton'
import { useSelector } from 'react-redux';
import { selectorUserRole } from '../../../redux/selector';
import { roleSuperUser } from '../../../utils/globalVariable';

const EventImages = () => {

    const userRole = useSelector(selectorUserRole);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [eventImages, setEventImages] = useState([]);

    const { eventId } = useParams();

    const singleEventData = async () => {
        setLoading(true);
        try {
            const res = await SingleEvent_Data(eventId);
            setEventImages(res.data.data.eventImages);
            setLoading(false);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };

    const deleteImage = async (index) => {
        try {
            SweetConfirm('Delete Record', 'Confirm deletion of selected item?', 'Delete', async () => {
                const res = await DeleteEventImage(eventId, index);
                if (res.status === 200) {
                    SweetAlertBox('success', 'Deleted', 'That record is deleted successfully.', () => singleEventData())
                }
                else {
                    SweetAlertBox('error', 'Error Occurred', 'An unexpected error has occurred. Please try again later', () => singleEventData())
                }
            })
        }
        catch (err) {
            console.error(err)
        }
    }

    useEffect(() => {
        if (eventId !== undefined) {
            singleEventData();
        }
        else {
            navigate('/events')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventId])

    return (
        <div>
            <CustomLoader show={loading} />

            <div className='py-4 d-flex justify-content-between align-items-center'>
                <BackButton />
                <MainTitle heading={"Event Images"} textAlign={"center"} />
                <AddNewImage eventID={eventId} fetchData={singleEventData} />
            </div>

            <Row sm={1} md={1} lg={3} xl={4}>
                {
                    eventImages && eventImages.length !== 0 ? (
                        eventImages.map((item, index) => (
                            <Col key={index}>
                                <div className='imageContainer'>
                                    <Image
                                        className='rounded p-2 shadow my-3 w-100'
                                        src={item}
                                        alt={`not found`}
                                        onError={(e) => e.target.src = IMAGE_NOTFOUND}
                                    />
                                    {
                                        userRole && userRole === roleSuperUser ?
                                            <div className='deleteImage'>
                                                <button className="actionDelete" onClick={() => deleteImage(index)} title='delete'>
                                                    <FcFullTrash size={20} />
                                                </button>
                                            </div> : ""
                                    }
                                </div>
                            </Col>
                        ))
                    ) : (
                        <div className='text-center fs-2 text-secondary my-3 w-100'>Not Found Images</div>
                    )
                }

            </Row>

        </div>
    )
}

export default EventImages