import React, { useEffect, useState } from "react";
import MainTitle from "../../components/mainTitle/mainTitle";
import NewChapter from "./NewChapter";
import "./ChapterStyle.css";
import CustomButton from "../../components/Buttons/custom/CustomButton";
import { AllChapters_Data } from "../../services/getServices";
import CustomLoader from "../../components/loader/loader";
import { BiRefresh } from "react-icons/bi";
import FilterBox from "../../components/filterBox/FilterBox";
import { Col, Image, Row } from "react-bootstrap";
import { IMAGE_CHAPTER, IMAGE_DEFAULT_AVATER } from "../../assets";
import { FaLocationDot, FaUsers } from "react-icons/fa6";
import { SweetAlertBox } from "../../components/alert/sweetAlertPop";
import { FcEditImage, FcFullTrash } from "react-icons/fc";
import { DeleteLocation_Data } from "../../services/postServices";
import { roleSuperUser } from "../../utils/globalVariable";
import { selectorUserRole } from "../../redux/selector";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import DeleteButton from "../../components/Buttons/deleteButton/deleteButton";
import CustomBtn from "../../components/Buttons/CustomButton/CustomBtn";
import DataTableCom from "../../components/datatable/datatablecom";
import ChapterSpeaker from "./ChapterSpeaker";
import ChapterVolunteer from "./ChapterVolunteer";

const Chapters = () => {

    const navigate = useNavigate()
    const [loading, setLoading] = useState("");
    const [chapterData, setChapterData] = useState([]);
    const userRole = useSelector(selectorUserRole);

    // fetch chapters data
    const fetchChaptersData = async () => {
        try {
            setLoading(true);
            const res = await AllChapters_Data();
            setChapterData(res.data.data);
        }
        catch (err) {
            console.error(err);
        }
        finally {
            setLoading(false);
        }
    };

    const columns = [
        {
            name: 'No',
            selector: (row, index) => index + 1,
            sortable: true,
            grow: 0,
        },
        {
            name: 'Chapter',
            selector: (row) => row.chapterName,
            sortable: true,
            grow: 0.5,
        },
        {
            name: 'City',
            sortable: true,
            selector: (row) => row.cityName,
            grow: 0.2,
        },
        {
            name: 'Leader Name',
            sortable: true,
            selector: (row) => row.details.leader?.leaderName,
            grow: 0.5,
        },
        {

            name: 'Actions',
            button: true,
            grow: 10,
            cell: (item) => {
                return <>
                    <div className='d-flex gap-2'>
                        <CustomBtn
                            btnIcon={<FaEye size={20} color="#0d6efd" />}
                            btnText=""
                            className="actionEdit"
                            onClick={() => navigate(`/chapter/${item.path}/view/`)}
                        />
                        <ChapterSpeaker participateSpeaker={item.details.speakers} chapterId={item._id} refreshData={fetchChaptersData} />
                        <ChapterVolunteer participateSpeaker={item.details.volunteers} chapterId={item._id} refreshData={fetchChaptersData} />
                        {/* <CustomBtn
                            btnIcon={<FcEditImage size={20} />}
                            btnText=""
                            className="actionEdit"
                            onClick={() => navigate(`/chapter/${item._id}/edit`)}
                        /> */}
                        {/* {
                            userRole && userRole === roleSuperUser ?
                                <DeleteButton
                                    Id={item._id}
                                    apiRoute={EVENTS_DELETE_API}
                                    handleBack={() => { fetchAllEventData() }}
                                /> : ""
                        } */}
                    </div>
                </>
            }
        }

    ];


    useEffect(() => {
        fetchChaptersData()
    }, [])

    return (
        <div className="chaptersComponents">
            <CustomLoader show={loading} />

            <div className="d-flex flex-column flex-sm-row gap-3 justify-content-center justify-content-sm-between align-items-center w-100 pb-4 px-3">
                <div className="d-flex justify-content-center align-items-center gap-2">
                    <MainTitle heading={"Chapters"} textAlign={"center"} />
                </div>
                <CustomButton buttonText={"Add"} onClick={() => navigate('/chapter/create')} />
            </div>

            <div className='pb-5'>
                <DataTableCom
                    columns={columns}
                    data={chapterData}
                    refreshData={fetchChaptersData}
                />
            </div>
        </div>
    );
};

export default Chapters;
