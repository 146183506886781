import React, { useEffect, useState } from 'react'
import FormikInput from '../../components/filedbox/formikInput'
import CustomButton from '../../components/Buttons/custom/CustomButton'
import { Col, Container, Form, Row } from 'react-bootstrap'
import MainTitle from '../../components/mainTitle/mainTitle'
import { EditProspect_Data, NewProspect_Data } from '../../services/postServices'
import { validationSchemaNewProspact } from '../../utils/validations'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { SweetAlertBox } from '../../components/alert/sweetAlertPop'
import { useFormik } from "formik";
import BackButton from '../../components/Buttons/custom/BackButton'
import { SingleProspect_Data } from '../../services/getServices'


const ProspactForm = () => {

    const initialValues = {
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        company: "",
        designation: "",
        linkedIn: ""
    }

    const { pid } = useParams();
    const navigate = useNavigate();
    const [prospactData, setProspactData] = useState(initialValues);
    const currentPath = useLocation()
    const checkUrls = currentPath.pathname.split('/')

    const singleProspactData = async (id) => {
        try {
            const res = await SingleProspect_Data(id);
            setProspactData(res.data.data);
        } catch (e) {
            console.error(e);
        }
    };

    const onSubmit = async () => {
        try {
            const formProspactData = {
                firstName: formik.values.firstName,
                lastName: formik.values.lastName,
                email: formik.values.email,
                mobile: formik.values.mobile,
                company: formik.values.company,
                designation: formik.values.designation,
                linkedIn: formik.values.linkedIn,
            }
            if (checkUrls.includes('create') || pid === '') {
                const res = await NewProspect_Data(formProspactData)
                if (res.status === 201) {
                    SweetAlertBox('success', 'Prospact Saved', `${formik.values.firstName + " " + formik.values.lastName} prospact is saved successfully`, () => {
                        navigate('/prospact')
                    })
                }
                else {
                    SweetAlertBox('error', 'Not Saved', `Sorry! ${formik.values.firstName + " " + formik.values.lastName} prospact is not saved.`, () => {
                        navigate('/prospact')
                    })
                }
            }
            else if (checkUrls.includes('edit') || pid === '') {
                const res = await EditProspect_Data(pid, formProspactData)
                if (res.status === 200) {
                    SweetAlertBox('success', 'Prospact Updated', `${formik.values.firstName + " " + formik.values.lastName} prospact is update successfully`, () => {
                        navigate('/prospact')
                    })
                }
                else {
                    SweetAlertBox('error', 'Not Updated', `Sorry! ${formik.values.firstName + " " + formik.values.lastName} prospact is not updated.`, () => {
                        navigate('/prospact')
                    })
                }
            }
        }
        catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        if (pid !== '') {
            singleProspactData(pid)
        }
    }, [pid])


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: prospactData,
        validationSchema: validationSchemaNewProspact,
        onSubmit,
    });

    return (
        <Container>
            <div className="d-flex flex-column flex-sm-row gap-3 justify-content-center justify-content-sm-start align-items-center w-100 pb-4 px-3">
                <BackButton />
                <div className='w-100 d-flex flex-column flex-sm-row justify-content-between align-items-center'>
                    <MainTitle
                        heading={"New Prospact Form"}
                        heading={checkUrls.includes('edit') || checkUrls.includes('create') ? pid && pid !== undefined ? "Edit Prospect" : "New Prospect" : "View Prospect"}
                        textAlign={"center"}
                    />
                    {
                        checkUrls.includes('view') ?
                            <CustomButton type="button" buttonText="edit" onClick={() => navigate(`/prospect/${pid}/edit/`)} /> : ""
                    }
                </div>
            </div>

            <Container className="allDataTable p-4 pb-0 rounded">
                <Form onSubmit={formik.handleSubmit} className="poolForm mb-5">
                    <Row md={1} sm={1} lg={2}>
                        <Col>
                            <FormikInput
                                fieldName={"Firstname"}
                                type={"text"}
                                as={"input"}
                                name={"firstName"}
                                placeholder={"enter firstname"}
                                disabled={checkUrls.includes('edit') || checkUrls.includes('create') ? false : true}
                                formik={formik}
                            />
                        </Col>
                        <Col>
                            <FormikInput
                                fieldName={"Lastname"}
                                type={"text"}
                                as={"input"}
                                name={"lastName"}
                                placeholder={"enter lastname"}
                                disabled={checkUrls.includes('edit') || checkUrls.includes('create') ? false : true}
                                formik={formik}
                            />
                        </Col>
                    </Row>
                    <Row md={1} sm={1} lg={2}>
                        <Col>
                            <FormikInput
                                fieldName={"Email Address"}
                                type={"email"}
                                as={"input"}
                                name={"email"}
                                placeholder={"enter email address"}
                                disabled={checkUrls.includes('edit') || checkUrls.includes('create') ? false : true}
                                formik={formik}
                            />
                        </Col>
                        <Col>
                            <FormikInput
                                fieldName={"Mobile"}
                                type={"number"}
                                as={"input"}
                                name={"mobile"}
                                placeholder={"enter mobile number"}
                                disabled={checkUrls.includes('edit') || checkUrls.includes('create') ? false : true}
                                formik={formik}
                            />
                        </Col>
                    </Row>
                    <Row md={1} sm={1} lg={2}>
                        <Col>
                            <FormikInput
                                fieldName={"Designation"}
                                type={"text"}
                                as={"input"}
                                name={"designation"}
                                placeholder={"enter designation"}
                                disabled={checkUrls.includes('edit') || checkUrls.includes('create') ? false : true}
                                formik={formik}
                            />
                        </Col>
                        <Col>
                            <FormikInput
                                fieldName={"Company"}
                                type={"text"}
                                as={"input"}
                                name={"company"}
                                placeholder={"enter company"}
                                disabled={checkUrls.includes('edit') || checkUrls.includes('create') ? false : true}
                                formik={formik}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormikInput
                                fieldName={"LinkedIn"}
                                type={"text"}
                                as={"input"}
                                name={"linkedIn"}
                                placeholder={"enter linkedin profile link"}
                                disabled={checkUrls.includes('edit') || checkUrls.includes('create') ? false : true}
                                formik={formik}
                            />
                        </Col>
                    </Row>

                    {
                        checkUrls.includes('edit') || checkUrls.includes('create') ?
                            <div className="d-flex justify-content-around align-items-center py-3">
                                <CustomButton buttonText={"Submit"} type={"submit"} />
                            </div> : ""
                    }
                </Form>
            </Container>

        </Container>
    )
}

export default ProspactForm