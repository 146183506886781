import Swal from "sweetalert2";
import { checkISC, projectName } from "../../utils/globalVariable";

let remainingTime = 5;
let timerInterval;

const timerConfirmFunction = (callback) => {
    timerInterval = setInterval(() => {
        remainingTime--;
        if (remainingTime <= 0) {
            callback();
            clearInterval(timerInterval);
            remainingTime = 5;
            Swal.close();
        }
    }, 1000);
}


const timerCancelFunction = () => {
    timerInterval = setInterval(() => {
        remainingTime--;
        if (remainingTime <= 0) {
            clearInterval(timerInterval);
            remainingTime = 5;
            Swal.close();
        }
    }, 1000);
}

export const SweetConfirm = (title, text, btnName, callback) => {
    Swal.fire({
        title: title,
        text: text,
        icon: 'warning',
        backdrop: false,
        showConfirmButton: true,
        confirmButtonText: btnName,
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonColor: projectName === checkISC ? "#E31937" : "#068043",
        timer: 5000,
        timerProgressBar: true,
        didOpen: () => timerCancelFunction(),
    }).then((result) => {
        if (result.isConfirmed) {
            callback();
            clearInterval(timerInterval);
            remainingTime = 5;
        }
        else {
            clearInterval(timerInterval);
            remainingTime = 5;
        }
    });
}

export const SweetAlertBox = (icon, title, text, callback) => {
    Swal.fire({
        title: title,
        text: text,
        icon: icon,
        backdrop: false,
        confirmButtonText: 'Ok',
        showConfirmButton: true,
        allowOutsideClick: false,
        confirmButtonColor: projectName === checkISC ? "#E31937" : "#068043",
        timer: 5000,
        timerProgressBar: true,
        didOpen: () => timerConfirmFunction(callback),
    }).then((res) => {
        if (res) {
            callback();
            clearInterval(timerInterval);
            remainingTime = 5;
        }
    })
}