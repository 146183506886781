import React, { useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import './ChooseImage.css'
import { IMAGE_DEFAULT_AVATER, IMAGE_NOTFOUND } from '../../../assets';
import { Form } from 'react-bootstrap';
import { customChooseImage } from '../../../utils/globalFunctions';

const ChooseImageInput = ({ image="", imageName, formik, imageState }) => {

    const [imageError, setImageError] = useState("");
    const inputImageRef = useRef(null)

    const currentPath = useLocation()
    const checkUrls = currentPath.pathname.split('/')

    return (
        <div className="chooseImageSection w-100">
            {
                image && image === "profile" ?
                    <div className="chooseUserImageBox text-center my-3">
                        <img
                            src={imageState.chooseImage !== null ? (URL.createObjectURL(imageState.chooseImage)) : formik?.values[imageName] !== "" ? formik?.values[imageName] : IMAGE_DEFAULT_AVATER}
                            alt="Not found"
                            className={`${checkUrls.includes('edit') || checkUrls.includes('create') ? 'cursor-pointer' : ''}`}
                            onClick={() => checkUrls.includes('edit') || checkUrls.includes('create') ? inputImageRef.current.click() : ''}
                            onError={(e) => { e.target.src = IMAGE_DEFAULT_AVATER }}
                        />
                    </div>
                    :
                    <div className="chooseBannerImageBox text-center my-3">
                        <img
                            src={imageState.chooseImage !== null ? (URL.createObjectURL(imageState.chooseImage)) : formik?.values[imageName] !== "" ? formik?.values[imageName] : IMAGE_NOTFOUND}
                            alt="Not found"
                            className={`${checkUrls.includes('edit') || checkUrls.includes('create') ? 'cursor-pointer' : ''}`}
                            onClick={() => checkUrls.includes('edit') || checkUrls.includes('create') ? inputImageRef.current.click() : ''}
                            onError={(e) => { e.target.src = IMAGE_NOTFOUND }}
                        />
                    </div>
            }
            {
                checkUrls.includes('edit') || checkUrls.includes('create') ?
                    <>
                        <Form.Group className="mb-3">
                            <Form.Control
                                type="file"
                                name={imageName}
                                className="d-none"
                                accept='.png,.jpg,.jpeg'
                                ref={inputImageRef}
                                onChange={(e) => {
                                    const res = customChooseImage(e)
                                    setImageError(res.error)
                                    imageState.setChooseImage(res.file)
                                }}
                            />
                        </Form.Group>
                        {imageError && imageError !== '' ? <p className='text-danger text-center fw-semibold'>{imageError}</p> : ''}
                    </>
                    : ""
            }
        </div>
    )
}

export default ChooseImageInput