import React, { useMemo, useState } from 'react'
import DataTable from 'react-data-table-component'
import './datatablecom.css'
import { customStyles } from '../../constant/theme'
import CustomButton from '../Buttons/custom/CustomButton'
import { Form } from 'react-bootstrap'
import FilterBox from '../filterBox/FilterBox'
import { BiRefresh } from 'react-icons/bi'


const DataTableCom = ({ columns, data, refreshData, dataList, dropdownChangeValue }) => {

    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [filterText, setFilterText] = useState('');

    // filter data box
    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        }

        return (
            <div className="d-flex flex-wrap justify-content-center justify-content-sm-between align-items-center w-100 gap-3">
                <div className='d-flex align-items-center'>
                    <CustomButton title="Refresh" buttonText={<BiRefresh fontSize={"20px"} />} type={"button"} onClick={refreshData} />
                    {
                        dataList && dataList !== "" ?
                            <div style={{ width: "fit-content", marginLeft: "20px" }}>
                                <Form.Select onChange={dropdownChangeValue}>
                                    {
                                        dataList.map((item, index) => (
                                            <option key={index} value={item.value}>{item.label}</option>
                                        ))
                                    }
                                </Form.Select>
                            </div> : ""
                    }
                    {/* {
                            data && data !== "" ? <h4 className='ms-4'>Total: {data.length}</h4> : ""
                        } */}
                </div>
                <div>
                    <FilterBox
                        onFilter={e => { setFilterText(e.target.value); }}
                        onClear={handleClear}
                        filterText={filterText} />
                </div>
            </div>
        )
    }, [filterText, resetPaginationToggle]);

    // search event name and location 
    const filteredItems = data?.filter((item) => {
        return Object.values(item).some((value) => typeof value === 'string' && value.toLowerCase().includes(filterText.toLowerCase()));
    });

    // sorted filed function 
    const customSort = (rows, selector, direction) => {
        return rows.sort((rowA, rowB) => {
            const aField = selector(rowA)
            const bField = selector(rowB)
            let comparison = 0;
            if (aField > bField) {
                comparison = 1;
            } else if (aField < bField) {
                comparison = -1;
            }

            return direction === 'desc' ? comparison * -1 : comparison;
        });
    };

    return (
        <div className='allDataTable table-responsive px-3 py-4 rounded'>

            <DataTable
                columns={columns}
                data={filteredItems}
                fixedHeader
                pagination
                highlightOnHover
                paginationResetDefaultPage={resetPaginationToggle}
                dense
                persistTableHead
                fixedHeaderScrollHeight="900px"
                sortFunction={customSort}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                customStyles={customStyles}
            />
        </div>
    )
}

export default DataTableCom