import React from 'react'
import './customButton.css';

const CustomButton = ({ type, buttonText, onClick, className, title }) => {
    return (
        <button
            type={type}
            className={`${className} blueButton`}
            onClick={onClick}
            title={title}>
            {buttonText}
        </button>
    )
}

export default CustomButton;
