import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Col, Form, Row } from "react-bootstrap";
import CustomButton from "../../components/Buttons/custom/CustomButton";
import { LoginAdminPassword_Data, NewAdminPassword_Data } from "../../services/postServices";
import { SweetAlertBox } from "../../components/alert/sweetAlertPop";
import Cookies from "js-cookie";
import FormikInput from "../../components/filedbox/formikInput";
import { convertEncrypted } from "../../utils/globalFunctions";
import { useDispatch } from "react-redux";
import { setAuthentication } from "../../redux/authSlice";
import FormikSelect from "../../components/filedbox/formikSelect";

const LoginPassword = ({ dataSave = false, closeModel }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const initialValues = {
        psemail: "",
        password: "",
        role: ""
    };

    const validationSchema = yup.object({
        psemail: yup
            .string()
            .email("invalid email address")
            .matches(/(@prydan\.com|\.prydan@gmail\.com)$/, { message: "Only @prydan.com or .prydan@gmail.com domains are allowed.", excludeEmptyString: true })
            .required("enter your email address"),

        password: yup
            .string()
            .min(8, 'password must be 8 characters long')
            .matches(/[0-9]/, 'password requires a number')
            .matches(/[a-z]/, 'password requires a lowercase letter')
            .matches(/[A-Z]/, 'password requires an uppercase letter')
            .matches(/[^\w]/, 'password requires a symbol')
            .required('enter your password'),
    });

    const onSubmit = async () => {
        try {
            if (dataSave) {
                // save a new record api called
                const res = await NewAdminPassword_Data({ email: formik.values.psemail, password: formik.values.password, role: formik.values.role });
                if (res.status === 200) {
                    SweetAlertBox('success', 'New Admin User Saved.', "New Admin User save successfully.", () => {
                        closeModel()
                    })
                }
                else if (res.status === 409) {
                    SweetAlertBox('warning', 'Already Exists', `Sorry! This email addres is already exists!`, () => {
                        closeModel()
                    })
                }
                else {
                    SweetAlertBox('error', 'Oops', `Sorry! This email and password are invalid. Please check again.`, () => { })
                }
            }
            else {
                // admin login api called
                const res = await LoginAdminPassword_Data({ email: formik.values.psemail, password: formik.values.password, role: formik.values.role });
                if (res.status === 200) {
                    SweetAlertBox('success', 'Login Successfully', "Login Successful! Welcome back.", () => {
                        const storeCookies = {
                            refereshToken: res.data.data.refresh || null,
                            accessToken: res.data.data.token,
                            userData: {
                                email: res.data.data.decodedToken.email,
                                role: res.data.data.decodedToken.role || 'user'
                            }
                        }
                        const encrypted = convertEncrypted(storeCookies)
                        Cookies.set('accessControl', encrypted, { expires: 1 })
                        dispatch(setAuthentication(storeCookies));
                        navigate('/');
                    })
                }
                else {
                    SweetAlertBox('error', 'Oops', `Sorry! This email and password are invalid. Please check again.`, () => { })
                }
            }
        }
        catch (e) {
            console.error(e)
        }
    }


    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
    });


    return (
        <Form onSubmit={formik.handleSubmit} className="pt-4 pb-2">
            <Row>
                <Col className="col-12">
                    <FormikInput
                        fieldName="Email Address"
                        type="email"
                        as="input"
                        name="psemail"
                        formik={formik}
                    />
                </Col>
                {
                    dataSave && dataSave ?
                        <Col className="col-12">
                            <FormikSelect
                                dataList={[
                                    {
                                        label: "Select Role",
                                        value: "",
                                        default: true
                                    },
                                    {
                                        label: "Super Admin",
                                        value: "super-admin",
                                    },
                                    {
                                        label: "Admin",
                                        value: "admin",
                                    },
                                    {
                                        label: "User",
                                        value: "user",
                                    },
                                ]}
                                fieldName={"Role"}
                                name={"role"}
                                formik={formik}
                            />
                        </Col> : ""
                }
                <Col className="col-12">
                    <FormikInput
                        fieldName="Password"
                        type="password"
                        as="input"
                        name="password"
                        formik={formik}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="text-center pt-4">
                        <CustomButton buttonText={dataSave ? "Admin Save" : "Login"} type={"submit"} />
                    </div>
                </Col>
            </Row>
        </Form>
    )
}

export default LoginPassword