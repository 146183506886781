import React from "react";
import CustomLoader from "../../../components/loader/loader";
import { Col, Container, Form, Row } from "react-bootstrap";
import MainTitle from "../../../components/mainTitle/mainTitle";
import FormikInput from "../../../components/filedbox/formikInput";
import { Link, useNavigate, useParams } from "react-router-dom";
import FormikCheackBox from "../../../components/filedbox/formikCheckBox";
import CustomButton from "../../../components/Buttons/custom/CustomButton";
import { useFormik } from "formik";
import { useState } from "react";
import { SingleSpeaker_Data } from "../../../services/getServices";
import { useEffect } from "react";
import { EditSpeaker_Data, NewSpeaker_Data } from "../../../services/postServices";
import { SweetAlertBox } from "../../../components/alert/sweetAlertPop";
import { ErrorToast } from "../../../components/alert/tostPop";
import FormikRadio from "../../../components/filedbox/formikRadio";
import BackButton from "../../../components/Buttons/custom/BackButton";
import { validationSchemaSpeakerForm } from "../../../utils/validations";

const SpeakerForm = () => {

    const initialValues = {
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        location: "",
        designation: "",
        company: "",
        linkedin: "",
        event: "",
        biography: "",
        speakerVideo: "",
        pastSessionLink: "",
        title: "",
        synopsis: "",
        takeaway1: "",
        takeaway2: "",
        takeaway3: "",
        takeaway4: "",
        duration: "",
        presentation: "",
        reasons: "",
        deliveryplan: "",
        comments: "",
        accept: ""
    };


    const { SpeakerId } = useParams();
    const navigate = useNavigate();
    const [speakerData, setSpeakerData] = useState(initialValues);
    const [loading, setLoading] = useState(false);

    const singleSpeakerData = async () => {
        setLoading(true);
        try {
            const res = await SingleSpeaker_Data(SpeakerId);
            const getData = res.data.data;
            setSpeakerData({
                firstName: getData.firstName,
                lastName: getData.lastName,
                email: getData.email,
                mobile: getData.mobile,
                location: getData.city,
                designation: getData.designation,
                company: getData.companyName,
                linkedin: getData.linkedIn,
                event: getData.eventName,
                biography: getData.biography,
                speackerVideo: getData.speakerVideoLink,
                pastSessionLink: getData.sessionFeedbackLink,
                title: getData.sessionTitle,
                synopsis: getData.sessionSynopsis,
                takeaway1: getData.firstTakeaway,
                takeaway2: getData.secondTakeaway,
                takeaway3: getData.thirdTakeaway,
                takeaway4: getData.fortTakeaway,
                duration: getData.sessionDuration,
                presentation: getData.presentationLink,
                reasons: getData.howSessionCanAddValue,
                deliveryplan: getData.sessionDeliveryPlan,
                comments: getData.additionalComment,
                accept: getData.approved
            });
            setLoading(false);
        } catch (e) {
            console.error(e);
        }
    }

    const onSubmit = async () => {
        const formData = {
            firstName: formik.values.firstName,
            lastName: formik.values.lastName,
            email: formik.values.email,
            mobile: formik.values.mobile.toString(),
            city: formik.values.location,
            designation: formik.values.designation,
            companyName: formik.values.company,
            linkedIn: formik.values.linkedin,
            eventName: formik.values.event,
            biography: formik.values.biography,
            speakerVideoLink: formik.values.speakerVideo === "" ? " " : formik.values.speakerVideo,
            sessionFeedbackLink: formik.values.pastSessionLink === "" ? " " : formik.values.pastSessionLink,
            sessionTitle: formik.values.title === "" ? " " : formik.values.title,
            sessionSynopsis: formik.values.synopsis === "" ? " " : formik.values.synopsis,
            firstTakeaway: formik.values.takeaway1 === "" ? " " : formik.values.takeaway1,
            secondTakeaway: formik.values.takeaway2 === "" ? " " : formik.values.takeaway2,
            thirdTakeaway: formik.values.takeaway3 === "" ? " " : formik.values.takeaway3,
            fortTakeaway: formik.values.takeaway4 === "" ? " " : formik.values.takeaway4,
            sessionDuration: formik.values.duration === "" ? " " : formik.values.duration,
            presentationLink: formik.values.presentation === "" ? " " : formik.values.presentation,
            howSessionCanAddValue: formik.values.reasons === "" ? " " : formik.values.reasons,
            sessionDeliveryPlan: formik.values.deliveryplan === "" ? " " : formik.values.deliveryplan,
            additionalComment: formik.values.comments === "" ? " " : formik.values.comments,
        }

        try {
            if (SpeakerId === undefined) {
                const res = await NewSpeaker_Data(formData)
                if (res.status === 201) {
                    SweetAlertBox("success", "Saved Speaker Data", `${formik.values.firstName + " " + formik.values.lastName} is Added successfully`, () => {
                        navigate('/getInvolved/speakers/')
                    })
                }
                else {
                    ErrorToast("Sorry, This record is updated.")
                    navigate('/getInvolved/speakers/')
                }
            }
            else {
                const res = await EditSpeaker_Data(SpeakerId, formData)
                if (res.status === 200) {
                    SweetAlertBox("success", "Update Speaker", `${formik.values.firstName + " " + formik.values.lastName} is update successfully`, () => {
                        navigate('/getInvolved/speakers/')
                    })
                }
                else {
                    ErrorToast("Sorry, This record is updated.")
                    navigate('/getInvolved/speakers/')
                }
            }

        }
        catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        if (SpeakerId !== undefined) {
            singleSpeakerData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SpeakerId])

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: speakerData,
        validationSchema: validationSchemaSpeakerForm,
        onSubmit,
    })


    return (
        <div>
            <CustomLoader show={loading} />
            <Container>

                <div className="d-flex flex-column flex-sm-row gap-3 justify-content-center justify-content-sm-between align-items-center w-100 pb-4 px-3">
                    <BackButton />
                    <MainTitle heading={SpeakerId === undefined ? "New Speaker" : "Edit Speaker"} textAlign={"center"} />
                </div>

                <Container className="allDataTable p-4 pb-0 rounded">
                    <Form noValidate onSubmit={formik.handleSubmit}>
                        <Row>
                            <Col sm={12} lg={6}>
                                <FormikInput
                                    fieldName={"First Name"}
                                    type={"text"}
                                    name={"firstName"}
                                    as={"input"}
                                    formik={formik}
                                />
                            </Col>
                            <Col sm={12} lg={6}>
                                <FormikInput
                                    fieldName={"Last Name"}
                                    type={"text"}
                                    name={"lastName"}
                                    as={"input"}
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName={"Email Address"}
                                    type={"email"}
                                    name={"email"}
                                    as={"input"}
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} lg={6}>
                                <FormikInput
                                    fieldName={"Mobile Number"}
                                    type={"number"}
                                    name={"mobile"}
                                    as={"input"}
                                    formik={formik}
                                />
                            </Col>
                            <Col sm={12} lg={6}>
                                <FormikInput
                                    fieldName={"City"}
                                    type={"text"}
                                    name={"location"}
                                    as={"input"}
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} lg={6}>

                                <FormikInput
                                    fieldName={"Designation"}
                                    type={"input"}
                                    name={"designation"}
                                    as={"input"}
                                    formik={formik}
                                />
                            </Col>
                            <Col sm={12} lg={6}>
                                <FormikInput
                                    fieldName={"Company"}
                                    type={"text"}
                                    name={"company"}
                                    as={"input"}
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName={"LinkedIn Profile Link"}
                                    type={"link"}
                                    name={"linkedin"}
                                    as={"input"}
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName={"Event Name"}
                                    type={"text"}
                                    name={"event"}
                                    as={"input"}
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName={"Your (Presenter's) Biography"}
                                    type={"textarea"}
                                    as={"textarea"}
                                    name={"biography"}
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName={
                                        <>
                                            <span className='pe-1'>Share Link of your video as a speaker</span>
                                            <span className='ps-1 text-danger'>(Google Drive Link)</span>
                                        </>
                                    }
                                    type={"text"}
                                    as={"input"}
                                    name={"speackerVideo"}
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName={
                                        <>
                                            <span className='pe-1'>Share your Past session's feedback through</span>
                                            <span className='ps-1 text-danger'>(Google Drive Link)</span>
                                        </>
                                    }
                                    type={"link"}
                                    as={"input"}
                                    name={"pastSessionLink"}
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName={"Title of the Session"}
                                    type={"text"}
                                    name={"title"}
                                    as={"input"}
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName={"Short synopsis for the session (50-100 words)"}
                                    type={"text"}
                                    name={"synopsis"}
                                    as={"textarea"}
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName={"1st Takeaway from your session"}
                                    type={"text"}
                                    name={"takeaway1"}
                                    as={"textarea"}
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName={"2nd Takeaway from your session"}
                                    type={"text"}
                                    name={"takeaway2"}
                                    as={"textarea"}
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName={"3rd Takeaway from your session"}
                                    type={"text"}
                                    name={"takeaway3"}
                                    as={"textarea"}
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName={"4th Takeaway from your session"}
                                    type={"text"}
                                    name={"takeaway4"}
                                    as={"textarea"}
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormikRadio
                                    dataList={[
                                        {
                                            label: '30 Minute',
                                            value: '30minute'
                                        },
                                        {
                                            label: '40 Minute',
                                            value: '40minute'
                                        },
                                        {
                                            label: '50 Minute',
                                            value: '50minute'
                                        },
                                        {
                                            label: '1 Hours',
                                            value: '1hours'
                                        }
                                    ]}
                                    fieldName={'Session Duration'}
                                    name={'duration'}
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName={
                                        <>
                                            <span className='pe-1'>Share your proposal/draft through Slide Share</span>
                                            <span className='ps-1 text-danger'>(Google Drive Link)</span>
                                        </>
                                    }
                                    type={"text"}
                                    name={"presentation"}
                                    as={"input"}
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName={"give reasons for how your session would add value to the participants."}
                                    type={"text"}
                                    name={"reasons"}
                                    as={"textarea"}
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName={"Session Delivery Plan"}
                                    type={"text"}
                                    name={"deliveryplan"}
                                    as={"textarea"}
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormikInput
                                    fieldName={"Additional comments or questions for the IT Sales Community team?"}
                                    type={"text"}
                                    name={"comments"}
                                    as={"textarea"}
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormikCheackBox
                                    dataList={[
                                        {
                                            label: (
                                                <>
                                                    I Accept the <Link href="/speaker-policy" className="text-danger fw-bold">Speaker Policy terms and conditions</Link>
                                                </>
                                            ),
                                            value: "YES",
                                        },
                                    ]}
                                    name="accept"
                                    formik={formik}
                                />
                            </Col>
                        </Row >
                        <Row>
                            <div className='d-flex justify-content-around align-items-center py-3'>
                                <CustomButton buttonText={"Submit"} type={'submit'} />
                            </div>
                        </Row>
                    </Form >
                </Container>

            </Container>
        </div>
    )
}

export default SpeakerForm