import React, { useState } from "react";
import { Image } from "react-bootstrap";
import "../sidebar/sidebar.css";
import { Link, useNavigate } from "react-router-dom";
import { IMAGE_HRC_FAVICON, IMAGE_HRC_LOGO, IMAGE_ISC_FAVICON, IMAGE_ISC_LOGO } from "../../assets";
import {
    FcHome,
    FcPlanner,
    FcConferenceCall,
    FcUnlock,
    FcPortraitMode,
    FcMenu,
    FcPodiumWithSpeaker,
    FcVoicePresentation,
    FcBusinessman,
    FcCollaboration,
    FcCustomerSupport,
    FcButtingIn,
    FcInvite,
    FcGlobe,
    FcContacts,
    FcFinePrint
} from "react-icons/fc";
import { IoMdClose, IoMdMenu } from "react-icons/io";
import { useDispatch } from "react-redux";
import { removeAuthentication } from "../../redux/authSlice";
import { SweetConfirm, SweetAlertBox } from "../alert/sweetAlertPop";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { useSelector } from 'react-redux';
import { selectorUserRole } from "../../redux/selector";
import { checkISC, projectName, roleSuperUser } from "../../utils/globalVariable";


const SidebarComponent = ({ children }) => {

    const [collapsed, setCollapsed] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const userRole = useSelector(selectorUserRole);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLogout = () => {
        SweetConfirm("Logout", "Are you sure you want to logout?", "Logout", () => {
            SweetAlertBox("success", "Logout Successfully", "logged out successfully. Goodbye!", () => {
                dispatch(removeAuthentication())
                navigate("/")
            })
        })
    }

    return (
        <main className="mainSection">
            <Sidebar
                className={`sidebarBox ${isExpanded ? "active" : ""}`}
                defaultCollapsed={collapsed}
                breakPoint="md"
                width="250px"
            >
                <div className="logoContainer">
                    <Link to="/">
                        {
                            projectName && projectName === checkISC ?
                                <Image
                                    src={collapsed !== true ? IMAGE_ISC_LOGO : IMAGE_ISC_FAVICON}
                                    className="h-100 w-100"
                                    alt="logo not found"
                                />
                                :
                                <Image
                                    src={collapsed !== true ? IMAGE_HRC_LOGO : IMAGE_HRC_FAVICON}
                                    alt="logo"
                                    className="w-100 h-100"
                                />
                        }
                    </Link>
                </div>
                <Menu>
                    <MenuItem icon={<FcMenu className="menuIcon" />} onClick={() => setCollapsed(!collapsed)}>
                        Menu
                    </MenuItem>

                    <MenuItem icon={<FcHome className="menuIcon" />} component={<Link to="/" />}>
                        Home
                    </MenuItem>

                    {
                        userRole && userRole === roleSuperUser ?
                            <MenuItem icon={<FcPodiumWithSpeaker className="menuIcon" />} component={<Link to="/admin" />}>
                                Admin
                            </MenuItem> : ""
                    }

                    <MenuItem icon={<FcBusinessman className="menuIcon" />} component={<Link to="/teams" />}>
                        Team
                    </MenuItem>

                    <MenuItem icon={<FcGlobe className="menuIcon" />} component={<Link to="/chapter" />}>
                        Chapter
                    </MenuItem>

                    <MenuItem icon={<FcPlanner className="menuIcon" />} component={<Link to="/events" />}>
                        Event
                    </MenuItem>

                    <MenuItem icon={<FcFinePrint className="menuIcon" />} component={<Link to="/prospect" />}>
                        Prospact
                    </MenuItem>

                    <SubMenu icon={<FcConferenceCall className="menuIcon" />} label="Member">
                        <MenuItem icon={<FcConferenceCall className="menuIcon" />} component={<Link to="/members" />}>
                            Members
                        </MenuItem>
                        <MenuItem icon={<FcPortraitMode className="menuIcon" />} component={<Link to="/membership" />}>
                            Membership
                        </MenuItem>
                        <MenuItem icon={<FcInvite className="menuIcon" />} component={<Link to="/invitation" />} >
                            Invitation
                        </MenuItem>
                    </SubMenu>

                    <SubMenu icon={<FcVoicePresentation className="menuIcon" />} label="Get - Involved">
                        <MenuItem icon={<FcBusinessman className="menuIcon" />} component={<Link to="/getInvolved/hosts/" />}>
                            Host
                        </MenuItem>

                        <MenuItem icon={<FcButtingIn className="menuIcon" />} component={<Link to="/getInvolved/speakers" />}>
                            Speaker
                        </MenuItem>

                        <MenuItem icon={<FcCustomerSupport className="menuIcon" />} component={<Link to="/getInvolved/volunteer" />}>
                            Volunteer
                        </MenuItem>

                        <MenuItem icon={<FcCollaboration className="menuIcon" />} component={<Link to="/getInvolved/feedback" />}>
                            Feedback
                        </MenuItem>
                    </SubMenu>

                    <MenuItem icon={<FcCustomerSupport className="menuIcon" />} component={<Link to="/clientFeedback" />}>
                        Client Feedback
                    </MenuItem>
                    <MenuItem icon={<FcContacts className="menuIcon" />} component={<Link to="/contactus" />}>
                        Contact Us
                    </MenuItem>

                    <MenuItem icon={<FcUnlock className="menuIcon" />} onClick={() => handleLogout()}>
                        Logout
                    </MenuItem>
                </Menu>
            </Sidebar>

            <div className={collapsed ? "bodySection" : "bodySection active"}>
                <div className="p-3 navbarToggle">
                    <div style={{ height: "inherit" }}>
                        <Link to={'/'}>
                            {
                                projectName && projectName === checkISC ?
                                    <Image
                                        src={IMAGE_ISC_LOGO}
                                        alt="logo not found"
                                        className="w-100 h-100"
                                    />
                                    :
                                    <Image
                                        src={IMAGE_HRC_LOGO}
                                        className="h-100 w-100 py-3"
                                        alt="logo not found"
                                    />
                            }
                        </Link>
                    </div>
                    {
                        isExpanded && isExpanded ?
                            <IoMdClose
                                className="toggleIcon"
                                color="#000"
                                onClick={() => setIsExpanded(false)}
                            />
                            :
                            <IoMdMenu
                                className="toggleIcon"
                                color="#000"
                                onClick={() => setIsExpanded(true)}
                            />
                    }
                </div>
                <div className="mainBodyContainerBox mt-md-0 py-5 px-3 px-md-5">
                    {children}
                </div>
            </div>
        </main>
    );
};

export default SidebarComponent;
