import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Col, Form, Row } from "react-bootstrap";
import CustomButton from "../../components/Buttons/custom/CustomButton";
import { loginUser } from "../../services/postServices";
import { SweetAlertBox } from "../../components/alert/sweetAlertPop";
import Cookies from "js-cookie";
import FormikInput from "../../components/filedbox/formikInput";

const LoginOtp = () => {

    const navigate = useNavigate();

    const initialValues = {
        otpEmail: "",
    };

    const validationSchema = yup.object({
        otpEmail: yup
            .string()
            .email("invalid email address")
            .required("enter your email address"),
    });


    const onSubmit = async () => {
        try {
            const res = await loginUser({ email: formik.values.otpEmail })
            if (res.status === 200) {
                Cookies.set('em', formik.values.otpEmail)
                SweetAlertBox('success', 'Send OTP Mail', 'Check Your email box', () => {
                    navigate('/otp')
                })
            }
            else {
                SweetAlertBox('error', 'Oops', `Sorry! ${formik.values.otpEmail} is invalied. please check again.`, () => { })
            }
        }
        catch (e) {
            console.error(e)
        }
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
    });


    return (
        <Form onSubmit={formik.handleSubmit} className="py-4">
            <Row>
                <Col>
                    <FormikInput
                        fieldName="Email Address"
                        type="email"
                        as="input"
                        name="otpEmail"
                        formik={formik}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="text-center pt-4">
                        <CustomButton buttonText={"Login"} type={"submit"} />
                    </div>
                </Col>
            </Row>
        </Form>
    )
}

export default LoginOtp