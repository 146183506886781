import React, { useState, useEffect } from 'react';
import { Image } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { DELETE_MEMBER_API } from '../../constant/api';
import { AllMembers_Data } from '../../services/getServices';
import { IMAGE_DEFAULT_AVATER } from '../../assets/index';
import CustomButton from '../../components/Buttons/custom/CustomButton';
import DeleteButton from '../../components/Buttons/deleteButton/deleteButton';
import CustomLoader from '../../components/loader/loader';
import MainTitle from '../../components/mainTitle/mainTitle';
import DataTableCom from '../../components/datatable/datatablecom';
import { BsPlusLg } from 'react-icons/bs'
import { FaEye, FaFileExport } from 'react-icons/fa'
import csvDownload from 'json-to-csv-export'
import { useSelector } from 'react-redux';
import { selectorUserRole } from '../../redux/selector';
import { roleSuperUser } from '../../utils/globalVariable';
import CustomBtn from '../../components/Buttons/CustomButton/CustomBtn';
import { FcEditImage } from 'react-icons/fc';



const Members = () => {

    const userRole = useSelector(selectorUserRole);
    const [memberData, setMemberData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [exportCSVData, setExportCSVData] = useState(null)
    const navigate = useNavigate();

    // fetch all members data
    const fetchMembersData = async () => {
        setLoading(true);
        try {
            const res = await AllMembers_Data();
            const allMembers = res?.data.data || [];
            // const combineMemberData = [...(allMembers.approved || []), ...(allMembers.notApproved || [])]
            // setMemberData(combineMemberData);
            setMemberData(allMembers);
            let exportData = []
            allMembers.map((item, index) => (
                exportData = [
                    ...exportData,
                    {
                        no: index + 1,
                        firstname: item.firstName,
                        lastname: item.lastName,
                        email: item.email,
                        mobile: item.mobile,
                        city: item.city,
                        company: item.company,
                        designation: item.designation,
                        memberShip: item.approved ? "approved" : "notApproved"
                    }
                ]
            ))

            const data = {
                data: exportData,
                filename: "Member Data List",
                delimiter: ',',
                headers: ["No", "Firstname", "Lastname", "Email Address", "Mobile", "City", "Company", "Designation", "MemberShip"]
            }

            setExportCSVData(data)
            setLoading(false);
        }
        catch (err) {
            console.error(err);
            setLoading(false);
        }
    }


    const columns = [
        {
            name: 'No',
            selector: (row, index) => index + 1,
            sortable: true,
            width: '5%',
            minWidth: '5%',
        },
        {
            name: 'Image',
            sortable: true,
            selector: (row) => row.image,
            width: '10%',
            minWidth: '10%',
            cell: (item) => {
                return <Image src={item.profile} onError={(e) => e.target.src = IMAGE_DEFAULT_AVATER} alt={'not found'} style={{ width: "50px", height: "50px", objectFit: "cover" }} className="p-1 rounded-pill"></Image>
            }
        },
        {
            name: 'Name',
            selector: (row) => row.firstName + " " + row.lastName,
            sortable: true,
        },
        {
            name: 'Company',
            selector: (row) => row.company,
            sortable: true,
        },
        {
            name: 'Designation',
            selector: (row) => row.designation,
            sortable: true,
        },
        {

            name: 'Actions',
            button: true,
            cell: (item) => {
                return (
                    <div className="d-flex gap-3">
                        <CustomBtn
                            btnIcon={<FaEye size={20} color="#0d6efd" />}
                            btnText=""
                            className="actionEdit"
                            onClick={() => navigate(`/members/${item._id}/view/`)}
                        />
                        <CustomBtn
                            btnIcon={<FcEditImage size={20} />}
                            btnText=""
                            className="actionEdit"
                            onClick={() => navigate(`/members/${item._id}/edit/`)}
                        />
                        {
                            userRole && userRole === roleSuperUser ?
                                <DeleteButton
                                    Id={item._id}
                                    apiRoute={DELETE_MEMBER_API}
                                    handleBack={() => { fetchMembersData() }}
                                /> : ""
                        }
                    </div>
                )
            }
        }
    ];

    useEffect(() => {
        fetchMembersData();
    }, [])


    return (
        <div>
            <CustomLoader show={loading} />

            <div className="d-flex flex-column flex-sm-row gap-3 justify-content-center justify-content-sm-between align-items-center w-100 pb-4 px-3">
                <MainTitle heading={"Members"} textAlign={"center"} />
                <div className='d-flex gap-3 justify-content-center align-items-center'>
                    <CustomButton buttonText={<BsPlusLg fontSize={"22px"} />} title="New Member" onClick={() => navigate('/newMember')} />
                    {
                        exportCSVData && <CustomButton title="Export CSV" buttonText={<FaFileExport fontSize={"22px"} />} type={"button"} onClick={() => csvDownload(exportCSVData)} />
                    }
                </div>
            </div>


            <div className='pb-5'>
                <DataTableCom
                    columns={columns}
                    data={memberData}
                    refreshData={fetchMembersData}
                />
            </div>
        </div>
    )
}

export default Members