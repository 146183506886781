import React from 'react'
import { FcFullTrash } from "react-icons/fc";
import { deleteApi } from '../../../services/api';
import { SweetConfirm, SweetAlertBox } from '../../alert/sweetAlertPop';

const DeleteButton = ({ apiRoute, Id, handleBack }) => {


    const deleteRecord = async (pid) => {
        try {
            SweetConfirm('Delete Record', 'Confirm deletion of selected item?', 'Delete', async () => {
                const url = apiRoute.concat(pid.toString());
                const response = await deleteApi(url)
                if (response.status === 200) {
                    SweetAlertBox('success', 'Deleted', 'That record is deleted successfully.', () => handleBack())
                }
                else {
                    SweetAlertBox('error', 'Error Occurred', 'An unexpected error has occurred. Please try again later', () => { })
                }
            })
        } catch (e) {
            console.error(e);
        }
    }
    return (
        <div>
            {
                Id && Id !== undefined ?
                    <button className="actionDelete" onClick={() => deleteRecord(Id)} title='delete'>
                        <FcFullTrash size={20} />
                    </button> : ""
            }
        </div>
    )
}

export default DeleteButton