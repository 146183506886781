import { get } from "./api";
import {
    MEMBERS_API,
    EVENTS_API,
    COMPLETE_EVENT_API,
    ADMINS_API,
    FEEDBACK_API,
    VOLUNTEER_API,
    HOST_API,
    SPEAKER_API,
    TEAM_API,
    LOCATION_API,
    ALLMEMBERS_API,
    DASHBOARD_API,
    CLIENT_FEEDBACK_API,
    CONTACTUS_API,
    ALL_EVENTS_API,
    PROSPECTS_API,
    CHAPTERS_API
} from "../constant/api";


// ADMIN GET METHODS
export const AllAdmin_Data = async () => {
    return get(ADMINS_API)
}


// Dashboard Method
export const Dashboard_Data = async () => {
    return get(DASHBOARD_API)
}

// MEMBERS GET METHODS
export const AllMembers_Data = async () => {
    return get(ALLMEMBERS_API)
}

export const SingleMember_Data = async (id) => {
    return get(MEMBERS_API + id);
}


// EVENTS GET METHODS
export const AllEvent_Data = async () => {
    return get(ALL_EVENTS_API);
}

export const CompletedEvent_Data = async () => {
    return get(COMPLETE_EVENT_API);
}

export const SingleEvent_Data = async (id) => {
    return get(EVENTS_API + id);
}

// EVENT FORM METHODS
export const SingleEventFormData = async (eventId, userId) => {
    return get(`${EVENTS_API}${eventId}/formMembers/${userId}`);
}

//TEAMS GET API
export const AllTeam_Data = async () => {
    return get(TEAM_API);
}

export const SingleTeam_Data = async (id) => {
    return get(TEAM_API + id);
}


// GET INVOLVED API
export const AllFeedback_Data = async () => {
    return get(FEEDBACK_API)
}

export const SingleFeedback_Data = async (id) => {
    return get(FEEDBACK_API + id)
}

export const AllVolunteer_Data = async () => {
    return get(VOLUNTEER_API)
}

export const SingleVolunteer_Data = async (id) => {
    return get(VOLUNTEER_API + id)

}

export const AllSpeaker_Data = async () => {
    return get(SPEAKER_API)
}

export const SingleSpeaker_Data = async (id) => {
    return get(SPEAKER_API + id)
}

export const AllHost_Data = async () => {
    return get(HOST_API)
}

export const SingleHost_Data = async (id) => {
    return get(HOST_API + id)
}

// Location All API
export const GetLocation_Data = async () => {
    return get(LOCATION_API)
}


// customer feedback API
export const AllClientFeedback_Data = async () => {
    return get(CLIENT_FEEDBACK_API)
}

export const SingleClient_Feedback_Data = async (id) => {
    return get(CLIENT_FEEDBACK_API + id)
}


// Contact Us Methods
export const AllContactUs_Data = async () => {
    return get(CONTACTUS_API)
}

export const SingleContactUs_Data = async (id) => {
    return get(CONTACTUS_API + id)
}

// All Prospect
export const AllProspect_Data = async () => {
    return get(PROSPECTS_API)
}

export const SingleProspect_Data = async (id) => {
    return get(PROSPECTS_API + id)
}

// chapter all apis
export const AllChapters_Data = async () => {
    return get(CHAPTERS_API)
}

export const SingleChapters_Data = async (id) => {
    return get(CHAPTERS_API + id)
}