import React, { useState, useEffect } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import FormikInput from '../../../components/filedbox/formikInput';
import FormikSelect from '../../../components/filedbox/formikSelect';
import * as yup from "yup";
import { useFormik } from "formik";
import { IMAGE_DEFAULT_AVATER } from "../../../assets";
import { EditEventProfile_Data } from '../../../services/postServices';
import { SweetAlertBox } from '../../../components/alert/sweetAlertPop';


const EditParticipate = ({ data = null, handleClose, handleSubmit }) => {

    const initialValues = {
        firstName: "",
        lastName: "",
        role: "",
        company: "",
        designation: "",
    };

    const [eventMemberData, setEventMemberData] = useState(initialValues)

    const validationSchema = yup.object({
        role: yup.string().required('select your role'),
    });

    useEffect(() => {
        console.log(data);
        if (data !== null) {
            setEventMemberData({
                firstName: data.user_id.firstName,
                lastName: data.user_id.lastName,
                company: data.user_id.company,
                designation: data.user_id.designation,
                profile: data.user_id.profile,
                role: data.role,
            })
        }
    }, [data])


    // form submit 
    const onSubmit = async () => {
        try {
            const eventMemberData = {
                role: formik.values.role,
            }

            const res = await EditEventProfile_Data(data?.user_id._id, eventMemberData)
            if (res.status === 200) {
                SweetAlertBox('success', 'Update Member', `${data?.firstName + " " + data?.lastName}'s member has been updated successfully.`, () => {
                    handleSubmit()
                })
            }
            else {
                SweetAlertBox('error', 'Not Updated', `Sorry! ${data?.firstName + " " + data?.lastName}'s member has been not updated.`, () => {
                    handleSubmit()
                })
            }
        }
        catch (e) {
            console.error(e)
        }
    }


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: eventMemberData,
        validationSchema,
        onSubmit,
    });


    return (
        <Modal show={true} size={"lg"} onHide={handleClose} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Participate</Modal.Title>
            </Modal.Header>
            <Form noValidate onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <Row>
                        <div className="w-100 mb-3">
                            <div className="team_profile_image text-center my-3">
                                <img
                                    src={formik.values.profile}
                                    alt="Not found"
                                    className="rounded-circle w-100 h-100 object-fit-cover"
                                    title='choose your image'
                                    onError={(e) => { e.target.src = IMAGE_DEFAULT_AVATER }}
                                />
                            </div>
                        </div>
                    </Row>
                    <Row>
                        <Col>
                            <FormikInput
                                fieldName="Firstname"
                                type="text"
                                as="input"
                                name="firstName"
                                disabled={true}
                                formik={formik}
                            />
                        </Col>
                        <Col>
                            <FormikInput
                                fieldName="Lastname"
                                type="text"
                                as="input"
                                name="lastName"
                                disabled={true}
                                formik={formik}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormikInput
                                fieldName="Company Name"
                                type="text"
                                as="input"
                                name="company"
                                disabled={true}
                                formik={formik}
                            />
                        </Col>
                        <Col>
                            <FormikInput
                                fieldName="Designation"
                                type="text"
                                as="input"
                                disabled={true}
                                name="designation"
                                formik={formik}
                            />
                        </Col>
                        <Col>
                            <FormikSelect
                                dataList={[
                                    {
                                        label: "Select Role",
                                        value: ""
                                    },
                                    {
                                        label: "Host",
                                        value: "Host"
                                    },
                                    {
                                        label: "Speaker",
                                        value: "Speaker"
                                    },
                                    {
                                        label: "Member",
                                        value: "Member"
                                    },
                                    {
                                        label: "Moderator",
                                        value: "Moderator"
                                    }]
                                }
                                fieldName={"Role"}
                                name={"role"}
                                formik={formik}
                            />
                        </Col>
                    </Row>
                    <Row className='px-3'>
                        <Button variant="primary" type='submit'>
                            Submit
                        </Button>
                    </Row>
                </Modal.Body>
            </Form>
        </Modal>
    )
}

export default EditParticipate