import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { convertDecrypted } from "../utils/globalFunctions";


const getCookieData = Cookies.get('accessControl', { encode: false })

let LoginDataRedux;
if (getCookieData) {
    const LoginCookie = convertDecrypted(getCookieData)
    if (LoginCookie !== undefined) {
        const userLogData = LoginCookie
        LoginDataRedux = {
            refereshToken: userLogData?.refereshToken,
            accessToken: userLogData?.accessToken,
            userData: {
                email: userLogData?.userData?.email,
                role: userLogData?.userData?.role,
            },
        }
    }
}

const initialState = {
    loginData: LoginDataRedux || null,
    isLogin: !!LoginDataRedux || false,
};

export const authenticationSlice = createSlice({
    name: "authentication",
    initialState,
    reducers: {
        setAuthentication: (state, actions) => {
            state.isLogin = true;
            state.loginData = actions.payload
        },

        removeAuthentication: (state) => {
            Cookies.remove('accessControl')
            state.isLogin = null
            state.loginData = null
        }
    }
})


export const { setAuthentication, removeAuthentication } = authenticationSlice.actions;
export default authenticationSlice.reducer
