import React, { useState, useEffect } from 'react'
import DataTableCom from '../../../components/datatable/datatablecom'
import { Button, Image, Modal } from 'react-bootstrap'
import MainTitle from '../../../components/mainTitle/mainTitle'
import CustomLoader from '../../../components/loader/loader'
import { SingleEvent_Data } from '../../../services/getServices'
import { Link, useParams } from 'react-router-dom'
import CustomButton from '../../../components/Buttons/custom/CustomButton'
import { BsQrCodeScan } from 'react-icons/bs'
import { MdOutlineCheckBoxOutlineBlank } from 'react-icons/md'
import QRCode from 'qrcode';
import { FaCheckSquare, FaFileExport, FaLinkedin } from 'react-icons/fa'
import csvDownload from 'json-to-csv-export'
import BackButton from '../../../components/Buttons/custom/BackButton'
import SloatForm from './SloatForm'
import { SweetConfirm, SweetAlertBox } from '../../../components/alert/sweetAlertPop'
import { DeleteEventFormData, EditEventFormData, EventConfirmationMail } from '../../../services/postServices'
import { FcFeedback, FcFullTrash } from 'react-icons/fc'
import { useSelector } from 'react-redux';
import { selectorUserRole } from '../../../redux/selector';
import { projectUrl, roleSuperUser } from '../../../utils/globalVariable';


const Slots = () => {

    const userRole = useSelector(selectorUserRole);
    const [slotsData, setSlotsData] = useState([]);
    const [eventData, setEventData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [exportCSVData, setExportCSVData] = useState(null)
    const { eventId } = useParams();
    const [qrCode, setQrCode] = useState(null);


    const handleClose = () => setQrCode(null);

    const handleRemoveRecord = async (userId) => {
        try {
            SweetConfirm('Delete Record', 'Confirm deletion of selected item?', 'Delete', async () => {
                const res = await DeleteEventFormData(eventId, userId);
                if (res.status === 200) {
                    SweetAlertBox('success', 'Deleted', 'That record is deleted successfully.', () => fetchSlotsData())
                }
                else {
                    SweetAlertBox('error', 'Error Occurred', 'An unexpected error has occurred. Please try again later', () => { })
                }
            })
        }
        catch (err) {
            console.error(err)
        }
    }

    const exportCSVFun = (resData) => {
        let exportData = []
        const csvHeader = ["No", "Firstname", "Lastname", "Email Address", "Mobile", "City", "Company", "Designation", "LinkedIn"]
        resData.formMembers.map((item, index) => (
            exportData = [
                ...exportData,
                {
                    no: index + 1,
                    firstname: item.firstName,
                    lastname: item.lastName,
                    email: item.email,
                    mobile: item.mobile,
                    city: item.city,
                    company: item.company,
                    designation: item.designation,
                    linkedin: item.linkedIn,
                }
            ]
        ))
        const export_data = {
            data: exportData,
            filename: resData.eventTitle,
            delimiter: ',',
            headers: csvHeader
        }
        setExportCSVData(export_data)
    }

    const handelEventStatus = async (userId, eventStatus) => {
        try {
            await EditEventFormData(eventId, userId, { eventStatus: eventStatus })
            fetchSlotsData()
        }
        catch (e) {
            console.error(e)
        }
    }

    const columns = [
        {
            name: 'No',
            selector: (row, index) => index + 1,
            grow: 0,
            sortable: true,
        },
        {
            name: 'Attendence',
            button: true,
            grow: 0,
            cell: (item) => {
                return (
                    <div className="d-flex gap-3 align-items-center">
                        {
                            item.eventStatus ?
                                <FaCheckSquare size={20} color='#09aa5e' onClick={() => handelEventStatus(item._id, false)} className="cursor-pointer" />
                                : <MdOutlineCheckBoxOutlineBlank size={24} color='#09aa5e' onClick={() => handelEventStatus(item._id, true)} className="cursor-pointer" />
                        }
                    </div>
                )
            }
        },
        {
            name: 'Name',
            selector: (row) => row.firstName + " " + row.lastName,
            sortable: true,
            grow: 0.5,
        },
        {
            name: 'Email Address',
            selector: (row) => row.email,
            sortable: true,
        },
        // {
        //     name: 'Mobile',
        //     selector: (row) => row.mobile,
        //     sortable: true,
        //     grow: 0.5,
        // },
        // {
        //     name: 'Company',
        //     selector: (row) => row.company,
        //     sortable: true,
        // },
        // {
        //     name: 'Designation',
        //     selector: (row) => row.designation,
        //     sortable: true,
        // },
        {
            name: 'Linkedin',
            selector: (row) => <Link to={row.linkedIn} target="_blank"><FaLinkedin fontSize={25} /></Link>,
            sortable: true,
            grow: 0.5,
        },
        {
            name: 'Role',
            selector: (row) => row.role,
            grow: 0.5,
            sortable: true,
        },
        {
            name: 'Actions',
            button: true,
            grow: 3,
            cell: (item) => {
                return (
                    <div className="d-flex gap-3">
                        {
                            !item.emailSendStatus ?
                                <button className="actionEdit" onClick={() => sendConfirmMail(eventData._id, item)} title='Send Mail'>
                                    <FcFeedback size={20} />
                                </button> : ""
                        }
                        <SloatForm eventData={eventData._id} sloatId={item._id} refreshData={() => fetchSlotsData()} />
                        {
                            userRole && userRole === roleSuperUser ?
                                <button className="actionDelete" onClick={() => handleRemoveRecord(item._id)} title='delete'>
                                    <FcFullTrash size={20} />
                                </button> : ""
                        }
                    </div>
                )
            }
        }
    ];

    const sendConfirmMail = async (eventId, userData) => {
        try {
            const emailData = {
                eventId: eventId,
                userId: userData._id,
                firstName: userData.firstName,
                lastName: userData.lastName,
                email: userData.email,
                eventTitle: eventData.eventTitle,
                date: eventData.date,
                time: eventData.startTime + " " + eventData.endTime,
                venue: eventData.venue,
                contactDetails: "+91 8884266639",
            }
            setLoading(true)
            await EventConfirmationMail(emailData)
            setTimeout(() => {
                setLoading(false)
                fetchSlotsData()
            }, 10000);
        }
        catch (e) {
            console.error(e)
        }
    }

    // fetch all events data
    const fetchSlotsData = async () => {
        setLoading(true);
        try {
            const res = await SingleEvent_Data(eventId);
            setEventData(res.data.data)
            setSlotsData(res.data.data.formMembers)
            exportCSVFun(res.data.data)
            setLoading(false);
        }
        catch (err) {
            console.error(err)
        }
    }


    // generate and Show event qr code
    const generateShowQRCode = async (event_id) => {
        const qrCodeText = `${projectUrl}/event/${event_id}/bookslot`;

        try {
            const qrCodeDataUrl = await QRCode.toDataURL(qrCodeText, {
                errorCorrectionLevel: 'H',
                type: 'image/jpeg',
                quality: 1,
                margin: 1,
                width: 300,
            });
            setQrCode(qrCodeDataUrl)
        } catch (error) {
            console.error('Error generating and downloading QR code:', error);
        }
    };

    // Download QR Code
    const generateDownloadQRCode = (qrCodeDataUrl) => {
        const downloadLink = document.createElement('a');
        downloadLink.href = qrCodeDataUrl;
        downloadLink.download = `${eventData.eventTitle}_qr_code.jpg`;
        downloadLink.click();
        handleClose();
    }


    useEffect(() => {
        if (eventId !== "") {
            fetchSlotsData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventId])

    return (
        <div>
            <CustomLoader show={loading} />

            <div className="d-flex flex-wrap justify-content-between w-100 py-4 px-3">
                <div className="d-flex align-items-center justify-content-between gap-3">
                    <BackButton />
                    <MainTitle heading={"Slots Member Data"} textAlign={"center"} />
                </div>
                <div className="d-flex gap-3">
                    <SloatForm eventData={eventData} refreshData={() => fetchSlotsData()} />
                    {
                        eventId && <CustomButton buttonText={<BsQrCodeScan />} type={"button"} onClick={() => generateShowQRCode(eventId)} />
                    }
                    {
                        exportCSVData && <CustomButton className={'ms-2'} buttonText={<FaFileExport />} type={"button"} onClick={() => csvDownload(exportCSVData)} />
                    }
                </div>
            </div>

            <div className='pb-5'>

                <DataTableCom
                    columns={columns}
                    data={slotsData}
                    refreshData={fetchSlotsData}
                />
            </div>

            {
                qrCode && eventData && qrCode !== null ?
                    (
                        <Modal show={true} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>QR Code</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='text-center'>
                                    <Image
                                        src={qrCode}
                                        alt="QR Code is not found"
                                        width={"200px"}
                                        height={"200px"}
                                    />
                                    <p className='mt-4 fw-bold'>{eventData.eventTitle} QR Code</p>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary" onClick={handleClose}>Close</Button>
                                <Button variant="outline-primary" onClick={() => generateDownloadQRCode(qrCode)}>Download</Button>
                            </Modal.Footer>
                        </Modal>
                    ) : ''
            }
        </div>
    )
}

export default Slots