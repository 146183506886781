import React, { useEffect, useState } from 'react'
import { AllClientFeedback_Data } from '../../services/getServices'
import { Col, Row } from 'react-bootstrap'
import CustomLoader from '../../components/loader/loader'
import CustomButton from '../../components/Buttons/custom/CustomButton'
import MainTitle from '../../components/mainTitle/mainTitle'
import HRFeedbackForm from './ClientFeedbackForm'
import { BiRefresh } from 'react-icons/bi'
import { DeleteClient_Feedback_Data } from '../../services/postServices'
import { SweetConfirm, SweetAlertBox } from '../../components/alert/sweetAlertPop'
import { FcEditImage, FcFullTrash } from 'react-icons/fc'
import { useSelector } from 'react-redux'
import { selectorUserRole } from '../../redux/selector'
import { IMAGE_DEFAULT_AVATER } from '../../assets'
import { roleSuperUser } from '../../utils/globalVariable'
import './clientFeedback.css'


const ClientFeedback = () => {
  const [loading, setLoading] = useState(false);
  const [feedbackData, setFeedbackData] = useState([])
  const [clientFeedbackId, setClientFeedbackId] = useState("");
  const userRole = useSelector(selectorUserRole);
  const [show, setShow] = useState(false);

  // const router = useRouter()
  const getFeedBackData = async () => {
    setLoading(true)
    try {
      const res = await AllClientFeedback_Data();
      if (res.status === 200) {
        setFeedbackData(res.data.data)
      }
    } catch (e) {
      console.log(e)
    }
    finally {
      setLoading(false)
    }
  }

  const handleAction = (id) => {
    setShow(true)
    if (id !== "") {
      setClientFeedbackId(id)
    }
    else {
      setClientFeedbackId("")
    }
  }

  const deleteClientFeedback = (id) => {
    try {
      SweetConfirm('Delete Record', 'Confirm deletion of selected item?', 'Delete', async () => {
        const res = await DeleteClient_Feedback_Data(id);
        if (res.status === 200) {
          SweetAlertBox('success', 'Deleted', 'That record is deleted successfully.', () => getFeedBackData())
        }
        else {
          SweetAlertBox('error', 'Error Occurred', 'An unexpected error has occurred. Please try again later', () => getFeedBackData())
        }
      })
    } catch (err) {
      console.error(err)
    }
  }


  useEffect(() => {
    getFeedBackData();
  }, [])


  return (
    <div className='clientFeedbackComponent'>
      <CustomLoader show={loading} />

      <div className='py-4 px-5 d-flex justify-content-between align-items-center'>
        <CustomButton className={'me-2'} buttonText={<BiRefresh fontSize={"20px"} />} type={"button"} onClick={getFeedBackData} />
        <MainTitle heading={"Client Feedbacks"} textAlign={"center"} />
        <CustomButton type="button" buttonText="Add" onClick={() => handleAction("")} />
        {
          show && show ? <HRFeedbackForm handleShow={show} handleClose={() => { setShow(false); getFeedBackData() }} feebackId={clientFeedbackId} /> : ""
        }
      </div>

        

      <div className="allDataTable clientFeedbackContainer w-100 rounded">
        <div className="layoutsComponents">
          <Row className="row-cols-1" md={2} xl={3} xxl={4}>
            {
              feedbackData && feedbackData.length !== 0 ? (
                feedbackData.map((item) => (
                  <Col key={item.id}>
                    <div className="allDataTable gridCard p-4 rounded bg-white d-flex flex-column justify-content-start align-items-center gap-3">
                      <div className="imageBox shadow p-2">
                        <img
                          src={item.profile}
                          alt="not found"
                          className="image"
                          onError={(e) => (e.target.src = IMAGE_DEFAULT_AVATER)}
                        />
                      </div>
                      <div className="my-3 text-center">
                        <div className="text-center text-danger mb-3">
                          <span className="fw-bold fs-5">{item.role}</span>
                        </div>
                        <h5 className="text-capitalize">
                          {item.firstName} {item.lastName}
                        </h5>
                        <h6 className="text-capitalize">{item.designation}</h6>
                        <p className="text-lowercase">{item.role}</p>
                        <p className="text-lowercase">{item.rating}</p>
                      </div>

                      <div className="participateActionControls">
                        <button className="actionEdit mx-1" onClick={() => handleAction(item._id)}>
                          <FcEditImage size={20} />
                        </button>
                        {
                          userRole && userRole === roleSuperUser ?
                            <button className="actionDelete mx-1" onClick={() => deleteClientFeedback(item._id)}>
                              <FcFullTrash size={20} />
                            </button>
                            : ""
                        }
                      </div>
                    </div>
                  </Col>
                ))
              ) : (
                <div className="text-center w-100">
                  <h2 className='text-secondary m-0'>No team members found</h2>
                </div>
              )
            }
          </Row>
        </div>
      </div>
    </div>
  )
}

export default ClientFeedback