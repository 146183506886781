import React, { useEffect, useState } from 'react'
import CustomLoader from '../../components/loader/loader'
import { Col, Container, Row, Form } from 'react-bootstrap'
import BackButton from '../../components/Buttons/custom/BackButton'
import MainTitle from '../../components/mainTitle/mainTitle'
import CustomButton from '../../components/Buttons/custom/CustomButton'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import FormikInput from '../../components/filedbox/formikInput'
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter, FaWhatsapp } from 'react-icons/fa'
import ChooseImageInput from '../../components/filedbox/chooseImage/ChooseImage'
import { NewChapter_Data } from '../../services/postServices'
import { SweetAlertBox } from '../../components/alert/sweetAlertPop'
import { SingleChapters_Data } from '../../services/getServices'

const ChapterForm = () => {

    const initialValues = {
        src: "",
        cityName: "",
        path: "",
        tagLine: "",
        chapterName: "",
        whatsappLink: "",

        // bannerImage: "",
        socialMediaDescription: "",
        chapterLinkedIn: "",
        chapterFacebook: "",
        chapterTwitter: "",
        chapterInstagram: "",

        leaderImage: "",
        leaderName: "",
        leaderDescription: "",
        leaderLinkedIn: "",
        leaderFacebook: "",
        leaderTwitter: "",
        leaderInstagram: "",
    }

    // all useState declare here
    const [chapterData, setChapterData] = useState(initialValues)
    const [chapterImage, setChapterImage] = useState(null);
    // const [socialImage, setSocialImage] = useState(null);
    const [leaderImage, setLeaderImage] = useState(null);
    const [loading, setLoading] = useState(false);


    // all variables declare here
    const { cid } = useParams();
    const navigate = useNavigate();
    const currentPath = useLocation()
    const checkUrls = currentPath.pathname.split('/')

    // all funcation declare here
    const openSocialMedia = (url) => {
        window.open(url, '_blank')
    }

    const onSubmit = async () => {
        if (checkUrls.includes('edit') || checkUrls.includes('create')) {
            try {
                const formData = new FormData();

                // chapter details
                formData.append("src", chapterImage)
                formData.append("cityName", formik.values.cityName)
                formData.append("path", formik.values.path)
                formData.append("tagLine", formik.values.tagLine)
                formData.append("chapterName", formik.values.chapterName)
                formData.append("whatsAppLink", formik.values.whatsappLink)

                // chapter social media details
                formData.append("details[socialMedia][socialMediaDescription]", formik.values.socialMediaDescription)
                formData.append("details[socialMedia][linkedIn]", formik.values.chapterLinkedIn)
                formData.append("details[socialMedia][twitter]", formik.values.chapterTwitter)
                formData.append("details[socialMedia][instagram]", formik.values.chapterInstagram)
                formData.append("details[socialMedia][facebook]", formik.values.chapterFacebook)

                // leader details
                formData.append("leaderImage", leaderImage);
                formData.append("details[leaderDetails][leaderName]", formik.values.leaderName)
                formData.append("details[leaderDetails][leaderDescription]", formik.values.leaderDescription)
                formData.append("details[leaderDetails][linkedIn]", formik.values.leaderLinkedIn)
                formData.append("details[leaderDetails][twitter]", formik.values.leaderTwitter)
                formData.append("details[leaderDetails][instagram]", formik.values.leaderInstagram)
                formData.append("details[leaderDetails][facebook]", formik.values.leaderFacebook)

                const res = await NewChapter_Data(formData)
                if (res.status === 200) {
                    SweetAlertBox("success", "Create Chapter", `${formik.values.chapterName} is create successfully`, () => {
                        navigate('/chapter')
                    })
                }
                else {
                    SweetAlertBox("error", "Not Create Chapter", `Sorry! ${formik.values.chapterName} is not created.`, () => {
                        navigate('/chapter')
                    })
                }
            }
            catch (err) {
                console.error(err)
            }
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: chapterData,
        // validationSchema: validationSchemaEventForm,
        onSubmit,
    });

    // fetch chapters data
    const fetchChaptersData = async (chapterPath) => {
        try {
            setLoading(true);
            const res = await SingleChapters_Data(chapterPath);
            const resChapter = res.data.data[0]
            console.log(resChapter)
            const setData = {
                src: resChapter.src,
                cityName: resChapter.chapterName,
                path: resChapter.path,
                tagLine: resChapter.tagLine,
                chapterName: resChapter.chapterName,
                whatsappLink: resChapter.whatAppLink,

                // bannerImage: "",
                socialMediaDescription: resChapter.details.socialMedia.socialMediaDescription,
                chapterLinkedIn: resChapter.details.socialMedia.linkedIn,
                chapterFacebook: resChapter.details.socialMedia.facebook,
                chapterTwitter: resChapter.details.socialMedia.twitter,
                chapterInstagram: resChapter.details.socialMedia.instagram,

                leaderImage: resChapter.details.leader.leaderImage,
                leaderName: resChapter.details.leader.leaderName,
                leaderDescription: resChapter.details.leader.leaderDescription,
                leaderLinkedIn: resChapter.details.leader.linkedIn,
                leaderFacebook: resChapter.details.leader.twitter,
                leaderTwitter: resChapter.details.leader.instagram,
                leaderInstagram: resChapter.details.leader.facebook,
            }
            console.log(setData)
            setChapterData(setData);
        }
        catch (err) {
            console.error(err);
        }
        finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (cid !== undefined) {
            fetchChaptersData(cid);
        }
    }, [cid]);

    return (
        <div>
            <CustomLoader show={loading} />

            <Container>
                <Row className='py-4 '>
                    <Col sm={2}>
                        <BackButton />
                    </Col>
                    <Col sm={8}>
                        <MainTitle
                            heading={checkUrls.includes('edit') || checkUrls.includes('create') ? cid && cid !== undefined ? "Edit Chapter" : "New Chapter" : "View Chapter"}
                            textAlign={"center"}
                        />
                    </Col>
                    <Col sm={2}>
                        {
                            checkUrls.includes('view') ? <CustomButton type="button" buttonText="edit" onClick={() => navigate(`/chapter/${''}/edit/`)} /> : ""
                        }
                    </Col>
                </Row>

                <Form onSubmit={formik.handleSubmit} className="poolForm mb-5">
                    <Container>
                        <div className="allDataTable p-4 my-4 rounded">
                            <Row>
                                <Col>
                                    <ChooseImageInput
                                        image="banner"
                                        imageName="src"
                                        formik={formik}
                                        imageState={{ chooseImage: chapterImage, setChooseImage: setChapterImage }}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col className="col-12 col-md-6">
                                    <FormikInput
                                        fieldName="Chapter Name"
                                        type="text"
                                        as="input"
                                        name="chapterName"
                                        disabled={checkUrls.includes('edit') || checkUrls.includes('create') ? false : true}
                                        placeholder="enter chapter name"
                                        formik={formik}
                                    />
                                </Col>
                                <Col className="col-12 col-md-6">
                                    <FormikInput
                                        fieldName="City Name"
                                        type="text"
                                        as="input"
                                        name="cityName"
                                        disabled={checkUrls.includes('edit') || checkUrls.includes('create') ? false : true}
                                        placeholder="enter city name"
                                        formik={formik}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormikInput
                                        fieldName="Chapter Tag Line"
                                        type="text"
                                        as="input"
                                        name="TagLine"
                                        disabled={checkUrls.includes('edit') || checkUrls.includes('create') ? false : true}
                                        placeholder="enter chapter name"
                                        formik={formik}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormikInput
                                        fieldName="Whatsapp Link"
                                        type="text"
                                        as="input"
                                        name="whatsappLink"
                                        disabled={checkUrls.includes('edit') || checkUrls.includes('create') ? false : true}
                                        placeholder="enter chapter whatsapp url"
                                        showUrl={<FaWhatsapp fontSize={24} onClick={() => openSocialMedia(formik.values.whatsappLink)} />}
                                        formik={formik}
                                    />
                                </Col>
                            </Row>
                        </div>

                        <div className="allDataTable p-4 my-4 rounded">
                            <Row>
                                <Col>
                                    <h4 className='fw-bold mb-4'>Chapter Social Media Details</h4>
                                </Col>
                            </Row>
                            {/* <Row>
                                <Col>
                                    <ChooseImageInput
                                        image='banner'
                                        imageName={"bannerImage"}
                                        formik={formik}
                                        imageState={{ chooseImage: socialImage, setChooseImage: setSocialImage }}
                                    />
                                </Col>
                            </Row> */}

                            <Row>
                                <Col>
                                    <FormikInput
                                        fieldName="Description"
                                        type="text"
                                        as="textarea"
                                        name="socialMediaDescription"
                                        disabled={checkUrls.includes('edit') || checkUrls.includes('create') ? false : true}
                                        placeholder="enter social media description"
                                        formik={formik}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col className='col-12 col-md-6'>
                                    <FormikInput
                                        fieldName="LinkedIn"
                                        type="text"
                                        as="input"
                                        name="chapterLinkedIn"
                                        disabled={checkUrls.includes('edit') || checkUrls.includes('create') ? false : true}
                                        placeholder="enter chapter linkedin url"
                                        showUrl={<FaLinkedin fontSize={24} onClick={() => openSocialMedia(formik.values.chapterLinkedIn)} />}
                                        formik={formik}
                                    />
                                </Col>
                                <Col className='col-12 col-md-6'>
                                    <FormikInput
                                        fieldName="Facebook"
                                        type="text"
                                        as="input"
                                        name="chapterFacebook"
                                        disabled={checkUrls.includes('edit') || checkUrls.includes('create') ? false : true}
                                        placeholder="enter chapter facebook url"
                                        showUrl={<FaFacebook fontSize={24} onClick={() => openSocialMedia(formik.values.chapterFacebook)} />}
                                        formik={formik}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col className='col-12 col-md-6'>
                                    <FormikInput
                                        fieldName="Twitter"
                                        type="text"
                                        as="input"
                                        name="chapterTwitter"
                                        disabled={checkUrls.includes('edit') || checkUrls.includes('create') ? false : true}
                                        placeholder="enter chapter twitter url"
                                        showUrl={<FaTwitter fontSize={24} onClick={() => openSocialMedia(formik.values.chapterTwitter)} />}
                                        formik={formik}
                                    />
                                </Col>
                                <Col className='col-12 col-md-6'>
                                    <FormikInput
                                        fieldName="Instagram"
                                        type="text"
                                        as="input"
                                        name="chapterInstagram"
                                        disabled={checkUrls.includes('edit') || checkUrls.includes('create') ? false : true}
                                        placeholder="enter chapter instagram url"
                                        showUrl={<FaInstagram fontSize={24} onClick={() => openSocialMedia(formik.values.chapterInstagram)} />}
                                        formik={formik}
                                    />
                                </Col>
                            </Row>
                        </div>

                        <div className="allDataTable p-4 my-4 rounded">
                            <Row>
                                <Col>
                                    <h4 className='fw-bold mb-4'>Chapter Leader Details</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <ChooseImageInput
                                        image="profile"
                                        imageName={"leaderImage"}
                                        formik={formik}
                                        imageState={{ chooseImage: leaderImage, setChooseImage: setLeaderImage }}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <FormikInput
                                        fieldName="Leader Name"
                                        type="text"
                                        as="input"
                                        name="leaderName"
                                        disabled={checkUrls.includes('edit') || checkUrls.includes('create') ? false : true}
                                        placeholder="enter leader name"
                                        formik={formik}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <FormikInput
                                        fieldName="Leader Description"
                                        type="text"
                                        as="textarea"
                                        name="leaderDescription"
                                        disabled={checkUrls.includes('edit') || checkUrls.includes('create') ? false : true}
                                        placeholder="enter social media description"
                                        formik={formik}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col className='col-12 col-md-6'>
                                    <FormikInput
                                        fieldName="LinkedIn"
                                        type="text"
                                        as="input"
                                        name="leaderLinkedIn"
                                        disabled={checkUrls.includes('edit') || checkUrls.includes('create') ? false : true}
                                        placeholder="enter leader linkedin url"
                                        showUrl={<FaLinkedin fontSize={24} onClick={() => openSocialMedia(formik.values.leaderLinkedIn)} />}
                                        formik={formik}
                                    />
                                </Col>
                                <Col className='col-12 col-md-6'>
                                    <FormikInput
                                        fieldName="Facebook"
                                        type="text"
                                        as="input"
                                        name="leaderFacebook"
                                        disabled={checkUrls.includes('edit') || checkUrls.includes('create') ? false : true}
                                        placeholder="enter leader facebook url"
                                        showUrl={<FaFacebook fontSize={24} onClick={() => openSocialMedia(formik.values.leaderFacebook)} />}
                                        formik={formik}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col className='col-12 col-md-6'>
                                    <FormikInput
                                        fieldName="Twitter"
                                        type="text"
                                        as="input"
                                        name="leaderTwitter"
                                        disabled={checkUrls.includes('edit') || checkUrls.includes('create') ? false : true}
                                        placeholder="enter leader twitter url"
                                        showUrl={<FaTwitter fontSize={24} onClick={() => openSocialMedia(formik.values.leaderTwitter)} />}
                                        formik={formik}
                                    />
                                </Col>
                                <Col className='col-12 col-md-6'>
                                    <FormikInput
                                        fieldName="Instagram"
                                        type="text"
                                        as="input"
                                        name="leaderInstagram"
                                        disabled={checkUrls.includes('edit') || checkUrls.includes('create') ? false : true}
                                        placeholder="enter leader instagram url"
                                        showUrl={<FaInstagram fontSize={24} onClick={() => openSocialMedia(formik.values.leaderInstagram)} />}
                                        formik={formik}
                                    />
                                </Col>
                            </Row>
                        </div>

                        {
                            checkUrls.includes('edit') || checkUrls.includes('create') ?
                                <div className="allDataTable p-4 my-4 rounded">
                                    <div className="text-center py-2">
                                        <CustomButton buttonText={"Submit"} type={"submit"} />
                                    </div>
                                </div> : ""
                        }
                    </Container>
                </Form>
            </Container>
        </div>
    )
}

export default ChapterForm