import React from 'react'
import { IMAGE_HRC_LOGO, IMAGE_ISC_LOGO } from '../../assets'
import { Image } from 'react-bootstrap'
import MainTitle from '../../components/mainTitle/mainTitle'
import CustomButton from '../../components/Buttons/custom/CustomButton'
import { useNavigate } from 'react-router-dom'
import { checkISC, projectName } from '../../utils/globalVariable'
// import { useAppSelector } from '../../redux/hooks'
// import { isLogin } from '../../redux/authSlice'

const PageNotFound = () => {
    const navigate = useNavigate();

    return (
        <div className='loginContainer'>
            <div className='loginForm'>
                <div className="logo">
                    <div className="logoBox">
                        {
                            projectName && projectName === checkISC ?
                                <Image
                                    src={IMAGE_ISC_LOGO}
                                    className="h-100 w-100 py-3"
                                    alt="logo not found"
                                />
                                :
                                <Image
                                    src={IMAGE_HRC_LOGO}
                                    className="h-100 w-100 py-3"
                                    alt="logo not found"
                                />
                        }
                    </div>

                    <hr className="text-secondary" />

                    <div className='d-flex justify-content-center align-items-center py-5'>
                        <div className='text-center'>
                            <MainTitle heading={"404 Page Not Found"} textAlign={"center"} />

                            <div className='mt-5'>
                                <CustomButton
                                    type="button"
                                    buttonText="Go To Home"
                                    onClick={() => navigate('/')}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default PageNotFound