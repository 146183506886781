import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { BiHide, BiShow } from 'react-icons/bi'
import './formikfiled.css'
import { FaLinkedin } from 'react-icons/fa'

const FormikInput = ({ fieldName, type, name, placeholder, as, disabled = false, autoFocus = false, formik, extra, row, showUrl }) => {
    const [showHidePs, setShowHidePs] = useState('Hide')

    const hideShowPs = () => {
        if (showHidePs === 'Hide') {
            setShowHidePs('Show')
        }
        else {
            setShowHidePs('Hide')
        }
    }

    return (
        <div className='my-3'>
            <Form.Group className="position-relative">
                <Form.Label className="fw-bold mx-1 text-capitalize d-flex justify-content-between align-items-center">
                    {fieldName}
                </Form.Label>
                <Form.Control
                    type={
                        type === "password" ?
                            showHidePs === "Hide" ?
                                'password' : 'text'
                            : type
                    }
                    as={as}
                    name={name}
                    placeholder={placeholder}
                    disabled={disabled}
                    autoFocus={autoFocus}
                    row={row}
                    value={formik.values[name]}
                    {...formik.getFieldProps(name)}
                />

                {
                    extra && extra.length !== 0 ? (
                        <span className='example'>{extra}</span>
                    ) : null
                }

                {
                    type && type === "password" ?
                        showHidePs && showHidePs === 'Hide' ?
                            <BiHide className='passwordIcon' onClick={hideShowPs} fontSize={24}/>
                            :
                            <BiShow className='passwordIcon' onClick={hideShowPs} fontSize={24}/>
                        : ''
                }

                {
                    showUrl !== '' ?
                        <div className="fieldIconBox">
                            {showUrl}
                        </div>
                        : ""
                }
            </Form.Group>
            {
                formik.touched[name] && formik.errors[name] ? (
                    <p className="text-danger fw-bold mt-1">{formik.errors[name]}</p>
                ) : null
            }
        </div>
    )
}

export default FormikInput