import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import MainTitle from "../../components/mainTitle/mainTitle";
import DeleteButton from "../../components/Buttons/deleteButton/deleteButton";
import CustomLoader from "../../components/loader/loader";
import { AllAdmin_Data } from "../../services/getServices";
import NewAdmin from "./newAdmin";
import { DELETE_ADMIN_API } from "../../constant/api";
import DataTableCom from "../../components/datatable/datatablecom";
import EditAdmin from "./EditAdmin";
import { useSelector } from 'react-redux';
import { selectorUserRole } from "../../redux/selector";
import { roleSuperUser } from "../../utils/globalVariable";

const AdminPage = () => {

    const [adminData, setAdminData] = useState([]);
    const [adminFetch, setAdminFetch] = useState('otp');
    const [loading, setLoading] = useState(false);
    const userRole = useSelector(selectorUserRole);

    const columns = [
        {
            name: "No",
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: "Email Address",
            selector: (row) => row.email,
            sortable: true,
            grow: 4,
        },
        {
            name: "Role",
            selector: (row) => row.role,
            sortable: true,
            grow: 4,
        },
        {
            name: "Actions",
            button: true,
            grow: 5,
            cell: (item) => {
                return (
                    <div className="d-flex gap-3">
                        <EditAdmin
                            submitForm={() => fetchAllAdminData()}
                            email={item.email}
                            role={item.role}
                            setData={adminFetch || 'adminOtp'}
                        />
                        {
                            userRole && userRole === roleSuperUser ?
                                adminFetch && adminFetch === 'adminOtp' ?
                                    <DeleteButton
                                        Id={item._id}
                                        apiRoute={DELETE_ADMIN_API}
                                        handleBack={() => {
                                            fetchAllAdminData();
                                        }}
                                    />
                                    : ""
                                : ""
                        }
                    </div>
                );
            },
        },
    ];

    // fetch all events data
    const fetchAllAdminData = async (e) => {
        setLoading(true);
        try {
            const res = await AllAdmin_Data();
            if (e?.target?.value === "adminPassword") {
                setAdminFetch('adminPassword')
                setAdminData(res.data.data.adminPassword);
            }
            else {
                setAdminFetch('adminOtp')
                setAdminData(res.data.data.adminOtp);
            }
            setLoading(false);
        } catch (err) {
            console.error(err);
        }
    };


    useEffect(() => {
        fetchAllAdminData();
    }, []);

    return (
        <div>
            <CustomLoader show={loading} />

            <div className="d-flex flex-wrap justify-content-center justify-content-sm-between align-items-center w-100 pb-4 px-3 gap-3">
                <MainTitle heading={"Admin Members"} textAlign={"center"} />
                <NewAdmin open={"open"} submitForm={() => fetchAllAdminData()} />
            </div>

            <div className="pb-5">
                <DataTableCom
                    columns={columns}
                    data={adminData}
                    refreshData={fetchAllAdminData}
                    dataList={[
                        {
                            label: "Admin OTP",
                            value: "adminOtp"
                        },
                        {
                            label: "Admin Password",
                            value: "adminPassword"
                        },
                    ]}
                    dropdownChangeValue={fetchAllAdminData}
                />
            </div>
        </div>
    );
};

export default AdminPage;
