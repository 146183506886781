import "./login.css";
import MainTitle from "../../components/mainTitle/mainTitle";
import { IMAGE_HRC_LOGO, IMAGE_ISC_LOGO } from "../../assets/index"
import { checkISC, projectName } from "../../utils/globalVariable";
import LoginPassword from "./loginPassword";
import LoginOtp from "./loginOtp";
import {Image, Tab, Tabs } from "react-bootstrap";

const Login = () => {
    return (
        <div className="loginContainer">
            <div className="loginForm">
                <div className="logoBox">
                    {
                        projectName && projectName === checkISC ?
                            <Image
                                src={IMAGE_ISC_LOGO}
                                className="h-100 w-100 py-3"
                                alt="logo not found"
                            />
                            :
                            <Image
                                src={IMAGE_HRC_LOGO}
                                className="h-100 w-100 py-3"
                                alt="logo not found"
                            />
                    }
                </div>

                <hr className="text-secondary" />

                <MainTitle heading={"Login"} textAlign={"center"} />

                <div className="loginTabs">
                    <Tabs defaultActiveKey="passwordLogin" className="mb-3" fill>
                        <Tab eventKey="passwordLogin" title="Password Login">
                            <LoginPassword />
                        </Tab>
                        <Tab eventKey="otp" title="OTP Login">
                            <LoginOtp />                            
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    );
};

export default Login;