import * as yup from "yup";


export const validationSchemaEventForm = yup.object({
    eventTitle: yup.string().required("enter event title"),
    date: yup.string().required("enter event date"),
    startTime: yup.string().required("enter event start time"),
    endTime: yup.string().required("enter event end time"),
    venue: yup.string().required("enter event venue"),
    location: yup.string().required("enter event location"),
    venueMapLink: yup.string().required("enter event map link"),
    slots: yup.number().required("enter event slots"),
    entryFees: yup.string().required("enter entry fees"),
    forInvite: yup.string().required("enter for Invite"),
    keynoteSpeakers: yup.string(),
    moderator: yup.string(),
    objective: yup.string(),
    organizedBy: yup.string(),
});


export const validationSchemaMailBox = yup.object({
    to: yup
        .string()
        .required("enter your send mail address"),

    subject: yup
        .string()
        .required("enter your mail subject"),

    message: yup
        .string()
        .required("enter your message"),
})


export const validationSchemaAdminOtp = yup.object({
    email: yup
        .string()
        .matches(/(@prydan\.com|\.prydan@gmail\.com)$/, { message: "Only @prydan.com or .prydan@gmail.com domains are allowed.", excludeEmptyString: true })
        .required("enter your email address"),

    role: yup
        .string()
        .required("select your role")
});

export const validationSchemaAdminPassword = yup.object({
    password: yup
        .string()
        .required('enter your password')
        .min(8, 'Password must be 8 characters long')
        .matches(/[0-9]/, 'Password requires a number')
        .matches(/[a-z]/, 'Password requires a lowercase letter')
        .matches(/[A-Z]/, 'Password requires an uppercase letter')
        .matches(/[^\w]/, 'Password requires a symbol'),

    role: yup
        .string()
        .required("select your role")
});


export const validationSchemaClientFeedback = yup.object({
    firstName: yup.string().required("enter firstname"),
    lastName: yup.string().required("enter lastname"),
    designation: yup.string().required('enter designation'),
    rating: yup.string().required('enter reting'),
    feedbackTitle: yup.string().required('enter feedback title'),
    feedbackDescription: yup.string().required('enter feedback description'),
})


export const validationSchemaContactUs = yup.object({
    firstName: yup.string().required("enter firstname"),
    lastName: yup.string().required("enter lastname"),
    email: yup.string().email("Invalid email address format").required("enter email address"),
    mobileNumber: yup.string().min(10, 'invalid mobile number').max(10, 'invalid mobile number').matches(/[0-9]/, 'requires a number').required("enter your mobile number"),
    message: yup.string().required('enter message'),
})


export const validationSchemaSloatForm = yup.object({
    firstName: yup.string().required("enter firstname"),
    lastName: yup.string().required("enter lastname"),
    email: yup.string().email("Invalid email address format").required("enter email address"),
    mobile: yup.string().min(10, 'invalid mobile number').max(10, 'invalid mobile number').matches(/[0-9]/, 'requires a number').required("enter your mobile number"),
    // city: yup.string().min(3, 'Invalid city name').required('enter city is required'),
    // company: yup.string().required('enter company'),
    // designation: yup.string().required('enter designation'),
    // linkedIn: yup.string().matches(/^(https?:\/\/)?(www\.)?linkedin\.com\/(in|profile)\/.+$/, 'Invalid LinkedIn profile URL').required("linkedIn Link is required"),
})

export const validationSchemaFeedbackForm = yup.object({
    firstName: yup.string().matches(/^[a-zA-Z\s]+$/, 'First Name must contain only alphabetic characters and white spaces').required('enter your first name').min(2, 'First Name must be at least 2 characters long'),
    lastName: yup.string().matches(/^[a-zA-Z]+$/, 'Last Name must contain only alphabetic characters').required('enter your last name').min(2, 'Last Name must be at least 2 characters long'),
    email: yup.string().email("Invalid email address").required("enter your email address"),
    mobile: yup.string().matches(/^[0-9]+$/, 'mobile number must contain only digits').min(10, 'invalid mobile number').max(10, 'invalid mobile number').required('enter your mobile number'),
    designation: yup.string().required("enter your designation"),
    company: yup.string().required("enter your company name"),
    eventExperience: yup.string().required('enter event experience'),
    improvementSuggestions: yup.string().required('enter provide your suggestions for future events.'),
    eventRating: yup.string().required('Select event rating'),
    speakerPresentation: yup.string().required("rate the speaker's knowledge and presentation."),
    eventEngaging: yup.string(),
    eventVenueFacilitiesTimings: yup.string(),
    eventTopicInterest: yup.string(),
    eventinFormativeValuable: yup.string().required('select event ormative valuable'),
    opportunitiesToConnect: yup.string().required('select oppotunities to network with other attendees'),
    enoughNetworkingBreaks: yup.string(),
    eventOrganizedCommunicated: yup.string(),
    eventParticularlyEnjoy: yup.string(),
    topicsInFutureEvents: yup.string(),
    attendFutureEvents: yup.string(),
})


export const validationSchemaHostForm = yup.object({
    firstName: yup.string().matches(/^[a-zA-Z]+$/, 'First Name must contain only alphabetic characters').required('enter your first name').min(2, 'First Name must be at least 2 characters long'),
    lastName: yup.string().matches(/^[a-zA-Z]+$/, 'Last Name must contain only alphabetic characters').required('enter your last name').min(2, 'Last Name must be at least 2 characters long'),
    email: yup.string().email("Invalid email address").required("enter your email address"),
    mobile: yup.string().matches(/^[0-9]+$/, 'mobile number must contain only digits').min(10, 'invalid mobile number').max(10, 'invalid mobile number').required('enter your mobile number'),
    city: yup.string().required("enter your city"),
    designation: yup.string().required("enter your designation"),
    company: yup.string().required("enter your company name"),
    intrestedToHost: yup.string().required("tell us when you can host"),
    durationOfEvent: yup.string().oneOf(['4-5hours', '8-9hours'], 'select a duration').required('select your duration of event'),
    facilitiesProvide: yup.array().of(yup.string()).min(1, "select at least one facilities provide").required("select at least one facilities provide"),
    attendeesAccommodate: yup.array().of(yup.string()).min(1, "select at least one accommodate").required("select how many people you can accomodate"),
    peopleCommitToParticipatex: yup.string().required("how many people you can commit to bring"),
    pointOfContact1: yup.string().matches(/^[0-9]+$/, 'mobile number must contain only digits').min(10, 'invalid mobile number').max(10, 'invalid mobile number').required('enter your mobile number'),
    pointOfContact2: yup.string().matches(/^[0-9]+$/, 'mobile number must contain only digits').min(10, 'invalid mobile number').max(10, 'invalid mobile number').required('enter your mobile number'),
    pointOfContact3: yup.string().matches(/^[0-9]+$/, 'mobile number must contain only digits').min(10, 'invalid mobile number').max(10, 'invalid mobile number').required('enter your mobile number'),
    completeAddress: yup.string().required("enter your complete address"),
})


export const validationSchemaSpeakerForm = yup.object({
    firstName: yup.string().matches(/^[a-zA-Z\s]+$/, 'First Name must contain only alphabetic characters and white spaces').required('enter your first name').min(2, 'First Name must be at least 2 characters long'),
    lastName: yup.string().matches(/^[a-zA-Z]+$/, 'Last Name must contain only alphabetic characters').required('enter your last name').min(2, 'Last Name must be at least 2 characters long'),
    email: yup.string().email('Invalid email address').required('enter your email address'),
    mobile: yup.string().matches(/^\d+$/, 'Mobile number must contain only digits').min(10, 'Invalid mobile number').max(10, 'Invalid mobile number').required('enter your mobile number'),
    location: yup.string().min(3, 'Invalid city name').required('City is required'),
    designation: yup.string().min(2, 'Invalid designation').required('enter your designation'),
    company: yup.string().required('enter your company name'),
    linkedin: yup.string().matches(/^(https?:\/\/)?(www\.)?linkedin\.com\/(in|profile)\/.+$/, 'Invalid LinkedIn profile URL').required('enter your LinkedIn profile link'),
    event: yup.string().required('enter the event name'),
    biography: yup.string().required('enter your biography'),
    speakerVideo: yup.string(),
    pastSessionLink: yup.string(),
    title: yup.string(),
    synopsis: yup.string(),
    takeaway1: yup.string(),
    takeaway2: yup.string(),
    takeaway3: yup.string(),
    takeaway4: yup.string(),
    duration: yup.string(),
    presentation: yup.string(),
    reasons: yup.string(),
    deliveryplan: yup.string(),
    accept: yup.array().of(yup.string()).required('select at least one acceptance'),
})


export const validationSchemaVolunteerForm = yup.object({
    firstName: yup.string().min(2).required("enter your first name"),
    lastName: yup.string().min(2).required("enter your last name"),
    email: yup.string().email("invalid email address").required("enter your email address"),
    number: yup.string().min(10, 'invalid mobile number').max(10, 'invalid mobile number').matches(/[0-9]/, 'requires a number').required("enter your mobile number"),
    linkedIn: yup.string().required("enter your linkedIn link").matches(/(?:https?:\/\/)?(?:www\.)?linkedin\.com\/(?:\w+\/)?(?:p\/|tv\/)?([^\s/]+)/, 'Invalid linkedIn profile link'),
    occupation: yup.string().required("enter your occupation"),
    experience: yup.string().required("enter your experience"),
    availabity: yup.string(),
    areaOfInterest: yup.array().of(yup.string()).min(1, 'select at least one area of interest').required('select at least one area of interest'),
    preferredVolunteerRole: yup.string(),
    skillsOfVolunteer: yup.string(),
});


export const validationSchemaNewMember = yup.object({
    firstName: yup.string().required("enter firstname"),
    lastName: yup.string().required("enter lastname"),
    email: yup.string().email("Invalid email address format").required("enter email address"),
    mobile: yup.string().min(10, 'invalid mobile number').max(10, 'invalid mobile number').matches(/[0-9]/, 'requires a number').required("enter your mobile number"),
    password: yup.string().min(8, 'Password must be 8 characters long').matches(/[0-9]/, 'Password requires a number').matches(/[a-z]/, 'Password requires a lowercase letter').matches(/[A-Z]/, 'Password requires an uppercase letter').matches(/[^\w]/, 'Password requires a symbol').required('Please, enter your password'),
})


export const validationSchemaEditMemberForm = yup.object({
    firstName: yup.string().required("enter firstname"),
    lastName: yup.string().required("enter lastname"),
    email: yup.string().email("Invalid email address format").required("enter email address"),
    mobile: yup.string().min(10, 'invalid mobile number').max(10, 'invalid mobile number').matches(/[0-9]/, 'requires a number').required("enter your mobile number"),
    linkedIn: yup.string().matches(/^(https?:\/\/)?(www\.)?linkedin\.com\/(in|profile)\/.+$/, 'Invalid LinkedIn profile URL').required("LinkedIn Link is required"),
    city: yup.string(),
    area: yup.string(),
    company: yup.string().required('enter company'),
    designation: yup.string().required('enter designation'),
    yearOfExperience: yup.string(),
    salesExperience: yup.string(),
    areaOfExpertise: yup.string(),
    skillSet: yup.string(),
    areaOfInterest: yup.string(),
    whereYouHear: yup.string(),
    addValueToCommunity: yup.string(),
    lookingFor: yup.string(),
    approved: yup.string(),
    additionalComment: yup.string(),
});


export const validationSchemaTeamForm = yup.object({
    firstName: yup.string().required("enter firstname"),
    lastName: yup.string().required("enter lastname"),
    designation: yup.string().required('enter designation'),
    linkedIn: yup.string().matches(/^(https?:\/\/)?(www\.)?linkedin\.com\/(in|profile)\/.+$/, 'invalid linkedin profile url').required("linkedIn link is required"),
    role: yup.string().required('select your role'),
})


export const validationSchemaNewProspact = yup.object({
    firstName: yup.string().required("enter firstname"),
    lastName: yup.string().required("enter lastname"),
    email: yup.string().email("Invalid email address format").required("enter email address"),
    mobile: yup.string().min(10, 'invalid mobile number').max(10, 'invalid mobile number').matches(/[0-9]/, 'requires a number').required("enter your mobile number"),
    company: yup.string().required('enter company'),
    designation: yup.string().required('enter designation'),
    linkedIn: yup.string().matches(/^(https?:\/\/)?(www\.)?linkedin\.com\/(in|profile)\/.+$/, 'invalid linkedin profile url').required("enter linkedin profile link"),
})