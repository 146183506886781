import React from 'react';
import { Form } from 'react-bootstrap';

const FormikCheckBox = ({ dataList, fieldName, name, formik }) => {
    return (
        <Form.Group className="mb-3">
            {fieldName && fieldName !== "" ? <Form.Label className="fw-bold mx-1 text-capitalize mb-3">{fieldName}</Form.Label> : ""}
            {
                dataList && dataList !== "" ?
                    dataList?.map((arrItem) => (
                        <Form.Check
                            key={arrItem.value}
                            type="checkbox"
                            label={arrItem.label}
                            id={arrItem.label}
                            name={name}
                            value={arrItem.value}
                            checked={formik.values[name]?.includes(arrItem?.value)}
                            onChange={() => {
                                const updatedFormikField = formik.values[name] || [];

                                if (updatedFormikField.includes(arrItem.value)) {
                                    formik.setFieldValue(name, updatedFormikField.filter(item => item !== arrItem?.value));
                                } else {
                                    formik.setFieldValue(name, [...updatedFormikField, arrItem.value]);
                                }
                            }}
                        />
                    )) : ""
            }
            {
                formik.touched[name] && formik.errors[name] ? (
                    <p className="text-danger mt-1 fw-bold">{formik.errors[name]}</p>
                ) : null
            }
        </Form.Group>
    )
}

export default FormikCheckBox