import React from 'react'
import { Form } from 'react-bootstrap'

const FormikRadio = ({ dataList, fieldName, name, formik }) => {
    return (
        <div className='mb-3'>
            <Form.Group className="mb-3">
                <Form.Label className="fw-bold mx-1 text-capitalize mb-3">{fieldName}</Form.Label>
                {
                    dataList?.map((arrItem, index) => (
                        <Form.Check
                            key={arrItem.value}
                            type="radio"
                            label={arrItem.label}
                            id={arrItem.label}
                            name={name}
                            value={arrItem.value}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            checked={formik.values[name] === arrItem.value || (!formik.values[name] && index === 0)}
                        />
                    ))
                }
                {
                    formik.touched[name] && formik.errors[name] ? (
                        <p className="text-danger mt-1 fw-bold">{formik.errors[name]}</p>
                    ) : null
                }
            </Form.Group>
        </div>
    )
}
export default FormikRadio