import React, { useState } from "react";
import CustomButton from "../../components/Buttons/custom/CustomButton";
import { Form, Modal, Row, Col, Tabs, Tab } from "react-bootstrap";
import { ErrorToast } from "../../components/alert/tostPop";
import FormikInput from "../../components/filedbox/formikInput";
import { useFormik } from "formik";
import { NewAdmin_Data } from "../../services/postServices";
import { SweetAlertBox } from "../../components/alert/sweetAlertPop";
import FormikSelect from "../../components/filedbox/formikSelect";
import { validationSchemaAdminOtp } from "../../utils/validations";
import LoginPassword from "../login/loginPassword";

const NewAdmin = ({ submitForm }) => {

    const initialValues = {
        email: "",
        role: "",
    };

    const [adminData, setAdminData] = useState(initialValues);
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => {
        setShow(false)
        setAdminData({})
    }

    const onSubmit = async () => {
        try {
            const res = await NewAdmin_Data({ email: formik.values.email, role: formik.values.role })
            if (res.status === 201) {
                SweetAlertBox("success", "Create Admin Member", `${formik.values.email} is mail address create successfully`, () => {
                    handleClose()
                    submitForm()
                })
            } 
            else {
                ErrorToast("Not Created");
                handleClose()
                submitForm()
            }
        } catch (e) {
            console.error(e);
        }
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: adminData,
        validationSchema: validationSchemaAdminOtp,
        onSubmit,
    });

    return (
        <div>
            <CustomButton
                type={"button"}
                buttonText={"Add"}
                onClick={handleShow}
            />

            {show && show === true ? (
                <Modal
                    show={show}
                    size={"md"}
                    onHide={handleClose}
                    scrollable={true}
                    backdrop="static"
                    keyboard={false}
                    className="vh-100"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>New Admin Member</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="loginTabs">
                            <Tabs defaultActiveKey="passwordLogin" className="mb-3" fill>
                                <Tab eventKey="passwordLogin" title="Password Login">
                                    <LoginPassword dataSave={true} closeModel={() => handleClose()} />
                                </Tab>
                                <Tab eventKey="otp" title="OTP Login">
                                    <OtpForm formik={formik} />
                                </Tab>
                            </Tabs>
                        </div>
                    </Modal.Body>
                </Modal>
            ) : (
                ""
            )}
        </div>
    );
};

export default NewAdmin;


const OtpForm = ({ formik }) => {
    return (
        <Form noValidate onSubmit={formik.handleSubmit}>
            <Row>
                <Col className="col-12">
                    <FormikInput
                        fieldName="Email Address"
                        as="input"
                        name="email"
                        placeholder="enter email address"
                        formik={formik}
                    />
                </Col>
                <Col className="col-12">
                    <FormikSelect
                        dataList={[
                            {
                                label: "Select Role",
                                value: "",
                                default: true
                            },
                            {
                                label: "Super Admin",
                                value: "super-admin",
                            },
                            {
                                label: "Admin",
                                value: "admin",
                            },
                            {
                                label: "User",
                                value: "user",
                            },
                        ]}
                        fieldName={"Role"}
                        name={"role"}
                        formik={formik}
                    />
                </Col>
                <Col className="col-12 text-center py-3">
                    <CustomButton
                        type={"submit"}
                        buttonText={"Admin Save"}
                    />
                </Col>
            </Row>
        </Form>
    )
}