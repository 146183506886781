import React, { useState, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap';
import MainTitle from '../../components/mainTitle/mainTitle';
import DataTableCom from '../../components/datatable/datatablecom';
import { AllContactUs_Data } from '../../services/getServices';
import CustomLoader from '../../components/loader/loader';
import { CONTACTUS_API } from '../../constant/api';
import DeleteButton from '../../components/Buttons/deleteButton/deleteButton';
import ContactUsForm from './contactusForm';
import { useSelector } from 'react-redux';
import { selectorUserRole } from '../../redux/selector';
import { roleSuperUser } from '../../utils/globalVariable';

const ContactUs = () => {

    const userRole = useSelector(selectorUserRole);
    const [contactusData, setContactUsData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filterText, setFilterText] = useState('');

    const columns = [
        {
            name: 'No',
            selector: (row, index) => index + 1,
            sortable: true,
            grow: 0,
        },
        {
            name: 'Name',
            selector: (row) => row.firstName + " " + row.lastName,
            sortable: true,
        },
        {
            name: 'Email Address',
            sortable: true,
            selector: (row) => row.email,
        },
        {
            name: 'Mobile',
            sortable: true,
            selector: (row) => row.mobileNumber,
        },
        {
            name: 'Actions',
            button: true,
            grow: 4,
            cell: (item) => {
                return <>
                    <div className="d-flex gap-2">
                        <ContactUsForm contactId={item._id} refreshData={fetchAllContactUs} />
                        {
                            userRole && userRole === roleSuperUser ?
                                <DeleteButton
                                    Id={item._id}
                                    apiRoute={CONTACTUS_API}
                                    handleBack={() => fetchAllContactUs()}
                                /> : ""
                        }
                    </div>
                </>
            }
        }
    ]


    // fetch all events data
    const fetchAllContactUs = async () => {
        setLoading(true);
        try {
            const res = await AllContactUs_Data()
            setContactUsData(res.data.data);
            setLoading(false);
        }
        catch (res) {
            console.error(res)
        }
    }

    // search event name and location 
    const filteredItems = contactusData?.filter(
        item => (
            (item.firstName && item.firstName.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.lastName && item.lastName.toLowerCase().includes(filterText.toLowerCase()))
        )
    );


    useEffect(() => {
        fetchAllContactUs();
    }, [])


    return (
        <div>

            <CustomLoader isLoading={loading} />

            <div className="d-flex flex-column flex-sm-row gap-3 justify-content-center justify-content-sm-between align-items-center w-100 pb-4 px-3">
                <MainTitle heading={"Contact Us"} textAlign={"center"} />
                <ContactUsForm refreshData={fetchAllContactUs} />
            </div>

            <div className='pb-5'>
                <DataTableCom
                    columns={columns}
                    filteredItems={filteredItems}
                    filterText={filterText}
                    setFilterText={setFilterText}
                    refreshData={fetchAllContactUs}
                />
            </div>
        </div>
    )
}

export default ContactUs